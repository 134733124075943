import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import * as Yup from "yup";

//^ styles
import classes from "./UserProfileRegular.module.scss";
import styleVar from "../../../assets/scss/_themes-vars.module.scss";

//^ lib
import Head from "../../../layout/head/Head";

//^ http request
import { setProfileInfoHandler } from "../../../http/post-api";

//^ mui components
import { Box, Button, FormControl, MenuItem, OutlinedInput, Select, Stack, Typography } from "@mui/material";
import { LockOutlined as Lock } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTheme } from "@mui/material/styles";
import TextareaAutosize from "@mui/material/TextareaAutosize";

import { countryOptions } from "./UserData";
import { getCookie } from "../../../utils/Utils";
import { UserContext } from "./UserContext";
import { toast } from "react-toastify";

import Title from "../../../components/title/Title";
import { Spinner } from "reactstrap";

function ColWrapper({
  children,
  title,
  id,
  mobileView = true,
  alignItems = "center",
  colSpan = "",
  hasError,
  errorMsg,
}) {
  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr",

          "@media (min-width: 65.5rem)": {
            gridTemplateColumns: "1fr",
            gridColumn: colSpan,
          },
          rowGap: "0.625rem",
          alignItems: alignItems,
        }}
      >
        <Typography
          component={"label"}
          htmlFor={id}
          sx={{
            display: "none",
            "@media (min-width: 65.5rem)": {
              display: "block",
            },
          }}
        >
          {title}
        </Typography>
        {mobileView ? (
          <Typography
            component={"label"}
            htmlFor={id}
            variant="subtitle2"
            sx={{
              display: "block",
              "@media (min-width: 65.5rem)": {
                display: "none",
              },
            }}
          >
            {title}
          </Typography>
        ) : (
          ""
        )}
        <Stack gap={"0.325rem"}>
          {children}
          {hasError && (
            <Typography
              component={"p"}
              fontWeight={"600"}
              sx={{ color: styleVar.errorMain }}
              fontStyle={"italic"}
              fontSize={"0.8rem"}
            >
              {errorMsg}
            </Typography>
          )}
        </Stack>
      </Box>
    </>
  );
}

const UserProfileRegularPage = () => {
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const { loggedInUser, setData } = useContext(UserContext);
  const [countries, setCountries] = useState(countryOptions);

  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  //^ set profile mutation function
  const {
    isPending: setProfileIsPending,
    isError: setProfileIsError,
    error: setProfileError,
    mutate: setProfileMutate,
    reset: setProfileReset,
  } = useMutation({
    mutationKey: ["set-profile-info"],
    mutationFn: setProfileInfoHandler,
    onSuccess: (data) => {
      if (!data?.status) {
        if (data?.redirect) {
          window.location.href = `${process.env.REACT_APP_DEFAULT_REDIRECT_URL}`;
        } else {
          toast.error(data?.message);
        }
      } else {
        toast.success(data?.message);

        setData({
          first_name: data?.data?.profile.first_name,
          last_name: data?.data?.profile.last_name,
          phone_number: data?.data?.profile.phone_number,
          dob: data?.data?.profile.dob,
          address_1: data?.data?.profile.address_1,
          address_2: data?.data?.profile.address_2,
          notifications: data?.data?.profile?.notifications,
          state: data?.data?.profile.state,
          country: data?.data?.profile.country || "US",
          country_value: parseInt(data?.data?.profile.country),
          account_tokens: data?.data?.account_tokens,
        });
      }
      setProfileReset();
    },
  });

  useEffect(() => {
    if (setProfileIsError) {
      toast.error(setProfileError?.info?.message || "Something went wrong");
      setProfileReset();
    }
  }, [setProfileIsError, setProfileError, setProfileReset]);

  const loggedInUserDOB = loggedInUser?.dob ? dayjs(loggedInUser.dob) : null;

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().max(250, "First name cannot be longer than 250 characters"),
    lastName: Yup.string().max(250, "Last name cannot be longer than 250 characters"),
    email: Yup.string().email("Invalid email address").max(250, "Email cannot be longer than 250 characters"),
    phone_number: Yup.string().max(250, "Phone number cannot be longer than 250 characters"),
    address_1: Yup.string().max(250, "Address 1 cannot be longer than 250 characters"),
    address_2: Yup.string().max(250, "Address 2 cannot be longer than 250 characters"),
    state: Yup.string().max(250, "State cannot be longer than 250 characters"),
    dob: Yup.date().max(dayjs(), "Date of birth cannot be a future date"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: loggedInUser?.first_name || "",
      lastName: loggedInUser?.last_name || "",
      email: "",
      phone_number: loggedInUser?.phone_number || "",
      address_1: loggedInUser?.address_1 || "",
      address_2: loggedInUser?.address_2 || "",
      state: loggedInUser?.state || "",
      country: loggedInUser?.country_value || "",
      dob: loggedInUserDOB || null,
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();

      formData.append("first_name", values.firstName);
      formData.append("last_name", values.lastName);
      formData.append("email", values.email);
      formData.append("phone_number", values.phone_number);
      formData.append("address_1", values.address_1);
      formData.append("address_2", values.address_2);
      formData.append("state", values.state);
      formData.append("country_value", values.country);
      formData.append("dob", dayjs(values.dob).format("YYYY-MM-DD"));
      setProfileMutate({ formData });
    },
  });

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async () => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/countries`);
    res = await res.json();
    if (res.status) {
      let tempArr = [];
      res?.data?.countries.map((c) => {
        let value = c.id;
        let label = c.name;
        let country = { value, label };
        tempArr.push(country);
        // eslint-disable-next-line
        return;
      });
      setCountries(tempArr);
    }
  };

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  //^ Text Change event handler =================================================================

  // Define a debounced version of setProfileMutate
  // Handler for text change with debouncing
  const textChangeHandler = (event) => {
    formik.handleChange(event);
  };

  const handleCountryChangeHandler = (event) => {
    const value = event.target.value;

    formik.setFieldValue("country", value);
  };

  const handleDateChangeHandler = (date) => {
    // const dayjsDate = dayjs(date).format("YYYY-MM-DD");
    formik.setFieldValue("dob", date);
  };

  //^ Text Change event handler =================================================================

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  return (
    <>
      <React.Fragment>
        <Head title="User Profile" />

        {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
        <Title title={"Personal Information"} />
        <form onSubmit={formik.handleSubmit}>
          <Stack gap={"1rem"}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr",
                gap: "1.25rem",

                "@media (min-width: 65.5rem)": {
                  gridTemplateColumns: "1fr 1fr",
                },
                alignItems: "flex-start",
              }}
            >
              <ColWrapper
                id={"firstName"}
                title={"First Name"}
                hasError={formik.errors.firstName && formik.touched.firstName}
                errorMsg={formik.errors.firstName}
              >
                <OutlinedInput
                  value={formik.values.firstName}
                  id="firstName"
                  name="firstName"
                  onChange={(event) => textChangeHandler(event, "first_name")}
                  onBlur={formik.handleBlur}
                  placeholder="First Name"
                  fullWidth
                />
              </ColWrapper>

              <ColWrapper
                title={"Last Name"}
                id={"lastName"}
                hasError={formik.errors.lastName && formik.touched.lastName}
                errorMsg={formik.errors.lastName}
              >
                <OutlinedInput
                  value={formik.values.lastName}
                  id="lastName"
                  name="lastName"
                  onChange={(event) => textChangeHandler(event, "last_name")}
                  onBlur={formik.handleBlur}
                  placeholder="Last Name"
                  fullWidth
                />
              </ColWrapper>
              <ColWrapper
                title={
                  <Stack direction={"row"} gap={"0.425rem"} alignItems={"center"}>
                    <span>Email</span>
                    <Lock sx={{ fontSize: "1rem" }} />
                  </Stack>
                }
                id={"email"}
                hasError={formik.errors.email && formik.touched.email}
                errorMsg={formik.errors.email}
              >
                <OutlinedInput
                  defaultValue={getCookie("email", "john@doe.com")}
                  placeholder="Email"
                  name="email"
                  id="email"
                  disabled
                  fullWidth
                />
              </ColWrapper>

              <ColWrapper title={"Date of Birth"} id="dob">
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  hasError={formik.errors.dob && formik.touched.dob}
                  errorMsg={formik.errors.dob}
                >
                  <MuiDatePicker
                    variant="standard"
                    value={formik.values.dob || null}
                    id={"dob"}
                    onChange={handleDateChangeHandler}
                    sx={{ width: "100%" }}
                    format="DD/MM/YYYY"
                    maxDate={dayjs()}
                  />
                </LocalizationProvider>
              </ColWrapper>

              <ColWrapper
                title={"Phone Number"}
                colSpan="span 2"
                id={"phoneNumber"}
                hasError={formik.errors.phone_number && formik.touched.phone_number}
                errorMsg={formik.errors.phone_number}
              >
                <OutlinedInput
                  value={formik.values.phone_number}
                  id="phoneNumber"
                  name="phone_number"
                  onChange={(event) => textChangeHandler(event, "phone_number")}
                  onBlur={formik.handleBlur}
                  placeholder="Phone Number"
                  fullWidth
                />
              </ColWrapper>

              <ColWrapper
                title={"Address Line 1"}
                colSpan="span 1"
                id={"address1"}
                hasError={formik.errors.address_1 && formik.touched.address_1}
                errorMsg={formik.errors.address_1}
              >
                <TextareaAutosize
                  aria-label="Address Line 1"
                  id="address1"
                  placeholder="Type Address Line 1"
                  value={formik.values.address_1}
                  name="address_1"
                  onChange={(event) => textChangeHandler(event, "address_1")}
                  onBlur={formik.handleBlur}
                  className={`bg-transparent ${classes.textarea} ${theme.palette.mode === "dark" ? classes.dark : ""}`}
                  autoComplete="false"
                  minRows={1}
                />
              </ColWrapper>

              <ColWrapper
                title={"Address Line 2 (optional)"}
                id={"address2"}
                hasError={formik.errors.address_2 && formik.touched.address_2}
                errorMsg={formik.errors.address_2}
              >
                <TextareaAutosize
                  minRows={1}
                  aria-label="Address Line 2"
                  id="address2"
                  placeholder="Type Address Line 2"
                  value={formik.values.address_2}
                  name="address_2"
                  onChange={(event) => textChangeHandler(event, "address_2")}
                  onBlur={formik.handleBlur}
                  className={`bg-transparent ${classes.textarea} ${theme.palette.mode === "dark" ? classes.dark : ""}`}
                />
              </ColWrapper>

              <ColWrapper title={"Country"} id={"country"}>
                <FormControl sx={{ width: "100%" }} id="country">
                  <Select
                    variant="outlined"
                    id="country"
                    displayEmpty
                    value={formik.values.country}
                    onChange={handleCountryChangeHandler}
                    MenuProps={MenuProps}
                  >
                    <MenuItem disabled value="">
                      <em>Select Country</em>
                    </MenuItem>
                    {countries.map((item) => {
                      return (
                        <MenuItem
                          id="country"
                          key={item.value}
                          value={item.value}
                          style={getStyles(item.label, formik.values.country?.label || "", theme)}
                        >
                          <Typography>{item.label}</Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </ColWrapper>

              <ColWrapper
                title={"State"}
                id={"state"}
                hasError={formik.errors.state && formik.touched.state}
                errorMsg={formik.errors.state}
              >
                <OutlinedInput
                  id="state"
                  value={formik.values.state}
                  name="state"
                  onChange={(event) => textChangeHandler(event, "state")}
                  onBlur={formik.handleBlur}
                  placeholder="State"
                  fullWidth
                />
              </ColWrapper>
            </Box>

            <Box
              sx={{
                display: "none",
                "@media (min-width: 65.5rem)": {
                  display: "block",
                },
              }}
            >
              <Button
                startIcon={
                  setProfileIsPending ? <Spinner size={"sm"} style={{ borderWidth: "2px", color: "inherit" }} /> : ""
                }
                variant="contained"
                disabled={!formik.isValid || setProfileIsPending || !formik.dirty}
                type="submit"
                size="large"
              >
                Update
              </Button>
            </Box>
            <Box
              sx={{
                display: "block",
                width: "100%",
                marginTop: "1rem",
                "@media (min-width: 65.5rem)": {
                  display: "none",
                },
              }}
            >
              <Button
                startIcon={
                  setProfileIsPending ? <Spinner size={"sm"} style={{ borderWidth: "2px", color: "inherit" }} /> : ""
                }
                variant="contained"
                disabled={!formik.isValid || setProfileIsPending || !formik.dirty}
                type="submit"
                size="large"
                fullWidth
              >
                Update
              </Button>
            </Box>
          </Stack>
        </form>
      </React.Fragment>
    </>
  );
};

export default UserProfileRegularPage;
