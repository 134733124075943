import React from "react";

import {
  Skeleton,
  Stack,
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function DataTableSkeletonLoader() {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  return (
    <>
      <Paper elevation={isDark ? 1 : 3} sx={{ borderRadius: "0.625rem", padding: "1.25rem" }}>
        <Stack gap={"1rem"} width={"100%"}>
          <Box width={"100%"}>
            <Stack
              sx={{
                width: "100%",
                gap: "1.25rem",
                "@media(min-width: 65.5rem)": {
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                },
              }}
            >
              <Box width={"100%"}>
                <Skeleton
                  animation={"wave"}
                  variant="rounded"
                  height={"2.5rem"}
                  sx={{
                    width: "100%",
                    "@media (min-width: 65.5rem)": {
                      width: "15rem",
                    },
                  }}
                />
              </Box>
              <Stack
                gap={"0.625rem"}
                width={"100%"}
                sx={{
                  "@media (min-width: 65.5rem)": {
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  },
                }}
              >
                <Skeleton animation={"wave"} variant="text" sx={{ width: "5rem" }} />
                <Skeleton
                  animation={"wave"}
                  variant="rounded"
                  height={"2rem"}
                  sx={{
                    width: "100%",
                    "@media (min-width: 65.5rem)": {
                      width: "5rem",
                    },
                  }}
                />
              </Stack>
            </Stack>
          </Box>

          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" size={"small"}>
              <TableHead>
                {Array.from({ length: 1 }).map((_, index) => {
                  return (
                    <>
                      <TableRow key={index}>
                        {Array.from({ length: 5 }).map((_, index) => {
                          return (
                            <TableCell key={index}>
                              <Skeleton sx={{ height: "2.5rem" }} animation="wave" variant="text" width={"100%"} />
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </>
                  );
                })}
              </TableHead>
              <TableBody>
                {Array.from({ length: 10 }).map((_, index) => (
                  <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    {Array.from({ length: 5 }).map((_, index) => {
                      return (
                        <TableCell key={index}>
                          <Skeleton sx={{ height: "1.5rem" }} animation="wave" variant="text" width={"100%"} />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Stack
            sx={{
              "@media (min-width: 65.5rem)": {
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "0rem",
              },
            }}
          >
            <Stack
              direction={"row"}
              gap={"1rem"}
              sx={{
                justifyContent: "space-between",
                width: "100%",
                gap: "1.25rem",
                "@media (min-width: 65.5rem)": {
                  width: "auto",
                  justifyContent: "flex-start",
                },
              }}
            >
              <Skeleton animation={"wave"} variant="text" width={"15rem"} />
              <Skeleton animation={"wave"} variant="rounded" width={"5rem"} height={"2.5rem"} />
            </Stack>
            <Stack direction={"row"} gap={"0.3rem"}>
              <Skeleton animation={"wave"} variant="rounded" width={"7rem"} />
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </>
  );
}
