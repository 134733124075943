import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

//^ ui
import { useTheme } from "@mui/material/styles";
import { Box, Card, FormControl, FormControlLabel, Radio, RadioGroup, Skeleton, Stack } from "@mui/material";
import Title from "../../../../components/title/Title";
import { Block } from "../../../../components/Component";

//^ http request
import { getUserProfileHandler } from "../../../../http/get-api";

//^ redux action
import { twoFactorServicePlanAction } from "../../../../store/slices/twoFactor-user-profile-reducer";

//^ components
import EmailTwoFactorAuth from "./two-factor-options/EmailTwoFactorAuth";
import MobileTwoFactorAuth from "./two-factor-options/MobileTwoFactorAuth";
import BothTwoFactorAuth from "./two-factor-options/BothTwoFactorAuth";
import Head from "../../../../layout/head/Head";
import ErrorAlert from "../../../../components/ui/modals/error-model/ErrorAlert";
import BackButton from "../../../../components/button/back-button";

export default function TwoFactorSettings() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const [twoFactorTarget, setTwoFactorTarget] = useState("email");

  const {
    data: getUserProfileData,
    isLoading: getUserProfileIsLoading,
    isRefetching: getUserProfileIsRefetching,
    isError: getUserProfileIsError,
    error: getUserProfileError,
    refetch: getUserProfileRefetch,
  } = useQuery({
    queryKey: ["two-factor-get-user-profile"],
    queryFn: async ({ signal }) => getUserProfileHandler({ signal }),
    gcTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!getUserProfileIsRefetching || !getUserProfileIsLoading) {
      if (!getUserProfileData?.status) {
        if (getUserProfileData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        }
      } else {
        dispatch(twoFactorServicePlanAction.setUserProfileHandler(getUserProfileData?.data?.profile));
      }
    }
  }, [getUserProfileIsLoading, getUserProfileData, getUserProfileIsRefetching, dispatch]);

  function getRadioDataHandler(event) {
    const data = event.target.value;

    if (data === "email") {
      setTwoFactorTarget(data);
    } else if (data === "mobile") {
      setTwoFactorTarget(data);
    } else if (data === "both") {
      setTwoFactorTarget(data);
    } else {
      setTwoFactorTarget("");
    }
  }

  return (
    <>
      {getUserProfileIsError && (
        <ErrorAlert
          title={getUserProfileError?.code || 500}
          description={getUserProfileError?.info?.data?.message || "Something went wrong"}
          isConformed={() => {
            getUserProfileRefetch();
          }}
        />
      )}

      <Title
        title={"Two Factor Authentication Setting"}
        endComponent={<BackButton title={"Back"} variant={"text"} onClick={() => navigate(-1)} />}
      />
      {getUserProfileIsLoading || getUserProfileIsRefetching ? (
        <Head title={"Loading"} />
      ) : (
        <Head title={"Two Factor Settings"} />
      )}
      <Block className={"w-100"}>
        <Box
          sx={{
            display: "none",
            "@media (min-width: 65.5rem)": {
              display: "block",
            },
          }}
        >
          {getUserProfileIsLoading ? (
            <Card
              elevation={1}
              sx={{
                padding: "0rem",
                backgroundColor: "transparent",
                "@media (min-width: 65.5rem)": {
                  borderRadius: "0.625rem",
                  // backgroundColor: theme.palette.mode === "dark" ? "#17171c" : "",
                  // boxShadow: theme.palette.mode === "dark" ? theme.palette.boxShadow.dark : theme.palette.boxShadow.light,
                  ...theme.palette.cardInner.main,
                },
              }}
            >
              <Stack gap={"1.25rem"}>
                <Stack direction={"row"} gap={"1rem"} alignItems={"center"}>
                  {Array.from({ length: "3" }).map((_, index) => (
                    <Skeleton animation={"wave"} width={"10rem"} height={"2.25rem"} />
                  ))}
                </Stack>
                <Stack direction={"row"} gap={"1rem"} justifyContent={"space-between"}>
                  <Skeleton animation={"wave"} width={"100%"} />
                  <Skeleton animation={"wave"} width={"3.5rem"} height={"4rem"} />
                </Stack>

                <Skeleton animation={"wave"} width={"7.5rem"} height={"4rem"} />
              </Stack>
            </Card>
          ) : (
            <Card
              elevation={1}
              sx={{
                padding: "0rem",
                backgroundColor: "transparent",
                "@media (min-width: 65.5rem)": {
                  borderRadius: "0.625rem",
                  // backgroundColor: theme.palette.mode === "dark" ? "#17171c" : "",
                  // boxShadow: theme.palette.mode === "dark" ? theme.palette.boxShadow.dark : theme.palette.boxShadow.light,
                  ...theme.palette.cardInner.main,
                },
              }}
            >
              <div className="d-flex flex-column w-100" style={{ gap: "1rem" }}>
                <Stack gap={4}>
                  <Stack direction={"row"} gap={3} alignItems={"center"}>
                    <FormControl>
                      <RadioGroup row aria-labelledby="two-fact-option-group" defaultValue="email" name="2fa-group">
                        <FormControlLabel
                          value="email"
                          control={<Radio />}
                          label="Email"
                          onClick={getRadioDataHandler}
                        />
                        <FormControlLabel
                          value="mobile"
                          control={<Radio />}
                          label="Mobile"
                          onClick={getRadioDataHandler}
                        />
                        <FormControlLabel value="both" control={<Radio />} label="Both" onClick={getRadioDataHandler} />
                      </RadioGroup>
                    </FormControl>
                  </Stack>
                  <Box>
                    {twoFactorTarget === "email" ? (
                      <EmailTwoFactorAuth />
                    ) : twoFactorTarget === "mobile" ? (
                      <MobileTwoFactorAuth />
                    ) : twoFactorTarget === "both" ? (
                      <div>
                        <BothTwoFactorAuth />
                      </div>
                    ) : (
                      ""
                    )}
                  </Box>
                </Stack>
              </div>
            </Card>
          )}
        </Box>
        <Box
          sx={{
            display: "block",
            "@media (min-width: 65.5rem)": {
              display: "none",
            },
          }}
        >
          {getUserProfileIsLoading ? (
            <Card
              elevation={0}
              sx={{
                padding: "0rem",
                backgroundColor: "transparent",
                "@media (min-width: 65.5rem)": {
                  borderRadius: "0.625rem",
                  ...theme.palette.cardInner.main,
                },
              }}
            >
              <Stack gap={"1.25rem"}>
                <Stack direction={"row"} gap={"1rem"} alignItems={"center"}>
                  {Array.from({ length: "3" }).map((_, index) => (
                    <Skeleton animation={"wave"} width={"10rem"} height={"2.25rem"} />
                  ))}
                </Stack>
                <Stack direction={"row"} gap={"1rem"} justifyContent={"space-between"}>
                  <Skeleton animation={"wave"} width={"100%"} />
                  <Skeleton animation={"wave"} width={"3.5rem"} height={"4rem"} />
                </Stack>

                <Skeleton animation={"wave"} width={"7.5rem"} height={"4rem"} />
              </Stack>
            </Card>
          ) : (
            <Card
              sx={{
                padding: "0rem",
                backgroundColor: "transparent",
                "@media (min-width: 65.5rem)": {
                  borderRadius: "0.625rem",
                  ...theme.palette.cardInner.main,
                },
              }}
            >
              <div className="d-flex flex-column w-100" style={{ gap: "1rem" }}>
                <Stack gap={4}>
                  <Stack direction={"row"} gap={3} alignItems={"center"}>
                    <FormControl>
                      <RadioGroup row aria-labelledby="two-fact-option-group" defaultValue="email" name="2fa-group">
                        <FormControlLabel
                          value="email"
                          control={<Radio />}
                          label="Email"
                          onClick={getRadioDataHandler}
                        />
                        <FormControlLabel
                          value="mobile"
                          control={<Radio />}
                          label="Mobile"
                          onClick={getRadioDataHandler}
                        />
                        <FormControlLabel value="both" control={<Radio />} label="Both" onClick={getRadioDataHandler} />
                      </RadioGroup>
                    </FormControl>
                  </Stack>
                  <Box>
                    {twoFactorTarget === "email" ? (
                      <EmailTwoFactorAuth />
                    ) : twoFactorTarget === "mobile" ? (
                      <MobileTwoFactorAuth />
                    ) : twoFactorTarget === "both" ? (
                      <div>
                        <BothTwoFactorAuth />
                      </div>
                    ) : (
                      ""
                    )}
                  </Box>
                </Stack>
              </div>
            </Card>
          )}
        </Box>
      </Block>
    </>
  );
}
