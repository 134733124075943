import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment/moment";
import { toast } from "react-toastify";

//^ http request
import { getInvoiceListHandler } from "../../http/post-api";

//^ mui component
import { Stack } from "@mui/material";

//^ column
import { columns } from "./columns";

//^ Table component
import ErrorAlert from "../../components/ui/modals/error-model/ErrorAlert";
import DataTableSkeletonLoader from "../../components/ui/loader/Skeleton/DataTableSkeletonLoader";
import EmptyFolder from "../../components/svg/empty-folder";
import DataTable from "../invoices/data-table";

export default function InvoicesTable() {
  const [billings, setBillings] = React.useState([]);

  const {
    data: getInvoiceListData,
    isLoading: getInvoiceListIsLoading,
    isRefetching: getInvoiceListIsRefetching,
    isError: getInvoiceListIsError,
    error: getInvoiceListError,
    refetch: getInvoiceListRefetch,
  } = useQuery({
    queryKey: ["get-invoice-list"],
    queryFn: getInvoiceListHandler,
  });

  useEffect(() => {
    if (!getInvoiceListIsLoading || !getInvoiceListIsRefetching) {
      if (!getInvoiceListData?.status) {
        if (getInvoiceListData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        }
      } else {
        const serviceFilter = getInvoiceListData?.data?.list.map((list, index) => {
          let count = index;
          count++;

          return {
            srNo: count,
            id: list?.id,
            invoiceId: list?.invoiceId,
            tsxId: list?.txn_id,
            plan: list?.plan,
            planId: list?.plan_id,
            serviceId: list?.service_id,
            type: list.type,
            total: (list?.price + list?.auger_price)?.toFixed(2),
            invoiceDate: moment(list?.date).format("DD/MM/YYYY"),
            status: list?.status,
          };
        });

        setBillings(serviceFilter || []);
      }
    }
  }, [getInvoiceListData, getInvoiceListIsLoading, getInvoiceListIsRefetching]);

  useEffect(() => {
    if (getInvoiceListIsError) {
      console.log(getInvoiceListError?.info);
      toast.error(getInvoiceListError?.info?.message);
    }
  }, [getInvoiceListIsError, getInvoiceListError]);

  return (
    <>
      {getInvoiceListIsError && (
        <ErrorAlert
          title={`${getInvoiceListError?.code || 500}`}
          description={`${getInvoiceListError?.info?.message || "Something went wrong"}`}
          isConformed={() => getInvoiceListRefetch()}
        />
      )}
      <div>
        {getInvoiceListIsLoading ? (
          <DataTableSkeletonLoader />
        ) : billings ? (
          <DataTable
            data={billings || []}
            columns={columns}
            searchPlaceholder={"Search for plans..."}
            searchKey={"plan-hidden"}
            isCompact={true}
          />
        ) : (
          <Stack width={"100%"} height={"100%"} alignItems={"center"} justifyContent={"center"}>
            <EmptyFolder />
          </Stack>
        )}
      </div>
    </>
  );
}
