import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import Toggle from "../sidebar/Toggle";
import User from "./dropdown/user/User";
import Notification from "./dropdown/notification/Notification";
import { Icon } from "../../components/Component";

import { useTheme, useThemeUpdate } from "../provider/Theme";
import { Button, Tooltip } from "reactstrap";
import { Link } from "react-router-dom";
import { Box, Paper, Stack } from "@mui/material";

const AppHeader = ({ fixed, className, app }) => {
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const navigate = useNavigate();

  const [tooltipOpen, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!tooltipOpen);
  };

  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });

  return (
    <Paper elevation={1} sx={{ zIndex: "1200", borderRadius: "none", border: "none" }} className={`${headerClass}`}>
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ms-n1">
            <Toggle className="nk-nav-toggle nk-quick-nav-icon" icon="menu" click={themeUpdate.sidebarVisibility} />
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`${process.env.PUBLIC_URL}/user-profile-regular`)}
            className="nk-header-app-name"
          >
            <div className="nk-header-app-logo">
              <Icon name={app.icon} className={app.theme} />
            </div>
            <div className="nk-header-app-info">
              <span className="lead-text">{app.text}</span>
            </div>
          </div>

          <div className="nk-header-tools">
            <Stack direction={"row"} gap={"1.5rem"} className="nk-quick-nav">
              <Box className="notification-dropdown me-n1">
                <span className="d-flex flex-row align-items-center fs-sm-15px fs-10px fw-semibold">
                  <a href={`${process.env.REACT_APP_DEFAULT_REDIRECT_URL}`}>
                    <div className="btn btn-outline-primary d-none d-md-inline-flex">
                      <div className="px-1">Back to dashboard</div>
                      <Icon name="arrow-to-right" className=""></Icon>
                    </div>
                  </a>
                  <a href={`${process.env.REACT_APP_DEFAULT_REDIRECT_URL}`}>
                    <div className="btn btn-outline-primary d-md-none fs-sm-15px fs-10px" id="id">
                      <Icon name="arrow-to-right" className=""></Icon>
                      <Tooltip placement="right" isOpen={tooltipOpen} target="id" toggle={toggle}>
                        Back to dashboard
                      </Tooltip>
                    </div>
                  </a>
                </span>
              </Box>
              <Box className="notification-dropdown me-n1">
                <Notification />
              </Box>
              <Box className="user-dropdown">
                <User />
              </Box>
            </Stack>
          </div>
        </div>
      </div>
    </Paper>
  );
};
export default AppHeader;
