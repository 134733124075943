import React, { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";

//^ http requests
import { deleteConnectionHandler, requestConnectionHandler } from "../../../http/post-api";
import { queryClient } from "../../../http";

//^ @mui component
import { AccessTime, MoreVert } from "@mui/icons-material";
import { Box, CircularProgress } from "@mui/material";

import {
  Avatar,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
} from "@mui/material";
import { toast } from "react-toastify";

function UserList(props) {
  //^ delete connection mutation fn ================================================================

  const {
    isPending: deleteConnectionIsPending,
    isError: deleteConnectionIsError,
    error: deleteConnectionError,
    mutate: deleteConnectionMutate,
    reset: deleteConnectionReset,
  } = useMutation({
    mutationKey: ["delete-connection"],
    mutationFn: deleteConnectionHandler,
    onSuccess: (data) => {
      if (data?.toast) {
        if (data?.status) {
          toast.success(data?.message);
          queryClient.invalidateQueries([`get-user-${"connected"}-list`, "connectType"]);
        } else {
          toast.error(data?.message);
        }
      }
      deleteConnectionReset();
    },
  });

  useEffect(() => {
    if (deleteConnectionIsError) {
      toast.error(deleteConnectionError?.message);
      deleteConnectionReset();
    }
  }, [deleteConnectionIsError, deleteConnectionError, deleteConnectionReset]);

  //^ delete connection mutation fn ================================================================

  //^ connection mutation fn ================================================================

  const {
    isPending: requestConnectionIsPending,
    isError: requestConnectionIsError,
    error: requestConnectionError,
    mutate: requestConnectionMutate,
    reset: requestConnectionReset,
  } = useMutation({
    mutationKey: ["request-connection"],
    mutationFn: requestConnectionHandler,
    onSuccess: (data) => {
      if (data?.toast) {
        if (data?.status) {
          toast.success(data?.message);
          queryClient.invalidateQueries([`get-user-${"not_connected"}-list`, "notConnectedType"]);
        } else {
          toast.error(data?.message);
        }
      }
      requestConnectionReset();
    },
  });

  useEffect(() => {
    if (requestConnectionIsError) {
      toast.error(requestConnectionError.message);
      requestConnectionReset();
    }
  }, [requestConnectionIsError, requestConnectionError, requestConnectionReset]);

  //^ connection mutation fn ================================================================

  return (
    <List
      sx={{
        width: "100%",
      }}
    >
      <ListItem
        alignItems="flex-start"
        secondaryAction={
          <Stack direction={"row"} alignItems={"center"} gap={2} width={"100%"}>
            {props?.row?.connection_status?.message !== "Pending" ? (
              props?.row?.connection_status?.connected === true ? (
                <Box
                  sx={{
                    display: "none",
                    "@media (min-width: 65.5rem)": {
                      display: "block",
                    },
                  }}
                >
                  <Button
                    startIcon={deleteConnectionIsPending && <CircularProgress size={16} color="inherit" />}
                    variant="contained"
                    sx={{
                      borderRadius: "10rem",
                      padding: "0.25rem 1rem",
                    }}
                    size="small"
                    onClick={() => deleteConnectionMutate({ connection_id: props.row.connection_id, type: "single" })}
                  >
                    remove Connection
                  </Button>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "none",
                    "@media (min-width: 65.5rem)": {
                      display: "block",
                    },
                  }}
                >
                  <Button
                    startIcon={requestConnectionIsPending && <CircularProgress size={16} color="inherit" />}
                    variant="contained"
                    sx={{
                      borderRadius: "10rem",
                      padding: "0.25rem 1rem",
                      minWidth: "7rem",
                    }}
                    size="small"
                    onClick={() => requestConnectionMutate({ user_id: props.row.user_id })}
                  >
                    connect
                  </Button>
                </Box>
              )
            ) : (
              <Button
                startIcon={<AccessTime />}
                variant="outlined"
                disabled
                sx={{
                  borderRadius: "10rem",
                  padding: "0.25rem 1rem",
                  minWidth: "7rem",
                }}
              >
                {props?.row?.connection_status?.message}
              </Button>
            )}
            <IconButton
              sx={{
                display: "block",
                "@media (min-width: 65.5rem)": {
                  display: "none",
                },
              }}
            >
              <MoreVert />
            </IconButton>
          </Stack>
        }
      >
        <ListItemAvatar>
          <Avatar alt={props.row.username} src={props.row.avatar_url} />
        </ListItemAvatar>
        <ListItemText
          primary={props.row.username}
          secondary={<React.Fragment>{`${props.row.email}`}</React.Fragment>}
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </List>
  );
}

export const columns = [
  {
    accessorKey: "userList",
    header: "User List",
    accessorFn: (row) => {
      return <UserList row={row} />;
    },
  },
  { accessorKey: "username" },
  { accessorKey: "lastName" },
  { accessorKey: "email" },
  { accessorKey: "created_at" },
];
