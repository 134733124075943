import colors from "../../assets/scss/_themes-vars.module.scss";

import { InputBase } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";

const StyledSearch = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.mode === "dark" ? alpha(colors.darkModeColor, 0.15) : "none",
  boxShadow: `0px 0px 0px 1px ${theme.palette.mode === "dark" ? colors.borderDarkAlt : colors.borderLightAlt}`,
  "&:hover": {
    backgroundColor: theme.palette.mode === "dark" ? alpha(colors.darkModeColor, 0.2) : "none",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme, width, hoverwidth }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: width || "15ch",
      "&:focus": {
        width: hoverwidth || "20ch",
      },
    },
  },
}));

export default function Search({ placeholder, onClick, onChange, name, width, hoverwidth, ...props }) {
  return (
    <StyledSearch>
      <SearchIconWrapper>
        <SearchIcon sx={{ fontSize: "1.25rem" }} />
      </SearchIconWrapper>
      <StyledInputBase
        width={width}
        hoverwidth={hoverwidth}
        placeholder={placeholder || "Search..."}
        inputProps={{ "aria-label": "search" }}
        onClick={onClick}
        onChange={onChange}
        name={name}
        {...props}
      />
    </StyledSearch>
  );
}
