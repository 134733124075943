import { MoreVert } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

export const columns = [
  {
    accessorKey: "userList",
    header: "User List",
    accessorFn: (row) => {
      return (
        <List sx={{ width: "100%" }}>
          <ListItem
            alignItems="center"
            secondaryAction={
              <Stack direction={"row"} alignItems={"center"} gap={2}>
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: "10rem",
                    width: "7rem",
                    display: "none",
                    "@media (min-width: 65.5rem)": {
                      display: "block",
                    },
                  }}
                  size="small"
                >
                  Following
                </Button>
                <IconButton>
                  <MoreVert />
                </IconButton>
              </Stack>
            }
          >
            <ListItemAvatar>
              <Avatar alt={row?.username} src={row?.user_avatar} />
            </ListItemAvatar>
            <ListItemText
              primary={<Typography textTransform={"capitalize"}>{row?.username}</Typography>}
              secondary={<React.Fragment>{`${row?.email}`}</React.Fragment>}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </List>
      );
    },
  },
  { accessorKey: "username" },
  { accessorKey: "lastName" },
  { accessorKey: "created_at" },
  { accessorKey: "email" },
];
