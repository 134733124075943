import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";

//^ redux actions
import { userProfileAction } from "../store/slices/user-profile-slice";

//^ http request
import { getUserProfileHandler } from "../http/get-api";

import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import AppHeader from "./header/AppHeader";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";

//^ redux slice
import { muiAction } from "../store/slices/mui-slice";

import { useTheme } from "./provider/Theme";
import { UserContext } from "../pages/pre-built/user-manage/UserContext";
import Profile from "./Profile";

//^ components
import { getSiteSettingsHandler } from "../http/post-api";
import { siteSettingAction } from "../store/slices/site-setting-slice";
import ErrorAlert from "../components/ui/modals/error-model/ErrorAlert";

const Layout = ({ title, app }) => {
  const muiTheme = useSelector((state) => state.mui.theme);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();

  const loggedInUser = useContext(UserContext);

  const [data, setData] = useState(loggedInUser);

  const {
    data: userProfileData,
    isLoading: userProfileIsLoading,
    isError: userProfileIsError,
    error: userProfileError,
    refetch: userProfileRefetch,
  } = useQuery({
    queryKey: ["get-user-profile-index-app"],
    queryFn: ({ signal }) => getUserProfileHandler({ signal }),
  });

  //^ get site settings query fn
  const {
    data: getSiteSettingsData,
    isLoading: getSiteSettingsIsLoading,
    isRefetching: getSiteSettingsIsRefetching,
    isError: getSiteSettingsIsError,
    error: getSiteSettingsError,
    refetch: getSiteSettingsRefetch,
  } = useQuery({
    queryKey: ["get-site-settings"],
    queryFn: getSiteSettingsHandler,
    gcTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!getSiteSettingsIsLoading || !getSiteSettingsIsRefetching) {
      if (!getSiteSettingsData?.status) {
        if (getSiteSettingsData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        }
      } else {
        let showApp = "";

        for (let siteSetting of getSiteSettingsData?.data?.site_settings) {
          if (siteSetting.field_key === "apps") {
            showApp = siteSetting.field_value;
          }
        }

        dispatch(
          siteSettingAction.setSiteSettingHandler({
            showApp,
          })
        );
      }
    }
  }, [getSiteSettingsData, getSiteSettingsIsLoading, getSiteSettingsIsRefetching, dispatch]);

  useEffect(() => {
    if (getSiteSettingsIsError) {
      console.log(getSiteSettingsError);
    }
  }, [getSiteSettingsIsError, getSiteSettingsError]);

  useEffect(() => {
    if (!userProfileIsLoading) {
      if (userProfileData?.status) {
        dispatch(userProfileAction.setProfileData({ profileData: userProfileData?.data?.profile }));
        dispatch(userProfileAction.setTokenValue(userProfileData?.data?.token_value));
      } else {
        if (userProfileData?.redirect) {
          navigate("/login");
        }
      }
    }
    // eslint-disable-next-line
  }, [userProfileIsLoading, userProfileData]);

  useEffect(() => {
    if (theme.skin !== muiTheme) {
      dispatch(muiAction.setTheme(theme.skin));
      document.documentElement.style.setProperty("--bar-color", theme.skin === "dark" ? "#5a5a5a" : "#ccc");
      document.documentElement.style.setProperty("--track-color", theme.skin === "dark" ? "#333" : "#f0f0f0");
    }
  }, [theme.skin, dispatch, muiTheme]);

  useEffect(() => {
    const cookieValue = Cookies.get("authToken");
    if (!cookieValue) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {getSiteSettingsIsError && (
        <ErrorAlert
          title={`${getSiteSettingsError?.code || 500}`}
          description={`${getSiteSettingsError?.info?.data?.message || "Something went wrong"}`}
          isConformed={() => getSiteSettingsRefetch()}
        />
      )}
      {userProfileIsError && (
        <ErrorAlert
          title={`${userProfileError?.code || 500}`}
          description={`${userProfileError?.info?.message || "Something went wrong"}`}
          isConformed={() => userProfileRefetch()}
        />
      )}

      <UserContext.Provider value={{ loggedInUser: data, setData }}>
        <Profile />
        <Head title={!title && "Loading"} />
        <AppRoot className="npc-apps apps-only">
          <AppMain>
            {theme.sidebarMobile && <Sidebar fixed />}
            <AppWrap>
              <AppHeader app={app} fixed />
              {userProfileIsLoading ? (
                <div style={{ height: "100vh" }} className="d-flex justify-content-center align-items-center">
                  {/* <Spinner /> */}
                </div>
              ) : (
                <Outlet />
              )}
            </AppWrap>
          </AppMain>
        </AppRoot>
      </UserContext.Provider>
    </>
  );
};
export default Layout;
