//^ mui
import { Box } from "@mui/material";

//^ ui components
// import Title from "../../components/title/Title";
// import { BlockBetween, BlockHead, BlockHeadContent } from "../../components/Component";

//^ components
import InvoicesTable from "../../tables/invoices/table";
import Head from "../../layout/head/Head";

export default function AccountInvoicesPage() {
  return (
    <Box>
      {/* <BlockHead>
        <BlockBetween>
          <BlockHeadContent className={"w-100"}>
            <Title title={"Invoices"} />
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead> */}
      <Head title="Invoices" />
      <InvoicesTable />
    </Box>
  );
}
