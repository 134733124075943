import { Box, Stack, Typography } from "@mui/material";
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent } from "../../components/Component";
import Separator from "../../components/ui/separator/Separator";

export default function Title({ title, subTitle, divider = true, endComponent }) {
  return (
    <div>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent className={"w-100"}>
            <Stack
              width={"100%"}
              justifyContent={"space-between"}
              sx={{
                gap: "1rem",
                "@media (min-width: 65.5rem)": {
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "0rem",
                },
              }}
            >
              <Box width={"100%"}>
                <Typography variant="h5" className="d-none d-sm-block">
                  {title}
                </Typography>
                <Typography variant="h6" className="d-block d-sm-none">
                  {title}
                </Typography>
              </Box>
              <Stack justifyContent={"flex-start"} direction={"row"}>
                {endComponent ? endComponent : ""}
              </Stack>
            </Stack>
            <BlockDes>{subTitle}</BlockDes>
            {divider ? <Separator /> : ""}
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
    </div>
  );
}
