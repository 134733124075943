import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Form, Spinner, Alert, Button } from "reactstrap";
// import Logo from "../../images/logo.png";
// import LogoDark from "../../images/logo-dark.png";
import { useForm } from "react-hook-form";
import { resetPassword, verifyAccountRequest, verifyLinkRequest } from "../../controller/auth_controller";
import Cookies from "js-cookie";
import AuthFooter from "./AuthFooter";
import Head from "../../layout/head/Head";
import { Block, BlockContent, BlockHead, BlockTitle, Icon, PreviewCard } from "../../components/Component";
import { useNavigate } from "react-router";
import LogoFetch from "./LogoFetch";
import { CustomButton } from "../../themes/CustomComponent";

const VerifyAccount = ({ type }) => {
  const { token, code } = useParams();
  const [confirmPassState, setConfirmPassState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState("");
  const [successVal, setSuccessVal] = useState("");
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState([]);

  useEffect(() => {
    const authToken = Cookies.get("authToken");
    if (authToken) {
      window.location.href = process.env.REACT_APP_ACCOUNT_URL;
    }
    async function checkLink() {
      let verifyLinkRes = await verifyLinkRequest(token, code);
      if (!verifyLinkRes.status) {
        setError(verifyLinkRes.message);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    }
    checkLink();
    // eslint-disable-next-line
  }, []);

  const onFormSubmit = async (formData) => {
    setLoading(true);
    let verifyAccountRes = null;
    if (type === "verify") {
      verifyAccountRes = await verifyAccountRequest(token, code, formData.password);
    } else {
      verifyAccountRes = await resetPassword(token, code, formData.password, formData.confirmPassword);
    }
    setLoading(false);
    if (verifyAccountRes.status) {
      setSuccessVal(verifyAccountRes.message);
      setError(false);
      reset();

      const dashboardURL = Cookies.get("dashboard");
      if (dashboardURL && dashboardURL === "client") {
        window.location = process.env.REACT_APP_ACCOUNT_URL;
      } else {
        window.location = process.env.REACT_APP_ACCOUNT_URL;
      }
      // const now = new Date();
      // const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
      // Cookies.set("authToken", verifyAccountRes.data.authToken, {
      //   domain: process.env.REACT_APP_COOKIE_DOMAIN,
      //   expires: expires,
      // });
      // Cookies.set("username", verifyAccountRes.data.username, {
      //   domain: process.env.REACT_APP_COOKIE_DOMAIN,
      //   expires: expires,
      // });
      // Cookies.set("email", verifyAccountRes.data.email, {
      //   domain: process.env.REACT_APP_COOKIE_DOMAIN,
      //   expires: expires,
      // });
      Cookies.remove("dashboard", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    } else {
      setError(verifyAccountRes.message);
      setSuccessVal("");
      if (verifyAccountRes.errors) setValidationErrors(verifyAccountRes.errors);
      else setValidationErrors([]);
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const password = watch("password");
  const dashBoardCookie = Cookies.get("dashboard");
  return (
    <>
      <Head title="Login" />
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-4 w-30 mx-auto text-center">
          {/* <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link> */}
          <LogoFetch />
        </div>

        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">{type === "verify" ? "Account Verification" : "Reset Password"}</BlockTitle>
            </BlockContent>
          </BlockHead>
          {successVal && (
            <div className="mb-3">
              <Alert color="success" className="alert-icon">
                <Icon name="alert-circle" /> {successVal}
              </Alert>
            </div>
          )}
          {errorVal && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                <Icon name="alert-circle" /> {errorVal}
                {validationErrors.length > 0 && (
                  <ul style={{ listStyle: "circle" }}>
                    {validationErrors.map((error, key) => (
                      <li key={key}>{error}</li>
                    ))}
                  </ul>
                )}
              </Alert>
            </div>
          )}
          <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="password">
                  Password
                </label>
                <Link className="link link-primary link-sm d-none" to={`${process.env.PUBLIC_URL}/reset`}>
                  Forgot Password?
                </Link>
              </div>
              <div className="form-control-wrap">
                <a
                  href="#password"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setPassState(!passState);
                  }}
                  className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                >
                  <Icon name="eye" className="passcode-icon icon-show"></Icon>

                  <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                </a>
                <input
                  type={passState ? "text" : "password"}
                  id="password"
                  tabIndex={1}
                  {...register("password", {
                    required: "password is required",
                    pattern: {
                      value: /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                      message: "Enter strong password(eg. Abc@1234)",
                    },
                    maxLength: { value: 16, message: "Password must not exceed 16 characters" },
                  })}
                  placeholder="Enter your password"
                  className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                />
                {errors.password && <span className="invalid">{errors.password.message}</span>}
              </div>
            </div>
            {type === "reset" && (
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="confirmPassword">
                    Confirm Password
                  </label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#confirmPassword"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setConfirmPassState(!confirmPassState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${
                      confirmPassState ? "is-hidden" : "is-shown"
                    }`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                  <input
                    type={confirmPassState ? "text" : "password"}
                    id="confirmPassword"
                    tabIndex={2}
                    {...register("confirmPassword", {
                      required: "This field is required",
                      validate: (value) => value === password || "Passwords do not match",
                    })}
                    placeholder="Enter confirm password"
                    className={`form-control-lg form-control ${confirmPassState ? "is-hidden" : "is-shown"}`}
                  />
                  {errors.confirmPassword && <span className="invalid">{errors.confirmPassword.message}</span>}
                </div>
              </div>
            )}
            <div className="form-group">
              <CustomButton
                size="lg"
                className="btn-block"
                type="submit"
                color="primary"
                tabIndex={`${type === "reset" ? 3 : 2}`}
              >
                {loading ? (
                  <Spinner style={{ borderWidth: "2px", color: "inherit" }} size={"sm"} />
                ) : type === "verify" ? (
                  "Verify"
                ) : (
                  "Reset Password"
                )}
              </CustomButton>
            </div>
          </Form>
          {dashBoardCookie === "client" ? (
            <div className="d-none"></div>
          ) : (
            <div className="form-note-s2 text-center pt-4">
              New on our platform? <Link to={`${process.env.PUBLIC_URL}/register`}>Create an account</Link>
            </div>
          )}
          <div className="text-center pt-4 pb-3 d-none">
            <h6 className="overline-title overline-title-sap">
              <span>OR</span>
            </h6>
          </div>
          <ul className="nav justify-center gx-4 d-none">
            <li className="nav-item">
              <a
                className="nav-link"
                href="#socials"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                Facebook
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#socials"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                Google
              </a>
            </li>
          </ul>
        </PreviewCard>
      </Block>
      <AuthFooter />
    </>
  );
};

export default VerifyAccount;
