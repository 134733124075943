import { useSearchParams } from "react-router-dom";

//^ data-table
import { columns } from "./column";
import DataTable from "./data-table";

export default function RequestTable() {
  // eslint-disable-next-line
  const [searchParams, _setSearchParams] = useSearchParams();
  const requestTab = searchParams.get("requestTab");

  const receivedData = [
    {
      id: 1,
      username: "John Doe",
      imgUrl:
        "https://images.unsplash.com/photo-1463453091185-61582044d556?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dz",
      email: "email@example.com",
    },
    {
      id: 2,
      username: "Alice",
      imgUrl:
        "https://images.unsplash.com/photo-1463453091185-61582044d556?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dz",
      email: "email@example.com",
    },
    {
      id: 3,
      username: "Michael",
      imgUrl:
        "https://images.unsplash.com/photo-1463453091185-61582044d556?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dz",
      email: "email@example.com",
    },
    {
      id: 4,
      username: "Emily",
      imgUrl:
        "https://images.unsplash.com/photo-1463453091185-61582044d556?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dz",
      email: "email@example.com",
    },
    {
      id: 5,
      username: "Daniel",
      imgUrl:
        "https://images.unsplash.com/photo-1463453091185-61582044d556?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dz",
      email: "email@example.com",
    },
    {
      id: 6,
      username: "Sarah",
      imgUrl:
        "https://images.unsplash.com/photo-1463453091185-61582044d556?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dz",
      email: "email@example.com",
    },
    {
      id: 7,
      username: "David",
      imgUrl:
        "https://images.unsplash.com/photo-1463453091185-61582044d556?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dz",
      email: "email@example.com",
    },
  ];

  const sentData = [
    {
      id: 1,
      username: "John Doe",
      imgUrl:
        "https://images.unsplash.com/photo-1463453091185-61582044d556?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dz",
      email: "email@example.com",
    },
    {
      id: 2,
      username: "Alice",
      imgUrl:
        "https://images.unsplash.com/photo-1463453091185-61582044d556?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dz",
      email: "email@example.com",
    },
    {
      id: 3,
      username: "Michael",
      imgUrl:
        "https://images.unsplash.com/photo-1463453091185-61582044d556?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dz",
      email: "email@example.com",
    },
    {
      id: 4,
      username: "Emily",
      imgUrl:
        "https://images.unsplash.com/photo-1463453091185-61582044d556?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dz",
      email: "email@example.com",
    },
    {
      id: 5,
      username: "Daniel",
      imgUrl:
        "https://images.unsplash.com/photo-1463453091185-61582044d556?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dz",
      email: "email@example.com",
    },
    {
      id: 6,
      username: "Sarah",
      imgUrl:
        "https://images.unsplash.com/photo-1463453091185-61582044d556?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dz",
      email: "email@example.com",
    },
    {
      id: 7,
      username: "David",
      imgUrl:
        "https://images.unsplash.com/photo-1463453091185-61582044d556?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dz",
      email: "email@example.com",
    },
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={requestTab === "receive" ? receivedData : requestTab === "sent" ? sentData : []}
      />
    </>
  );
}
