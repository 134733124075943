import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardImg, CardText, CardTitle, Col, Row } from "reactstrap";
import { useTheme } from "../../layout/provider/Theme";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import { Box, Button, Container, Skeleton, Typography } from "@mui/material";
import { useTheme as useMuiTheme } from "@mui/material/styles";
import {
  Grid,
  Stack,
  Paper,
  Card as MuiCard,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
} from "@mui/material";

//^ stylesheets
import classes from "./show-apps.module.scss";

//^ http request
import { getAppContent } from "../../http/get-api";

import ErrorAlert from "../../components/ui/modals/error-model/ErrorAlert";
import Head from "../../layout/head/Head";
import Slider from "react-slick";

function AccountCardSkeleton() {
  return (
    <MuiCard elevation={3} sx={{ minHeight: "20rem", height: "100%", width: "100%" }}>
      <Stack height={"100%"} width={"100%"} justifyContent={"space-between"} gap={2}>
        <CardActionArea sx={{ padding: "1.25rem", height: "100%" }}>
          <Stack gap={1} width={"100%"}>
            <Skeleton variant="rounded" animation={"wave"} width={"100%"} height={"8.75rem"} />
            <Stack width={"100%"}>
              <Skeleton height={"3.25rem"} width={"100%"} variant="text" animation={"wave"} component={"h5"} />
              <Stack>
                <Skeleton variant="text" animation={"wave"} width={"100%"} />
                <Skeleton variant="text" animation={"wave"} width={"80%"} />
                <Skeleton variant="text" animation={"wave"} width={"60%"} />
                <Skeleton variant="text" animation={"wave"} width={"40%"} />
              </Stack>
            </Stack>
          </Stack>
        </CardActionArea>
        <CardActions sx={{ padding: "1.25rem", paddingTop: "0rem" }}>
          <Skeleton variant="text" animation={"wave"} height={"4.5rem"} width={"8rem"} />
        </CardActions>
      </Stack>
    </MuiCard>
  );
}

const App = () => {
  const MUITheme = useMuiTheme();
  const theme = useTheme();
  const isDark = theme.skin === "dark";

  const showApp = useSelector((state) => state.siteSetting.showApp);

  useEffect(() => {
    if (showApp === false) {
      navigate(-1);
    }

    // eslint-disable-next-line
  }, [showApp]);

  const navigate = useNavigate();
  const isMobileView = theme.sidebarMobile;

  //^ states
  const [advertisementSlider, setAdvertisementSlider] = useState([]);
  const [accountLogos, setAccountLogos] = useState([]);
  const [businessApps, setBusinessApps] = useState([]);
  const [personalApps, setPersonalApps] = useState([]);
  const [blogDetails, setBlogDetails] = useState([]);
  //^ accounts advertisement query
  const {
    data: advertisementData,
    isLoading: advertisementIsLoading,
    isRefetching: advertisementIsRefetching,
    isError: advertisementIsError,
    error: advertisementError,
    refetch: advertisementRefetch,
  } = useQuery({
    queryKey: ["get-advertisement-slider-data"],
    queryFn: async ({ signal }) => getAppContent({ signal, app_category: 4 }),
  });

  //^ account logos
  const {
    data: accountLogosData,
    isLoading: accountLogosIsLoading,
    isRefetching: accountLogosIsRefetching,
    isError: accountLogosIsError,
    error: accountLogosError,
    refetch: accountLogosRefetch,
  } = useQuery({
    queryKey: ["accounts-logos"],
    queryFn: async ({ signal }) => getAppContent({ signal, app_category: 3 }),
  });

  //^ account's business details query
  const {
    data: businessData,
    isLoading: businessIsLoading,
    isRefetching: businessIsRefetching,
    isError: businessIsError,
    error: businessError,
    refetch: businessRefetch,
  } = useQuery({
    queryKey: ["get-business-details"],
    queryFn: async ({ signal }) => getAppContent({ signal, app_category: 0 }),
  });

  //^ account's Personal details query
  const {
    data: personalDetailsData,
    isLoading: personalDetailsIsLoading,
    isRefetching: personalDetailsIsRefetching,
    isError: personalDetailsIsError,
    error: personalDetailsError,
    refetch: personalDetailsRefetch,
  } = useQuery({
    queryKey: ["get-personal-details"],
    queryFn: async ({ signal }) => getAppContent({ signal, app_category: 1 }),
  });

  //^ account's Blog details query
  const {
    data: blogDetailsData,
    isLoading: blogDetailsIsLoading,
    isRefetching: blogDetailsIsRefetching,
    isError: blogDetailsIsError,
    error: blogDetailsError,
    refetch: blogDetailsRefetch,
  } = useQuery({
    queryKey: ["get-blog-details"],
    queryFn: async ({ signal }) => getAppContent({ signal, app_category: 2 }),
  });

  useEffect(() => {
    if (!advertisementIsLoading || !advertisementIsRefetching) {
      if (advertisementData?.status) {
        const filteredAdvertisementData = advertisementData?.data?.apps?.map((app) => ({
          id: app.id,
          img: isMobileView
            ? `${process.env.REACT_APP_API_URL}${app.mobile_view}`
            : `${process.env.REACT_APP_API_URL}${app.desktop_view}`,
          heading: app.heading,
          desc: app.description,
          buttonTitle: app.btn_title,
          link: app.link,
          theme: "dark",
        }));

        setAdvertisementSlider(filteredAdvertisementData);
      } else {
        if (advertisementData?.redirect) {
          // navigate(`${process.env.REACT_APP_ACCOUNT_URL}login`);
        }
      }
    }

    // eslint-disable-next-line
  }, [advertisementData, advertisementIsLoading, advertisementIsRefetching, isMobileView]);

  useEffect(() => {
    if (advertisementIsError) {
      console.log(advertisementError?.info);
    }
  }, [advertisementIsError, advertisementError]);

  useEffect(() => {
    if (!accountLogosIsLoading || !accountLogosIsRefetching) {
      if (accountLogosData?.status) {
        const filteredAccountLogosData = accountLogosData?.data?.apps?.map((app) => {
          return {
            id: app.id,
            name: app.name,
            logo_light: app.logo_light,
            logo_dark: app.logo_dark,
          };
        });

        setAccountLogos(filteredAccountLogosData);
      } else {
        if (advertisementData?.redirect) {
          // navigate(`${process.env.REACT_APP_ACCOUNT_URL}login`);
        }
      }
    }

    // eslint-disable-next-line
  }, [accountLogosData, accountLogosIsLoading, accountLogosIsRefetching]);

  useEffect(() => {
    if (accountLogosIsError) {
      console.log(accountLogosError?.info);
    }
  }, [accountLogosIsError, accountLogosError]);

  //^ Business account useEffect
  useEffect(() => {
    if (!businessIsLoading || !businessIsRefetching) {
      if (businessData?.status) {
        const filteredBusinessDetailsData = businessData?.data?.apps?.map((app) => ({
          id: app.id,
          name: app.name,
          link: app.link,
          logo: isDark
            ? `${process.env.REACT_APP_API_URL}${app.logo_dark}`
            : `${process.env.REACT_APP_API_URL}${app.logo_light}`,
          description: app.description,
        }));

        setBusinessApps(filteredBusinessDetailsData);
      } else {
        if (businessData?.redirect) {
          // navigate(`${process.env.REACT_APP_ACCOUNT_URL}login`);
        }
      }
    }

    // eslint-disable-next-line
  }, [businessData, businessIsLoading, businessIsRefetching, isDark]);

  useEffect(() => {
    if (businessIsError) {
      // console.log(businessError?.info);
    }
  }, [businessIsError, businessError]);

  //^ Personal app useEffect
  useEffect(() => {
    if (!personalDetailsIsLoading || !personalDetailsIsRefetching) {
      if (personalDetailsData?.status) {
        const filteredPersonalAppDetailsData = personalDetailsData?.data?.apps?.map((app) => ({
          id: app.id,
          name: app.name,
          link: app.link,
          logo: isDark
            ? `${process.env.REACT_APP_API_URL}${app.logo_dark}`
            : `${process.env.REACT_APP_API_URL}${app.logo_light}`,
          description: app.description,
        }));

        setPersonalApps(filteredPersonalAppDetailsData);
      } else {
        if (personalDetailsData?.redirect) {
          // navigate(`${process.env.REACT_APP_ACCOUNT_URL}login`);
        }
      }
    }

    // eslint-disable-next-line
  }, [personalDetailsData, personalDetailsIsLoading, personalDetailsIsRefetching, isDark]);

  useEffect(() => {
    if (personalDetailsIsError) {
      // console.log(personalDetailsError?.info);
    }
  }, [personalDetailsIsError, personalDetailsError]);

  //^ blog details useEffect
  useEffect(() => {
    if (!blogDetailsIsLoading || !blogDetailsIsRefetching) {
      if (blogDetailsData?.status) {
        const filteredPersonalAppDetailsData = blogDetailsData?.data?.apps?.map((app) => ({
          id: app.id,
          name: app.name,
          description: app.description,
          btnTitle: app.btn_title,
          link: app.link,
          logo: `${process.env.REACT_APP_API_URL}${app.logo_light}`,
        }));

        setBlogDetails(filteredPersonalAppDetailsData);
      } else {
        if (blogDetailsData?.redirect) {
          // navigate(`${process.env.REACT_APP_ACCOUNT_URL}login`);
        }
      }
    }

    // eslint-disable-next-line
  }, [blogDetailsData, blogDetailsIsLoading, blogDetailsIsRefetching]);

  useEffect(() => {
    if (blogDetailsIsError) {
      // console.log(blogDetailsError?.info);
    }
  }, [blogDetailsIsError, blogDetailsError]);

  const settings = {
    className: "slider-init",
    slidesToShow: 1,
    centerMode: false,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      { breakpoint: 1539, settings: { slidesToShow: 1 } },
      { breakpoint: 992, settings: { slidesToShow: 1 } },
      { breakpoint: 768, settings: { slidesToShow: 1 } },
    ],
    arrows: false,
    dots: true,
  };

  return (
    <>
      {advertisementIsError && (
        <ErrorAlert
          title={`${advertisementError?.status || 500}`}
          description={advertisementError?.info?.message || "Something went wrong"}
          isConformed={() => advertisementRefetch()}
        />
      )}
      {accountLogosIsError && (
        <ErrorAlert
          title={`${accountLogosError?.status || 500}`}
          description={accountLogosError?.info?.message || "Something went wrong"}
          isConformed={() => accountLogosRefetch()}
        />
      )}
      {businessIsError && (
        <ErrorAlert
          title={`${businessError?.status || 500}`}
          description={businessError?.info?.message || "Something went wrong"}
          isConformed={() => businessRefetch()}
        />
      )}
      {personalDetailsIsError && (
        <ErrorAlert
          title={`${personalDetailsError?.status || 500}`}
          description={personalDetailsError?.info?.message || "Something went wrong"}
          isConformed={() => personalDetailsRefetch()}
        />
      )}
      {blogDetailsIsError && (
        <ErrorAlert
          title={`${blogDetailsError?.status || 500}`}
          description={blogDetailsError?.info?.message || "Something went wrong"}
          isConformed={() => blogDetailsRefetch()}
        />
      )}
      <Head title={"Apps"} />

      {/* <SimpleBar> */}
      <Stack gap={"5rem"}>
        {advertisementIsLoading ? (
          <Paper
            elevation={0}
            sx={{
              backgroundColor: MUITheme.palette.mode === "dark" ? MUITheme.palette.backgroundDark.main : "",
            }}
            className="rounded-4 w-100 h-100 pb-4"
          >
            <Stack gap={"2rem"}>
              <Box>
                <Skeleton animation="wave" variant="rectangular" width={"100%"} sx={{ height: "30.6rem" }} />
              </Box>

              <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} gap={"0.625rem"}>
                <Box>
                  <Skeleton variant="circular" animation="wave" sx={{ width: "8px", height: "8px" }} />
                </Box>
                <Box>
                  <Skeleton variant="circular" animation="wave" sx={{ width: "8px", height: "8px" }} />
                </Box>
              </Stack>
            </Stack>
          </Paper>
        ) : (
          <Paper
            elevation={0}
            sx={{
              backgroundColor: MUITheme.palette.mode === "dark" ? MUITheme.palette.backgroundDark.main : "",
            }}
            className="rounded-4 w-100 pb-4"
          >
            <Slider {...settings}>
              {advertisementSlider.map((item) => (
                <Col key={item.id}>
                  <div>
                    <div className="position-relative">
                      <Stack
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          position: "absolute",
                          top: 0,
                          gap: "1rem",
                          zIndex: 1000,
                          paddingLeft: "2rem",
                          paddingRight: "2rem",
                          paddingTop: "2rem",
                          justifyContent: "start",
                          "@media (min-width: 65.5rem)": {
                            justifyContent: "center",
                            paddingLeft: "8.7rem",
                            paddingRight: "0rem",
                            paddingTop: "0rem",
                            width: "55%",
                          },
                        }}
                      >
                        <Stack gap={"1rem"}>
                          <Box>
                            <Typography variant="h3" className="d-none d-sm-block text-black">
                              {item.heading}
                            </Typography>
                            <Typography variant="h5" className="d-block d-sm-none text-black">
                              {item.heading}
                            </Typography>
                          </Box>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              "@media(min-width: 65.5rem)": { width: "80%" },
                            }}
                            className="text-black"
                          >
                            {item.desc}
                          </Typography>
                        </Stack>
                        <div>
                          <Button
                            variant="contained"
                            size="large"
                            onClick={() => (window.location.href = `${item.link}`)}
                          >
                            {item.buttonTitle}
                          </Button>
                        </div>
                      </Stack>
                      <div style={{ backgroundColor: "#141318", width: "100%", height: "30rem" }}>
                        <img
                          src={item.img}
                          className="h-100 w-100"
                          alt="placeholder-img"
                          style={{ opacity: 1, objectFit: "cover" }}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Slider>
          </Paper>
        )}

        <Container>
          <Grid
            container
            columns={{ xs: 1, sm: 2, md: 6 }}
            justifyContent={"center"}
            sx={{ gap: accountLogosIsLoading ? "1rem" : "" }}
          >
            {accountLogosIsLoading
              ? Array.from({ length: 5 }).map((_, index) => (
                  <Grid key={index} item xs={12} sm={6} md={1} alignItems={"center"}>
                    <Skeleton variant="rectangular" height={"5rem"} />
                  </Grid>
                ))
              : accountLogos.map((logo, index) => (
                  <Grid key={index} item xs={12} sm={6} md={1} alignItems={"center"} justifyContent={"center"}>
                    <Stack alignItems={"center"} justifyContent={"center"} height={"100%"}>
                      {isDark ? (
                        <Box dangerouslySetInnerHTML={{ __html: logo?.logo_dark }} />
                      ) : (
                        <Box dangerouslySetInnerHTML={{ __html: logo?.logo_light }} />
                      )}
                    </Stack>
                  </Grid>
                ))}
          </Grid>
        </Container>

        <div className="container d-flex flex-column">
          <p className="fs-2 fw-bold">For Business</p>
          <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3">
            {businessIsLoading
              ? Array.from({ length: 3 }).map((_, index) => (
                  <Col lg="4" key={index} className="mb-3">
                    <AccountCardSkeleton />
                  </Col>
                ))
              : businessApps.map((app, index) => (
                  <Col lg="4" key={index} className="mb-3">
                    <MuiCard elevation={3} sx={{ minHeight: "20rem", height: "100%" }}>
                      <Stack height={"100%"} justifyContent={"space-between"} gap={2}>
                        <CardActionArea sx={{ height: "100%" }} onClick={() => (window.location.href = app.link)}>
                          <CardMedia component="img" sx={{ height: "12.5rem" }} image={app.logo} alt={app.name} />
                          <CardContent sx={{ padding: "1.25rem" }}>
                            <Typography gutterBottom variant="h5" component="div">
                              {app.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {app.description}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions sx={{ padding: "1.25rem", paddingTop: "0rem" }}>
                          <a href={app.link} className="text-decoration-none">
                            <Button variant="contained">Explore Now</Button>
                          </a>
                        </CardActions>
                      </Stack>
                    </MuiCard>
                    <a href={app.link} className="d-none text-decoration-none">
                      <Card
                        className={`${classes["app-card"]} card-bordered shadow`}
                        style={{ height: "100%", cursor: "pointer" }}
                      >
                        <div className="d-flex h-100 flex-column justify-content-between">
                          <CardImg top src={app.logo} alt={app.name} />
                          <CardBody className="card-inner d-flex flex-column justify-content-start ">
                            <CardTitle tag="h5">{app.name}</CardTitle>
                            <CardText>{app.description}</CardText>
                          </CardBody>

                          <div className="card-inner pt-0">
                            <a href={app.link} className="text-decoration-none">
                              <Button variant="contained">Explore Now</Button>
                            </a>
                          </div>
                        </div>
                      </Card>
                    </a>
                  </Col>
                ))}
          </Row>
        </div>
        <div className="container d-flex flex-column">
          <p className="fs-2 fw-bold">For Personal</p>
          <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3">
            {personalDetailsIsLoading
              ? Array.from({ length: 3 }).map((_, index) => (
                  <Col lg="4" key={index} className="mb-3">
                    <AccountCardSkeleton />
                  </Col>
                ))
              : personalApps.map((app, index) => (
                  <Col lg="4" key={index} className="mb-3">
                    <MuiCard elevation={3} sx={{ minHeight: "20rem", height: "100%" }}>
                      <Stack height={"100%"} justifyContent={"space-between"} gap={2}>
                        <CardActionArea sx={{ height: "100%" }} onClick={() => (window.location.href = app.link)}>
                          <CardMedia component="img" sx={{ height: "12.5rem" }} image={app.logo} alt={app.name} />
                          <CardContent sx={{ padding: "1.25rem" }}>
                            <Typography gutterBottom variant="h5" component="div">
                              {app.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {app.description}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions sx={{ padding: "1.25rem", paddingTop: "0rem" }}>
                          <a href={app.link} className="text-decoration-none">
                            <Button variant="contained">Explore Now</Button>
                          </a>
                        </CardActions>
                      </Stack>
                    </MuiCard>
                    <a href={app.link} className="d-none text-decoration-none">
                      <Card
                        className={`${classes["app-card"]} card-bordered shadow`}
                        style={{ height: "100%", cursor: "pointer" }}
                      >
                        <div className="d-flex h-100 flex-column justify-content-between">
                          <CardImg top src={app.logo} alt={app.name} />
                          <CardBody className="card-inner d-flex flex-column justify-content-start ">
                            <CardTitle tag="h5">{app.name}</CardTitle>
                            <CardText>{app.description}</CardText>
                          </CardBody>

                          <div className="card-inner pt-0">
                            <a href={app.link} className="text-decoration-none">
                              <Button variant="contained">Explore Now</Button>
                            </a>
                          </div>
                        </div>
                      </Card>
                    </a>
                  </Col>
                ))}
          </Row>
        </div>
        <div className="container d-flex flex-column">
          <p className="fs-2 fw-bold">Explore more about {process.env.REACT_APP_PROJECT_NAME}</p>
          <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3">
            {blogDetailsIsLoading
              ? Array.from({ length: 3 }).map((_, index) => (
                  <Col lg="4" key={index} className="mb-3">
                    <AccountCardSkeleton />
                  </Col>
                ))
              : blogDetails.map((app, index) => (
                  <Col lg="4" key={index} className="mb-3">
                    <MuiCard elevation={3} sx={{ minHeight: "20rem", height: "100%" }}>
                      <Stack height={"100%"} justifyContent={"space-between"} gap={2}>
                        <CardActionArea sx={{ height: "100%" }} onClick={() => (window.location.href = app.link)}>
                          <CardMedia
                            component="img"
                            sx={{ height: "12.5rem", width: "100%", objectFit: "cover" }}
                            image={app.logo}
                            alt={app.name}
                          />
                          <CardContent sx={{ padding: "1.25rem" }}>
                            <Typography gutterBottom variant="h5" component="div">
                              {app.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {app.description}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions sx={{ padding: "1.25rem", paddingTop: "0rem" }}>
                          <a href={app.link} className="text-decoration-none">
                            <Button variant="contained">Explore Now</Button>
                          </a>
                        </CardActions>
                      </Stack>
                    </MuiCard>
                    <a href={app.link} className="d-none text-decoration-none">
                      <Card
                        className={`${classes["app-card"]} card-bordered shadow`}
                        style={{ height: "100%", cursor: "pointer" }}
                      >
                        <div className="d-flex h-100 flex-column justify-content-between">
                          <CardImg
                            top
                            src={app.logo}
                            alt={app.name}
                            style={{ height: "12.5rem", objectFit: "cover" }}
                          />
                          <CardBody className="card-inner d-flex flex-column justify-content-start ">
                            <CardTitle tag="h5">{app.name}</CardTitle>
                            <CardText>{app.description}</CardText>
                          </CardBody>

                          <div className="card-inner pt-0">
                            <a href={app.link} className="text-decoration-none">
                              <Button variant="contained">Read Now</Button>
                            </a>
                          </div>
                        </div>
                      </Card>
                    </a>
                  </Col>
                ))}
          </Row>
        </div>
      </Stack>

      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm d-none">
                <div className="nk-block-between mt-2">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">App List</h3>
                  </div>
                  <div className="nk-block-head-content mt-2 mt-md-0">
                    <a href="/subscription-package" className="btn btn-primary">
                      Plans
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </SimpleBar> */}
    </>
  );
};

export default App;
