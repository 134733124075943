import { useEffect, useState } from "react";
import { Outlet } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

//^ http request
import { getUserListsSummaryHandler } from "../../http/post-api";

import { PeopleAltRounded, PersonRounded, GroupAddRounded, GroupsRounded, Home } from "@mui/icons-material";
import { Box, Stack, Tab, Tabs } from "@mui/material";

//^ components
import BottomNavigations from "../../components/navigations/bottom-navigations";
import Head from "../head/Head";
import UserAccountLoader from "../../components/ui/loader/Skeleton/user-account-loader";
import { TabContext } from "@mui/lab";

export default function AccountUserLayout() {
  const [userNavList, setUserNavList] = useState([]);
  const [bottomNavVal, setBottomNavVal] = useState("");

  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const tabName = searchParams.get("tab") || "";

  const {
    data: userListSummaryData,
    isLoading: userListSummaryIsLoading,
    isError: userListSummaryIsError,
    error: userListSummaryError,
    // refetch: userListSummaryRefetch,
  } = useQuery({
    queryKey: ["get-user-list-summary"],
    queryFn: getUserListsSummaryHandler,
  });

  useEffect(() => {
    if (!userListSummaryIsLoading) {
      if (!userListSummaryData?.status) {
        if (userListSummaryData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        } else {
          toast.error(userListSummaryData?.data?.message);
        }
      } else {
        const navList = userListSummaryData?.data?.summary.map((summary) => ({
          label: summary?.label === "Following & Follower" ? "Followers" : summary?.label,
          value: summary.label,
          icon:
            summary?.label === "Connections" ? (
              <PeopleAltRounded />
            ) : summary?.label === "Requests" ? (
              <GroupAddRounded />
            ) : summary?.label === "Following & Follower" ? (
              <PersonRounded />
            ) : summary?.label === "Invitations" ? (
              <GroupsRounded />
            ) : (
              ""
            ),
        }));
        navList.unshift({
          label: "Home",
          value: null,
          icon: <Home />,
        });

        setUserNavList(navList);
      }
    }
    // eslint-disable-next-line
  }, [userListSummaryIsLoading, userListSummaryData]);

  useEffect(() => {
    if (userListSummaryIsError) {
      toast.error(userListSummaryError.message);
    }
  }, [userListSummaryIsError, userListSummaryError]);

  function handleAccountUserTabChange(_event, value) {
    if (value !== tabName) {
      setSearchParams({ tab: value });
      if (value === "") {
        setSearchParams({});
      }
    }
  }

  return (
    <>
      <Head title={`${process.env.REACT_APP_PROJECT_SHORT_NAME} Networks`} />
      <Stack height={"100%"}>
        {userListSummaryIsLoading && (
          <Box
            sx={{
              display: "block",
              "@media (min-width: 65.5rem)": {
                display: "none",
              },
            }}
          >
            <UserAccountLoader />
          </Box>
        )}
        <Stack
          width={"100%"}
          height={"100%"}
          gap={2}
          sx={{
            paddingBottom: "4.25rem",
            display: userListSummaryIsLoading ? "none" : "block",
            "@media (min-width: 65.5rem)": {
              paddingBottom: "0rem",
              display: "block",
            },
          }}
        >
          <Stack
            sx={{
              display: "none",
              "@media (min-width: 65.5rem)": {
                display: "block",
              },
            }}
          >
            <TabContext value={tabName}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabName}
                  onChange={handleAccountUserTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label="Home" value={""} />
                  {userListSummaryData?.data?.summary?.map((userSummary, index) => (
                    <Tab key={index} label={userSummary?.label} value={userSummary?.label} />
                  ))}
                </Tabs>
              </Box>
            </TabContext>
          </Stack>
          <Box
            height={"100%"}
            width={"100%"}
            gap={2}
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr",
              "@media (min-width: 65.5rem)": {
                gridTemplateColumns: "1fr",
                direction: "row",
              },
            }}
          >
            <Box
              sx={{
                display: "none",
                "@media (min-width: 65.5rem)": {
                  display: "block",
                },
              }}
            >
              {/* <Card variant="outlined" sx={{ borderRadius: "0.625rem" }}>
                <List
                  sx={{ width: "100%" }}
                  component={Paper}
                  elevation={1}
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader
                      component={Paper}
                      elevation={1}
                      sx={{ boxShadow: "none" }}
                      id="nested-list-subheader"
                    >
                      Manage My Silo Networks
                    </ListSubheader>
                  }
                >
                  {userListSummaryIsLoading ? (
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      width={"100%"}
                      height={"15rem"}
                    >
                      <Loader />
                    </Stack>
                  ) : (
                    <>
                      <ListItemButton onClick={() => setSearchParams({})} selected={tabName === ""}>
                        <ListItemIcon>
                          <Home />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Stack
                              direction={"row"}
                              width={"100%"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <Typography variant="subtitle1">{"Home"}</Typography>
                            </Stack>
                          }
                        />
                      </ListItemButton>
                      {userListSummaryData?.data?.summary?.map((userSummary, index) => (
                        <ListItemButton
                          onClick={() => setSearchParams({ tab: userSummary?.label })}
                          key={index}
                          selected={userSummary?.label === tabName}
                        >
                          <ListItemIcon>
                            {userSummary?.label === "Connections" ? (
                              <PeopleAltRounded />
                            ) : userSummary?.label === "Requests" ? (
                              <GroupAddRounded />
                            ) : userSummary?.label === "Following & Follower" ? (
                              <PersonRounded />
                            ) : userSummary?.label === "Invitations" ? (
                              <GroupsRounded />
                            ) : (
                              ""
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Stack
                                direction={"row"}
                                width={"100%"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                              >
                                <Typography variant="subtitle1">{userSummary?.label}</Typography>
                                <Typography variant="subtitle2">{userSummary?.value}</Typography>
                              </Stack>
                            }
                          />
                        </ListItemButton>
                      ))}
                    </>
                  )}
                </List>
              </Card> */}
            </Box>
            <Stack width={"100%"}>
              <Outlet />
            </Stack>
          </Box>
        </Stack>
        {!userListSummaryIsLoading ? (
          <BottomNavigations
            showLabels
            value={bottomNavVal}
            setValue={setBottomNavVal}
            elevation={1}
            sx={{
              width: "100%",
              position: "fixed",
              bottom: 0,
              left: 0,
              zIndex: 100,
              "@media (min-width: 65.5rem)": {
                display: "none",
              },
              borderRadius: "0rem",
            }}
            actions={userNavList}
          />
        ) : (
          ""
        )}
      </Stack>
    </>
  );
}
