import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ShimmerThumbnail } from "react-shimmer-effects";

const LogoFetch = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("field_key", "logo");
      // formData.append("field_key", "logo_dark");  
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-site-settings`, {
        method: "POST",
        body: formData,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setData(responseData?.data?.site_settings?.[0]?.field_value);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      {data ? (
        // <div className="nk-apps-brand">
          <Link className="logo-link" to={`${process.env.REACT_APP_LANDINGPAGE_URL}`}>
            <img className="logo-light logo-img logo-img-lg" src={`${process.env.REACT_APP_API_URL}`+ data} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={`${process.env.REACT_APP_API_URL}`+ data} alt="logo-dark" />

          </Link>
        // </div>
      ) : (
        <div className="nk-apps-brand">
          <ShimmerThumbnail height={100} width={100} className="m-0" />
        </div>
      )}
    </>
  );
};
export default LogoFetch;
