import React from "react";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";

//^ @mui components
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { useTheme } from "@mui/material/styles";

//^ components
import Search from "../../../components/search";
import ConnectedListSkeleton from "../../../components/ui/loader/Skeleton/follow-list-skeleton";

function ConnectionList(props) {
  return (
    <Stack
      height={"100%"}
      // maxHeight={"33rem"}
      sx={
        {
          // overflowX: "auto",
        }
      }
    >
      {props.table.getRowModel()?.rows?.length ? (
        props.table.getRowModel()?.rows?.map((row) =>
          row?.getVisibleCells()?.map((cell) => {
            return (
              <Box
                key={cell.id}
                sx={{
                  // border: "2px solid red",
                  display:
                    cell.getContext().column.id === "username" ||
                    cell.getContext().column.id === "lastName" ||
                    cell.getContext().column.id === "created_at" ||
                    cell.getContext().column.id === "email"
                      ? "none"
                      : "",
                }}
              >
                <Typography variant="subtitle2" fontWeight={400}>
                  {cell.getValue()}
                </Typography>
              </Box>
            );
          })
        )
      ) : (
        <Box
          colSpan={props.length}
          style={{
            height: "6rem",
          }}
        >
          <Stack alignItems={"center"} justifyContent={"center"} height={"100%"}>
            <Typography textTransform={"capitalize"} fontWeight={"bold"}>
              No results.
            </Typography>
          </Stack>
        </Box>
      )}
    </Stack>
  );
}

export default function DataTable({ columns, data, connectionCount, getUserConnectIsLoading }) {
  const [connectionFilter, setConnectionFilter] = React.useState("none");
  const [sorting, setSorting] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const connectionType = searchParams.get("connectType") || "connected";

  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const addConnectionTypeParam = ({ value, type = "append" }) => {
    if (type === "append") {
      const params = new URLSearchParams(searchParams);
      params.append("connectType", value);
      setSearchParams(params.toString());
    } else if (type === "set") {
      const params = new URLSearchParams(searchParams);
      params.set("connectType", value);
      setSearchParams(params.toString());
    }
  };

  function handleStatusSelectChange(event) {
    const recentValues = event.target.value;

    setConnectionFilter(recentValues);
    if (recentValues !== "none") {
      setSorting([
        {
          id: recentValues,
          asc: true,
        },
      ]);
    } else {
      setSorting([]);
    }
  }

  const table = useReactTable({
    data: data || [],
    columns: columns || [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      pagination,
    },
  });

  function handleSearchChange(event) {
    table.getColumn("username")?.setFilterValue(event.target.value);
  }

  function paginationChangeHandler(_event, page) {
    const actualPage = page - 1;
    table.setPageIndex(actualPage);
  }

  function handleConnectionListTabChange(_event, value) {
    if (value !== connectionType) {
      addConnectionTypeParam({ value, type: "set" });
    }
  }

  return (
    <Stack>
      <Paper elevation={isDark ? 1 : 3} sx={{ padding: "1.25rem", borderRadius: "0.425rem" }}>
        <Stack gap={2}>
          <Stack gap={2}>
            <Typography variant="h5" fontWeight={400} textTransform={"capitalize"}>
              {connectionCount} Connected Users
            </Typography>
            <Stack
              gap={2}
              sx={{
                "@media (min-width: 65.5rem)": {
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                },
              }}
            >
              <Stack direction={"row"} gap={2} alignItems={"center"}>
                <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                  <InputLabel id="status-outlined-select-label">Sort By</InputLabel>
                  <Select
                    size="small"
                    value={connectionFilter}
                    onChange={handleStatusSelectChange}
                    labelId="status-outlined-select-label"
                    id="status-outlined-select"
                    label="Sort by"
                  >
                    <MenuItem value="none">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"username"}>Username</MenuItem>
                    <MenuItem value={"email"}>Email</MenuItem>
                    <MenuItem value={"created_at"}>Recent</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Stack>
                <Search
                  width={"20ch"}
                  hoverwidth={"30ch"}
                  placeholder={"Search for username..."}
                  onChange={handleSearchChange}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <TabContext value={connectionType === null ? "" : connectionType}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={connectionType === null ? "" : connectionType}
                  onChange={handleConnectionListTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label="Connections" value={"connected"} />
                  <Tab label="Not Connected" value={"notConnected"} />
                </Tabs>
              </Box>
              <TabPanel sx={{ padding: "0rem" }} value={"connected"}>
                {getUserConnectIsLoading ? (
                  <ConnectedListSkeleton />
                ) : (
                  <ConnectionList table={table} columns={columns} />
                )}
              </TabPanel>
              <TabPanel sx={{ padding: "0rem" }} value={"notConnected"}>
                {getUserConnectIsLoading ? (
                  <ConnectedListSkeleton />
                ) : (
                  <ConnectionList table={table} columns={columns} />
                )}
              </TabPanel>
            </TabContext>
          </Stack>

          <Stack direction={"row"} alignItems={"flex-end"} justifyContent={"flex-end"}>
            <Pagination
              count={table.getPageCount()}
              onChange={paginationChangeHandler}
              variant="outlined"
              shape="rounded"
            />
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
}
