import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import SimpleBar from "simplebar-react";

//^ stylesheet
import classes from "../layout.module.scss";

import Sidebar from "../sidebar/Sidebar";
import Head from "../head/Head";
import AppHeader from "../header/AppHeader";
import AppRoot from "../global/AppRoot";
import AppMain from "../global/AppMain";
import AppWrap from "../global/AppWrap";
import { useTheme } from "../provider/Theme";
import { useTheme as useMuiTheme } from "@mui/material/styles";

//^ redux actions
import { userProfileAction } from "../../store/slices/user-profile-slice";
import { muiAction } from "../../store/slices/mui-slice";
import { siteSettingAction } from "../../store/slices/site-setting-slice";

//^ http request
import { getUserProfileHandler } from "../../http/get-api";
import { getSiteSettingsHandler } from "../../http/post-api";

//^ ui
import { Box, Paper } from "@mui/material";

import { UserContext } from "../../pages/pre-built/user-manage/UserContext";
import Profile from "../Profile";

//^ components
import ErrorAlert from "../../components/ui/modals/error-model/ErrorAlert";
import OutletSkeleton from "../../components/ui/loader/Skeleton/OutletSkeleton";

export default function UserLayoutApp({ title, app }) {
  const muiTheme = useSelector((state) => state.mui.theme);

  const theme = useTheme();
  const MUITheme = useMuiTheme();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUser = useContext(UserContext);

  //^ state
  const [data, setData] = useState(loggedInUser);
  const [sm, updateSm] = useState(false);
  // eslint-disable-next-line
  const [authChecking, setAuthChecking] = useState(true);
  const [mobileView, setMobileView] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState({});

  //& Error States

  //^ get site settings query fn
  const {
    data: getSiteSettingsData,
    isLoading: getSiteSettingsIsLoading,
    isRefetching: getSiteSettingsIsRefetching,
    isError: getSiteSettingsIsError,
    error: getSiteSettingsError,
    refetch: getSiteSettingsRefetch,
  } = useQuery({
    queryKey: ["get-site-settings"],
    queryFn: getSiteSettingsHandler,
    gcTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!getSiteSettingsIsLoading || !getSiteSettingsIsRefetching) {
      if (!getSiteSettingsData?.status) {
        if (getSiteSettingsData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        }
      } else {
        const findPaymentMethod = getSiteSettingsData?.data?.site_settings?.find(
          (setting) => setting.field_key === "payment_method"
        );
        let clientId = "";
        let merchantId = "";
        let showApp = "";
        let showService = "";
        let showSubscription = "";
        let paymentMethod = "";

        for (let siteSetting of getSiteSettingsData?.data?.site_settings) {
          if (siteSetting.field_key === "paypal_client_id") {
            clientId = siteSetting.field_value;
          }
          if (siteSetting.field_key === "payment_merchant_id") {
            merchantId = siteSetting.field_value;
          }
          if (siteSetting.field_key === "apps") {
            showApp = siteSetting.field_value;
          }
          if (siteSetting.field_key === "service_subscription") {
            showService = siteSetting.field_value;
          }

          if (siteSetting.field_key === "package_subscription") {
            showSubscription = siteSetting.field_value;
          }

          if (siteSetting.field_key === "payment_method") {
            paymentMethod = siteSetting.field_value;
          }
        }

        setPaymentMethod(findPaymentMethod || {});

        dispatch(
          siteSettingAction.setSiteSettingHandler({
            clientId: clientId,
            merchantId,
            showApp,
            showService,
            showSubscription,
            paymentMethod,
          })
        );
      }
    }
  }, [getSiteSettingsData, getSiteSettingsIsLoading, getSiteSettingsIsRefetching, dispatch]);

  useEffect(() => {
    if (getSiteSettingsIsError) {
      console.log(getSiteSettingsError);
    }
  }, [getSiteSettingsIsError, getSiteSettingsError]);

  useEffect(() => {
    if (getSiteSettingsIsError) {
      console.log(getSiteSettingsError);
    }
  }, [getSiteSettingsIsError, getSiteSettingsError]);

  useEffect(() => {
    if (theme.skin !== muiTheme) {
      dispatch(muiAction.setTheme(theme.skin));
      document.documentElement.style.setProperty("--bar-color", theme.skin === "dark" ? "#5a5a5a" : "#ccc");
      document.documentElement.style.setProperty("--track-color", theme.skin === "dark" ? "#333" : "#f0f0f0");
    }
  }, [theme.skin, dispatch, muiTheme]);

  const {
    data: userProfileData,
    isLoading: userProfileIsLoading,
    isRefetching: userProfileIsRefetching,
    isError: userProfileIsError,
    error: userProfileError,
    refetch: userProfileRefetch,
  } = useQuery({
    queryKey: ["get-user-profile-user-layout"],
    queryFn: ({ signal }) => getUserProfileHandler({ signal }),
  });

  useEffect(() => {
    if (!userProfileIsLoading || !userProfileIsRefetching) {
      if (userProfileData?.status) {
        dispatch(userProfileAction.setWholeProfileData({ wholeProfileData: userProfileData?.data }));
        dispatch(userProfileAction.setProfileData({ profileData: userProfileData?.data.profile }));
        dispatch(userProfileAction.setReferAndEarnData({ referAndEarnData: userProfileData?.data?.refer_data }));
        dispatch(userProfileAction.setTokenValue(userProfileData?.data?.token_value));
      } else {
        if (userProfileData?.redirect) {
          navigate("/login");
        }
      }
    }
    // eslint-disable-next-line
  }, [userProfileIsLoading, userProfileIsRefetching, userProfileData, navigate]);

  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  //^ Initial useEffect
  useEffect(() => {
    const cookieValue = Cookies.get("authToken");
    if (!cookieValue) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let at = Cookies.get("authToken");
    if (!at) {
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
      Cookies.set("currentURL", window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
      window.location = process.env.REACT_APP_ACCOUNT_URL;
    } else {
      setAuthChecking(false);
    }

    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, [navigate]);

  return (
    <>
      {getSiteSettingsIsError && (
        <ErrorAlert
          title={`${getSiteSettingsError?.code || 500}`}
          description={`${getSiteSettingsError?.info?.data?.message || "Something went wrong"}`}
          isConformed={() => getSiteSettingsRefetch()}
        />
      )}

      {userProfileIsError && (
        <ErrorAlert
          title={`${userProfileError?.code || 500}`}
          description={`${userProfileError?.info?.message || "Something went wrong"}`}
          isConformed={() => userProfileRefetch()}
        />
      )}
      <UserContext.Provider value={{ loggedInUser: data, setData }}>
        <Profile />
        <Head title={!title && "Loading"} />
        <AppRoot>
          <SimpleBar>
            <AppMain>
              <Sidebar
                updateSm={updateSm}
                sm={sm}
                mobileView={mobileView}
                fixed
                fieldValue={paymentMethod?.field_value}
              />
              <AppWrap className={"w-100"}>
                <AppHeader app={app} fixed />
                {getSiteSettingsIsLoading || getSiteSettingsIsRefetching ? (
                  <Box
                    sx={{
                      "@media (min-width: 65.5rem)": {
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                      },
                    }}
                  >
                    <Paper className="rounded-4 w-100 p-4 vh-100">
                      <OutletSkeleton />
                    </Paper>
                  </Box>
                ) : (
                  <div className={`${classes["layout-relative"]} mt-4 pb-4 w-100`}>
                    <Box
                      sx={{
                        "@media (min-width: 65.5rem)": {
                          width: "100%",
                          ...MUITheme.palette.cardInner.lg,
                        },
                      }}
                    >
                      {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)} />}
                      <Outlet />
                    </Box>
                  </div>
                )}
              </AppWrap>
            </AppMain>
          </SimpleBar>
        </AppRoot>
      </UserContext.Provider>
    </>
  );
}
