import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

//^ mui components
import { Box, Tab, Tabs } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { CardMembership, Loyalty, Receipt } from "@mui/icons-material";

//^ pages
import SubscriptionPackage from "../pre-built/package-subscription/subscription-packages/SubscriptionPackage";
import ServicePage from "../service/ServicePage";
import AccountInvoicesPage from "../account-invoices-page/AccountInvoicesPage";
import BottomNavigations from "../../components/navigations/bottom-navigations";

export default function SubscriptionsPage() {
  const showService = useSelector((state) => state.siteSetting.showService);
  const [bottomNavVal, setBottomNavVal] = React.useState("");

  const [searchParams, setSearchParams] = useSearchParams({ tab: "packageSubscriptions" });
  const tabValue = searchParams.get("tab");

  const handleChange = (_event, newValue) => {
    setSearchParams({ tab: newValue });
  };

  const navList = [
    {
      label: "Package",
      value: "packageSubscriptions",
      icon: <Loyalty />,
    },
    {
      label: "Service",
      value: "serviceSubscriptions",
      icon: <CardMembership />,
    },
    {
      label: "Invoice",
      value: "invoiceSubscriptions",
      icon: <Receipt />,
    },
  ];

  return (
    <>
      <TabContext value={tabValue}>
        <Box
          sx={{
            display: "none",
            borderBottom: 1,
            borderColor: "divider",
            "@media (min-width: 65.5rem)": {
              display: "block",
            },
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Package Subscriptions" value={"packageSubscriptions"} />
            {showService && <Tab label="Service Subscriptions" value={"serviceSubscriptions"} />}
            <Tab label="Invoices" value={"invoiceSubscriptions"} />
          </Tabs>
        </Box>
        <TabPanel
          sx={{
            padding: "2.25rem 0rem 3.25rem 0rem",
            "@media(min-width: 65.5rem)": {
              padding: "2.25rem 0rem 0rem 0rem",
            },
          }}
          value={"packageSubscriptions"}
        >
          <SubscriptionPackage />
        </TabPanel>
        {showService && (
          <TabPanel
            sx={{
              padding: "2.25rem 0rem 3.25rem 0rem",
              "@media(min-width: 65.5rem)": {
                padding: "2.25rem 0rem 0rem 0rem",
              },
            }}
            value={"serviceSubscriptions"}
          >
            <ServicePage />
          </TabPanel>
        )}
        <TabPanel
          sx={{
            padding: "2.25rem 0rem 3.25rem 0rem",
            "@media(min-width: 65.5rem)": {
              padding: "2.25rem 0rem 0rem 0rem",
            },
          }}
          value={"invoiceSubscriptions"}
        >
          <AccountInvoicesPage />
        </TabPanel>
      </TabContext>
      <BottomNavigations
        showLabels
        value={bottomNavVal}
        setValue={setBottomNavVal}
        elevation={1}
        sx={{
          width: "100%",
          position: "fixed",
          bottom: 0,
          left: 0,
          zIndex: 100,
          "@media (min-width: 65.5rem)": {
            display: "none",
          },
          borderRadius: "0rem",
        }}
        actions={navList}
        key={"tab"}
      />
    </>
  );
}
