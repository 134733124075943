import { useCallback, useState } from "react";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import PhoneInput from "react-phone-number-input";
import { countries } from "country-data";
import { toast } from "react-toastify";
import * as yup from "yup";

//^ http requests
import { postSet2FAProfileHandler } from "../../../../../http/post-api";

//^ helper funcs
import { getCookie } from "../../../../../utils/Utils";
import { useTheme } from "../../../../../layout/provider/Theme";

//^ stylesheet
import classes from "./TwoFactAuth.module.scss";
import inputClasses from "../../../../../assets/scss/components/Input/PasswordInput.module.scss";
import phoneClasses from "../two-factor-options/TwoFactAuth.module.scss";

//^ mui
import { Box, Stack, Button, CircularProgress } from "@mui/material";

//^ components
import { Icon } from "../../../../../components/Component";
import ResendOtpTimer from "../components/ResendOtpTimer";
import BothOtpVerify from "./BothOtpVerify";
import { useSelector } from "react-redux";
import ErrorMessage from "../../../../../components/ui/error-message/ErrorMessage";
import ErrorAlert from "../../../../../components/ui/modals/error-model/ErrorAlert";

export default function BothTwoFactorAuth() {
  const theme = useTheme();
  const isDark = theme.skin === "dark";

  //^ redux selector
  const userProfileData = useSelector((state) => state.userProfile.profileData);
  const userProfile = useSelector((state) => state.twoFactorUserProfile.userProfile);

  const countryIso = userProfile?.profile_iso || "US";

  //^ error handling states
  const [phoneInputError, setPhoneInputError] = useState(false);
  //^ disable states
  const [phoneInputIsDisabled, setPhoneInputIsDisabled] = useState(
    userProfileData?.phonecode && userProfileData?.two_fact_phone ? true : userProfileData?.phone_number ? true : false
  );
  const [emailInputIsDisabled, setEmailInputIsDisabled] = useState(true);

  //^ other states
  const [bothOtpVerificationData, setBothOtpVerificationData] = useState(undefined);
  const [showOtpTimer, setShowOtpTimer] = useState(false);

  //^ boolean states
  const [isCompleted, setIsCompleted] = useState(true);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email()
      .required()
      .matches(/^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/, "Invalid email format"),
  });
  //^ mutation function =====================================================================
  const {
    isPending: otpVerificationIsPending,
    isError: otpVerificationIsError,
    error: otpVerificationError,
    mutate: otpVerificationMutate,
    reset: otpVerificationReset,
  } = useMutation({
    mutationKey: ["both-otp-verification"],
    mutationFn: postSet2FAProfileHandler,
    onSuccess: (data) => {
      if (data.type === "error") {
        toast.error(data?.message);
      } else if (data.type === "success") {
        setPhoneInputIsDisabled(true);
        setEmailInputIsDisabled(true);

        setShowOtpTimer(true);

        setBothOtpVerificationData(data);

        toast.success(data?.message);
      } else if (data.message === "Validation failed") {
        toast.error(data?.message);
      }
      otpVerificationReset();
    },
  });

  //^ mutation function =====================================================================

  //^ formik declaration ====================================================================
  const emailId = getCookie("email", null);

  const countryCallingCode =
    countries[userProfile?.profile_iso ? userProfile?.profile_iso : "US"]?.countryCallingCodes[0];
  const phoneNumber = userProfile?.phone_number ? userProfile?.phone_number : "";
  const factPhoneCode = userProfile?.two_fact_phone ? userProfile?.two_fact_phone : "";

  const formik = useFormik({
    initialValues: {
      email: emailId,
      phoneNumber: userProfile?.two_fact_phone
        ? `${countryCallingCode}${factPhoneCode}`
        : `${countryCallingCode}${phoneNumber}`,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const phoneNumber = parsePhoneNumber(values.phoneNumber);
      const countryCode = phoneNumber.countryCallingCode;
      const number = phoneNumber.nationalNumber;

      otpVerificationMutate({
        phoneCode: countryCode,
        twoFactAuth: "3",
        twoFactEmail: values.email,
        twoFactPhone: number,
      });

      setPhoneInputIsDisabled(true);
      setEmailInputIsDisabled(true);
    },
  });
  //^ formik declaration =================================================================

  //* Change handlers =======================================================================
  const phoneInputChangeHandler = useCallback(
    (value) => {
      const stringValue = String(value);

      const isValid = isValidPhoneNumber(stringValue);
      formik.setFieldValue("phoneNumber", stringValue);

      if (!isValid) {
        setPhoneInputError(true);
      } else {
        setPhoneInputError(false);
      }
    },
    // eslint-disable-next-line
    [formik.values.phoneNumber]
  );
  //* Change handlers =======================================================================


  const buttonOtpMessage =
    bothOtpVerificationData && bothOtpVerificationData?.code === 200 && bothOtpVerificationData?.type === "success"
      ? "Resend OTP"
      : "Send OTP";

  return (
    <>
      {otpVerificationIsError && (
        <ErrorAlert
          title={`${otpVerificationError?.code || 500}`}
          description={`${otpVerificationError?.info?.message || "Something went wrong"}`}
          isConformed={() => otpVerificationReset()}
        />
      )}

      <Stack gap={"2rem"}>
        <form onSubmit={formik.handleSubmit}>
          <Stack gap={"1rem"}>
            <Stack
              className="h-100"
              justifyContent={"space-between"}
              gap={"1rem"}
              sx={{
                "@media (min-width: 65.5rem)": {
                  flexDirection: "row",
                },
              }}
            >
              <Box className={`w-100 d-flex flex-column ${inputClasses["input-model"]}`} style={{ gap: "0.225rem" }}>
                <Stack className={`${isDark ? inputClasses["dark"] : ""} ${inputClasses["input"]}`} gap={"0.325rem"}>
                  {" "}
                  <input
                    type="email"
                    name="email"
                    defaultValue={formik.values.email}
                    className={`form-control form-control-lg ${
                      formik.errors.email && formik.touched.email ? "is-invalid" : ""
                    }`}
                    style={{ fontSize: "14px" }}
                    placeholder="Enter a Email ID."
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      setEmailInputIsDisabled(true);
                      formik.handleBlur(e);
                    }}
                    disabled={emailInputIsDisabled}
                  />
                  {formik.errors.email && formik.touched.email ? <ErrorMessage errorMsg={formik.errors.email} /> : ""}
                </Stack>
              </Box>
              <Stack alignItems={"flex-end"}>
                <Button
                  variant="contained"
                  color={"primary"}
                  type="button"
                  onClick={() => setEmailInputIsDisabled(false)}
                  sx={{ height: "48px" }}
                >
                  <Icon name="edit" className={"fs-4"} />
                </Button>
              </Stack>
            </Stack>
            <Stack
              direction={"column"}
              justifyContent={"space-between"}
              gap={"1rem"}
              sx={{
                "@media (min-width: 65.5rem)": {
                  flexDirection: "row",
                },
              }}
            >
              <Box className={`w-100 d-flex flex-column ${phoneClasses["input-model"]}`} style={{ gap: "0.225rem" }}>
                <Stack className={`${isDark ? phoneClasses["dark"] : ""} ${phoneClasses["input"]}`} gap={"0.325rem"}>
                  <PhoneInput
                    value={formik.values.phoneNumber}
                    onChange={phoneInputChangeHandler}
                    onBlur={() => setPhoneInputIsDisabled(true)}
                    defaultCountry={countryIso}
                    className={`${classes["phone-input"]} ${
                      isDark ? classes["dark-mode"] : ""
                    } form-control form-control-sm ${phoneInputError ? "is-invalid" : ""} w-100 ${
                      phoneInputIsDisabled ? classes["disable-phone-input"] : ""
                    } ${phoneClasses["phone-input"]}`}
                    style={{ height: "48px", fontSize: "14px" }}
                    disabled={phoneInputIsDisabled}
                    placeholder="Enter phone number"
                    international
                  />
                  {phoneInputError ? <ErrorMessage errorMsg={"Enter valid phone number"} /> : ""}
                </Stack>
              </Box>
              <Stack alignItems={"flex-end"}>
                <Button
                  variant="contained"
                  color={"primary"}
                  type="button"
                  onClick={() => setPhoneInputIsDisabled(false)}
                  sx={{ height: "48px" }}
                >
                  <Icon name="edit" className={"fs-4"} />
                </Button>
              </Stack>
            </Stack>
            <Stack direction={"row"} gap={"1rem"} alignItems={"center"}>
              {showOtpTimer ? (
                <ResendOtpTimer
                  onOtpTimer={(isCompleted) => {
                    if (isCompleted) {
                      otpVerificationReset();
                      setShowOtpTimer(false);
                      setIsCompleted(isCompleted);
                    } else {
                      setIsCompleted(isCompleted);
                    }
                  }}
                />
              ) : (
                ""
              )}
              <Box>
                <Button
                  variant="contained"
                  color={"primary"}
                  type="submit"
                  className={"d-flex"}
                  style={{
                    gap: "0.5rem",
                    alignItems: "center",
                    cursor: phoneInputError ? "not-allowed" : "",
                    whiteSpace: "nowrap",
                  }}
                  disabled={
                    !formik.isValid || phoneInputError || otpVerificationIsPending || isCompleted === false
                      ? true
                      : false
                  }
                  startIcon={otpVerificationIsPending ? <CircularProgress size={18} color="inherit" /> : ""}
                >
                  <span>{buttonOtpMessage}</span>
                </Button>
              </Box>
            </Stack>
          </Stack>
        </form>
        {bothOtpVerificationData && bothOtpVerificationData?.type === "success" ? (
          <BothOtpVerify emailId={formik.values.email} phoneNumber={formik.values.phoneNumber} />
        ) : (
          ""
        )}
      </Stack>
    </>
  );
}
