import React, { useState, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "../../../components/Component";
// import { useSelector } from "react-redux";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";

import { useThemeUpdate } from "../../../layout/provider/Theme";

//^ utils
import { getCookie } from "../../../utils/Utils";

//^ nav items object data
import AccountAvatar from "./AccountAvatar";
import useClientScreenSize from "../../../hooks/user-screen-size";

//^ mui
import { Stack } from "@mui/material";
import ProfilePicture from "../../../components/profile-picture";
import { useSelector } from "react-redux";

function ShowPaymentMethod({
  tokenValue,
  fieldValue,
  value,
  showUsd = false,
  title = `${process.env.REACT_APP_PROJECT_NAME} Wallet Account`,
}) {
  return (
    <div className="card-inner">
      <div className="user-account-info py-0">
        <h6 className="overline-title-alt">{title}</h6>
        <div className="user-balance">
          {`${showUsd ? "$" : ""}${fieldValue === "2" ? parseFloat(value * tokenValue).toFixed(2) : value}`}
          <small className="currency currency-btc">{showUsd ? "" : " SBC"}</small>
        </div>
      </div>
    </div>
  );
}

const UserProfileAside = ({ updateSm, isUserSubscribed, fieldValue }) => {
  const showSubscription = useSelector((state) => state.siteSetting.showSubscription);

  const [imageUrl, setImageUrl] = useState("");
  const [modal, setModal] = useState(false);
  const [navLinks, setNavLinks] = useState([
    {
      to: `${process.env.PUBLIC_URL}/user-profile-regular`,
      icon: "user-fill-c",
      title: "Personal Information",
    },
    {
      to: `${process.env.PUBLIC_URL}/account-users`,
      icon: "users-fill",
      title: `${process.env.REACT_APP_PROJECT_SHORT_NAME} Networks`,
    },
    {
      to: `${process.env.PUBLIC_URL}/user-profile-setting`,
      icon: "setting-fill",
      title: "Settings",
    },
  ]);

  React.useEffect(() => {
    if (showSubscription === true) {
      const isSubscriptionsPresent = navLinks.some((link) => link.title === "Subscriptions");

      if (!isSubscriptionsPresent) {
        const newNavLinks = [...navLinks];
        newNavLinks.splice(1, 0, {
          to: `${process.env.PUBLIC_URL}/subscriptions?tab=packageSubscriptions`,
          icon: "coin-alt-fill",
          title: "Subscriptions",
        });
        setNavLinks(newNavLinks);
      }
    }
  }, [showSubscription, navLinks]);

  // eslint-disable-next-line
  const [profileName, setProfileName] = useState(getCookie("username", "John"));
  const users_account_tokens = useSelector((state) => state.payment.users_account_tokens);
  const tokenValue = useSelector((state) => state.userProfile.tokenValue);
  const themeUpdate = useThemeUpdate();

  //^ custom hook
  const { width } = useClientScreenSize();
  const laptopView = width >= 1024;

  // eslint-disable-next-line
  const [cropData, setCropData] = useState("#");

  const handleUpdateProfileImage = useCallback((imageUrl) => {
    setImageUrl(imageUrl);
  }, []);

  const handleUserProfileModalHandler = useCallback((isOpen) => {
    setModal(isOpen);
  }, []);

  return (
    <>
      <div className="card-inner-group h-100">
        <div className="card-inner">
          <div className="user-card">
            <Stack direction={"row"} gap={"1rem"}>
              <AccountAvatar showImg profileName={getCookie("username", "John")} />
              <div className="user-info">
                <span className="lead-text">{profileName}</span>
                <span className="sub-text">{getCookie("email", "john@mail.io")}</span>
              </div>
            </Stack>
            <div className="user-action">
              <UncontrolledDropdown>
                <DropdownToggle tag="a" className="btn btn-icon btn-trigger me-n2">
                  <Icon name="more-v" />
                </DropdownToggle>
                <DropdownMenu end>
                  <ul className="link-list-opt no-bdr">
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#dropdownitem"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setModal(true);
                        }}
                      >
                        <Icon name="camera-fill" />
                        <span>Change Photo</span>
                      </DropdownItem>
                    </li>
                    <li className="d-none">
                      <DropdownItem
                        tag="a"
                        href="#dropdownitem"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon name="edit-fill" />
                        <span>Update Profile</span>
                      </DropdownItem>
                    </li>
                  </ul>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        </div>
        {/* { fieldValue === "1" ? (
          <ShowPaymentMethod value={users_account_tokens.toFixed(6)} />
        ) : fieldValue === "2" ? (
          <ShowPaymentMethod
            title="Account Balance"
            showUsd={true}
            value={users_account_tokens.toFixed(2)}
            tokenValue={tokenValue}
            fieldValue={fieldValue}
          />
        ) : (
          ""
        )} */}
        {/* <div className="card-inner p-0"> */}
        <Stack height={"100%"} justifyContent={"space-between"} className="card-inner p-0">
          <ul className="link-list-menu d-flex flex-column" style={{ gap: "0.625rem" }}>
            {navLinks.map((navItem, index) => {
              if (navItem.title.toLowerCase() === "current subscription") {
                if (isUserSubscribed) {
                  return (
                    <NavLink
                      to={navItem.to}
                      className={`${({ isActive }) => (isActive ? "active" : "")} d-flex align-items-center`}
                      style={{ gap: "0.425rem" }}
                    >
                      <div className="d-flex align-items-center">
                        <Icon name={navItem.icon} />
                      </div>
                      <span className="lh-1">{navItem.title}</span>
                    </NavLink>
                  );
                } else {
                  return null;
                }
              } else {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      updateSm(false);
                      if (!laptopView) {
                        themeUpdate.sidebarVisibility();
                      }
                    }}
                  >
                    <NavLink
                      to={navItem.to}
                      className={`${({ isActive }) => (isActive ? "active" : "")} d-flex align-items-center`}
                      style={{ gap: "0.425rem" }}
                    >
                      <div className="d-flex align-items-center">
                        <Icon name={navItem.icon} />
                      </div>
                      <span className="lh-1">{navItem.title}</span>
                    </NavLink>
                  </li>
                );
              }
            })}
          </ul>
        </Stack>
      </div>
      {modal && (
        <ProfilePicture
          onModalClose={handleUserProfileModalHandler}
          isOpen={modal}
          imageUrl={imageUrl}
          updateProfileImage={handleUpdateProfileImage}
        />
      )}
    </>
  );
};

export default UserProfileAside;
