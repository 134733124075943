import React, { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { Alert } from "reactstrap";

//^ stylesheets
import classes from "./SubscriptionPackage.module.scss";

//^ utils
import { isDifferenceDays } from "../../../../utils/Utils";

//^ mui
import {
  Box,
  Stack,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  List,
  ListItem,
  useMediaQuery,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Close, ReportProblemRounded } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

//^ http request
import { getPackagesSubscriptionHandler } from "../../../../http/get-api";
import { downgradePackageHandler, getSubscriptedPackageHandler } from "../../../../http/post-api";

//^ redux slice
import {
  setAugerFee,
  setPlan,
  setPlanList,
  setPurchase,
  setAccountTokens,
  setTokenValue,
  setValidity,
  setPackageSubUpgradePlan,
} from "../../../../store/slices/package-subscription-slice";

//^ components
import Head from "../../../../layout/head/Head";
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, Icon } from "../../../../components/Component";
import ErrorAlert from "../../../../components/ui/modals/error-model/ErrorAlert";
import Separator from "../../../../components/ui/separator/Separator";
import Switch from "../../../../components/ui/switch/Switch";
import SubscriptionPackageSkeleton from "../../../../components/ui/loader/Skeleton/SubscriptionPackageSkeleton";
import StartButton from "../../../../components/button/navigate-button";
import moment from "moment";

export default function SubscriptionPackage() {
  const theme = useTheme();
  const planList = useSelector((store) => store.packageSubscription.planList);
  const validity = useSelector((store) => store.packageSubscription.validity);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [subscribedPackagePrice, setSubscribedPackagePrice] = useState(0);
  const [subscribedValidity, setSubscribedValidity] = useState("");
  const [switchClickContent, setSwitchClickContent] = useState({
    packageName: "",
    notIncludedFeatures: [],
    price: 0,
    validity: "",
    selectedPlan: {},
  });

  // eslint-disable-next-line
  const [upgradeSwitchClickContent, setUpgradeSwitchClickContent] = useState({
    packageName: "",
    notIncludedFeatures: [],
    price: 0,
    validity: "",
    selectedPlan: {},
  });
  const [selectedUpgradeOption, setSelectedUpgradeOption] = useState("option1");
  const [date, setDate] = useState(null);

  //^ boolean states
  const [isSwitchModelOpen, setIsSwitchModelOpen] = React.useState(false);
  const [isUpgradeSwitchModelOpen, setIsUpgradeSwitchModelOpen] = React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isDark = theme.palette.mode === "dark";

  const {
    data: getPackagesData,
    isLoading: getPackagesIsLoading,
    isError: getPackagesIsError,
    refetch: getPackagesRefetch,
  } = useQuery({
    queryKey: ["get-subscription-package-lists"],
    queryFn: ({ signal }) => getPackagesSubscriptionHandler({ signal }),
  });

  //^ getting current user's subscription package details query request
  const {
    data: getUserPackageData,
    isLoading: getUserPackageIsLoading,
    isRefetching: getUserPackageIsRefetching,
    isError: getUserPackageIsError,
    error: getUserPackageError,
    refetch: getUserPackageRefetch,
  } = useQuery({
    queryKey: ["get-current-user-package-details"],
    queryFn: getSubscriptedPackageHandler,
  });

  useEffect(() => {
    if (!getUserPackageIsLoading || !getUserPackageIsRefetching) {
      if (!getUserPackageData?.status) {
        if (getUserPackageData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        }
      } else {
        setSubscribedPackagePrice(getUserPackageData?.data?.package?.price);
        setSubscribedValidity(getUserPackageData?.data?.package?.package_data?.validity);
      }
    }
  }, [getUserPackageData, getUserPackageIsLoading, getUserPackageIsRefetching]);

  useEffect(() => {
    if (getUserPackageIsError) {
      toast.error(getUserPackageError.message);
    }
  }, [getUserPackageError, getUserPackageIsError]);

  useEffect(() => {
    if (!getPackagesIsLoading) {
      if (getPackagesData?.status) {
        let tempArr = [];

        getPackagesData?.data?.packages.map((pkg) => {
          const tempObj = {
            id: pkg.id,
            title: pkg.name,
            logo: `${process.env.REACT_APP_API_URL}${pkg.logo}`,
            desc: pkg.description,
            monthly_price: pkg.monthly_price,
            yearly_price: pkg.yearly_price,
            services: pkg.services,
            is_subscribed: pkg.is_subscribed,
            subscription_validity: pkg.subscription_validity,
            is_subscribed_end_date: pkg.is_subscribed_end_date,
          };
          tempArr.push(tempObj);
          return null;
        });

        dispatch(setPlanList(tempArr));
        dispatch(setAugerFee(getPackagesData?.data?.auger_fee));
        dispatch(setTokenValue(getPackagesData?.data?.token_value));
        dispatch(setAccountTokens(getPackagesData?.data?.account_tokens));
      }
    }
  }, [getPackagesData, getPackagesIsLoading, dispatch]);

  //^ downgrade package subscription mutation logic ================================================================
  const {
    isPending: downgradeIsPending,
    isError: downgradeIsError,
    error: downgradeError,
    mutate: downgradeMutate,
    reset: downgradeReset,
  } = useMutation({
    mutationKey: ["downgrade-subscription-package"],
    mutationFn: downgradePackageHandler,
    onSuccess: (data) => {
      if (data.toast) {
        if (!data.status) {
          toast.error(data?.message);
        } else {
          toast.success(data?.message);
          handleSwitchModalClose();
          navigate(
            `subscription-package-details/${data?.data?.package?.package_id}/print-invoice/${data?.data?.invoice_id}`
          );
        }
      }

      downgradeReset();
    },
  });

  useEffect(() => {
    if (downgradeIsError) {
      toast.error(downgradeError.message);
    }
  }, [downgradeIsError, downgradeError]);

  //^ downgrade package subscription mutation logic ================================================================

  const validityClick = useCallback(
    (isChecked) => {
      if (isChecked) {
        dispatch(setValidity(true));
      } else {
        dispatch(setValidity(false));
      }
    },
    [dispatch]
  );

  const selectPlan = (plan) => {
    dispatch(setPurchase(true));
    dispatch(setPlan(plan));
    navigate(`subscription-package-details/${encodeURI(plan.id)}`);
  };

  const textMotion = {
    hover: {
      x: 3,
    },
  };

  function handleSwitchModalClose() {
    setIsSwitchModelOpen(false);
  }

  function handleUpgradeSwitchModalClose() {
    setIsUpgradeSwitchModelOpen(false);
    setSelectedUpgradeOption("option1");
  }

  const handleOptionChange = (event) => {
    setSelectedUpgradeOption(event.target.value);
  };

  const handleDateChangeHandler = (date) => {
    setDate(date);
  };

  function handleSwitchPackageHandler(_event, packageName, notIncludedFeatures, price, validity, selectPlan) {
    setSwitchClickContent({
      packageName,
      notIncludedFeatures,
      price: price,
      validity,
      selectedPlan: selectPlan,
    });

    setIsSwitchModelOpen(true);
  }

  function handleUpgradeSwitchPackageHandler(_event, packageName, notIncludedFeatures, price, validity, selectPlan) {
    setUpgradeSwitchClickContent({
      packageName,
      notIncludedFeatures,
      price: price,
      validity,
      selectedPlan: selectPlan,
    });

    setIsUpgradeSwitchModelOpen(true);
  }

  return (
    <>
      {getUserPackageIsError && (
        <ErrorAlert
          title={"Something went wrong"}
          description={`Unable to get the current user's subscription package.`}
          isConformed={() => getUserPackageRefetch()}
        />
      )}
      {getPackagesIsError && (
        <ErrorAlert
          title={"Something went wrong"}
          description={`Unable to get the subscription package details.`}
          isConformed={() => getPackagesRefetch()}
        />
      )}
      <Head title="Subscriptions" />
      <BlockHead>
        <BlockBetween>
          <BlockHeadContent className={"w-100"}>
            <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"}>
              {/* <Title title={"Package Subscription"} divider={false} /> */}
              <Box>
                <Switch checkedLabel={"Monthly"} unCheckedLabel={"Yearly"} onClick={validityClick} checked={validity} />
              </Box>
            </Stack>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <>
        <Dialog
          open={isSwitchModelOpen}
          aria-labelledby="switch-dialog-title"
          onClose={handleSwitchModalClose}
          fullScreen={fullScreen}
          PaperProps={{
            elevation: 1,
            component: "form",
            sx: {
              width: "100%",
              maxWidth: "37.5rem",
            },
            onSubmit: (event) => {
              event.preventDefault();
              downgradeMutate({
                id: switchClickContent.selectedPlan?.id,
                validity: switchClickContent.validity ? "12" : "1",
              });
            },
          }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="switch-dialog-title">
            {"Are you sure you want to switch subscription?"}
            <IconButton
              aria-label="close"
              onClick={handleSwitchModalClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Typography id="switch-subscription-description" variant="subtitle1">
              {`Switching will change your subscription from ${getUserPackageData?.data?.package?.package_data?.name}`}
              <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                {` ($${subscribedPackagePrice}/${
                  subscribedValidity === "1" ? "yr" : subscribedValidity === "12" ? "mo" : ""
                }) `}
              </Typography>
              {`to ${switchClickContent?.packageName}`}
              <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                {` ($${switchClickContent?.price}/${switchClickContent?.validity ? "yr" : "mo"})`}
              </Typography>
              {`. Some features available in ${getUserPackageData?.data?.package?.package_data?.name}`}
              <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                {` (${subscribedValidity === "1" ? "yearly" : subscribedValidity === "12" ? "monthly" : ""}) `}
              </Typography>
              {`will not be included in the ${switchClickContent?.packageName}`}
              <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                {` (${switchClickContent?.validity ? "yearly" : "monthly"}) `}
              </Typography>
              {`package. These features are:`}
            </Typography>

            <List>
              {switchClickContent.notIncludedFeatures.map((feature, index) => {
                return (
                  <ListItem key={index}>
                    <Typography variant="subtitle2">- {feature?.name}</Typography>
                  </ListItem>
                );
              })}
            </List>
            <Typography variant="subtitle1">
              If you are sure about this change, please confirm by clicking the{" "}
              <Typography variant="button" component={"span"}>
                "Confirm"
              </Typography>{" "}
              button below.
            </Typography>
            <Alert color="warning" style={{ marginTop: "1rem", padding: "1rem" }}>
              <Stack direction={"row"} gap={2}>
                <ReportProblemRounded color="warning" />
                <Typography variant="body2">
                  When switching from {getUserPackageData?.data?.package?.package_data?.name}{" "}
                  <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                    {` ($${subscribedPackagePrice}/${
                      subscribedValidity === "1" ? "yr" : subscribedValidity === "12" ? "mo" : ""
                    }) `}
                  </Typography>{" "}
                  to {switchClickContent?.packageName}{" "}
                  <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                    {` ($${switchClickContent?.price}/${switchClickContent?.validity ? "yr" : "mo"}) `}
                  </Typography>
                  , the payment will remain the same as for {getUserPackageData?.data?.package?.package_data?.name},
                  with only the services changing to those of the {switchClickContent?.packageName} package. While the
                  plan will transition, it's important to note that even though{" "}
                  {getUserPackageData?.data?.package?.package_data?.name} costs{" "}
                  <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                    ${subscribedPackagePrice}
                  </Typography>{" "}
                  and {switchClickContent?.packageName} costs{" "}
                  <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                    ${switchClickContent?.price}
                  </Typography>
                  , the remaining{" "}
                  <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                    ${(subscribedPackagePrice - switchClickContent?.price).toFixed(2)}
                  </Typography>{" "}
                  will not be refunded.
                </Typography>
              </Stack>
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button
              startIcon={downgradeIsPending ? <CircularProgress size={17} color="inherit" /> : ""}
              color="primary"
              type="submit"
            >
              Confirm
            </Button>
            <Button color="secondary" onClick={handleSwitchModalClose} sx={{ color: isDark ? "white" : "black" }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isUpgradeSwitchModelOpen}
          aria-labelledby="switch-dialog-title"
          onClose={handleUpgradeSwitchModalClose}
          fullScreen={fullScreen}
          PaperProps={{
            elevation: 1,
            component: "form",
            sx: {
              width: "100%",
              maxWidth: "37.5rem",
            },
            onSubmit: (e) => {
              e.preventDefault();
              const currentDate = moment().format("YYYY-MM-DD");

              selectPlan(upgradeSwitchClickContent.selectedPlan);
              setSelectedUpgradeOption("option1");
              handleUpgradeSwitchModalClose();
              dispatch(
                setPackageSubUpgradePlan(
                  selectedUpgradeOption === "option1"
                    ? currentDate
                    : selectedUpgradeOption === "option2"
                    ? getUserPackageData?.data?.package?.end_date
                    : dayjs(date).format("YYYY-MM-DD")
                )
              );
            },
          }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="switch-dialog-title">
            {"Are you sure you want to upgrade subscription?"}
            <IconButton
              aria-label="close"
              onClick={handleUpgradeSwitchModalClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <RadioGroup value={selectedUpgradeOption} onChange={handleOptionChange}>
              <Stack gap={2}>
                <FormControlLabel
                  value="option1"
                  control={<Radio />}
                  label="Do you want to upgrade to Silver from Gold starting today?"
                  required
                />
                <FormControlLabel
                  value="option2"
                  control={<Radio />}
                  label="Upgrade the subscription when the current subscription expires."
                  required
                />
                <FormControlLabel
                  value="option3"
                  control={<Radio />}
                  label="From which date do you want to upgrade the plan?"
                  required
                />
              </Stack>
            </RadioGroup>
            {selectedUpgradeOption === "option3" && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  variant="standard"
                  value={date}
                  id={"dob"}
                  onChange={handleDateChangeHandler}
                  sx={{ width: "100%", mt: 2 }}
                  format="DD/MM/YYYY"
                  minDate={dayjs()}
                />
              </LocalizationProvider>
            )}
          </DialogContent>
          <DialogActions>
            <Button color="primary" type="submit">
              Confirm
            </Button>
            <Button
              color="secondary"
              onClick={handleUpgradeSwitchModalClose}
              sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <div className={`${classes["subscription-packages-list"]}`}>
          {getPackagesIsLoading || getUserPackageIsLoading ? (
            <div className="d-flex w-100" style={{ height: "100%", gridColumn: "span 3" }}>
              <Box width={"100%"}>
                <SubscriptionPackageSkeleton />
              </Box>
            </div>
          ) : (
            planList?.map((plan) => {
              const planPrice = !validity ? plan.monthly_price : plan.yearly_price;
              const validityString = validity ? "12" : "1";
              const subscriptionValidity = validityString === plan?.subscription_validity;
              const isFourDayDifference = isDifferenceDays(plan.is_subscribed_end_date, 4);
              const packageName = plan?.title || "";
              const notIncludedFeatures = plan?.services?.filter((service) => !service.include) || [];
              const selectedPlan = plan;

              return (
                <>
                  <div
                    className={`${
                      getUserPackageData?.status
                        ? plan.is_subscribed &&
                          plan.subscription_validity === validityString &&
                          plan.id === getUserPackageData.data.package.package_id &&
                          "bg-primary d-flex flex-column"
                        : ""
                    }`}
                    style={
                      getUserPackageData?.status
                        ? plan.is_subscribed &&
                          plan.subscription_validity === validityString &&
                          plan.id === getUserPackageData.data.package.package_id
                          ? {
                              borderRadius: "0.625rem",
                              paddingRight: "0.225rem",
                              paddingLeft: "0.225rem",
                              paddingBottom: "0.225rem",
                            }
                          : {}
                        : {}
                    }
                  >
                    {getUserPackageData?.status ? (
                      plan.is_subscribed &&
                      plan.subscription_validity === validityString &&
                      plan.id === getUserPackageData.data.package.package_id ? (
                        <Typography
                          textAlign={"center"}
                          variant="overline"
                          fontWeight={"600"}
                          color={"white"}
                          fontSize={15}
                          padding={"0.725rem 0rem"}
                        >
                          Current Subscription
                        </Typography>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    <Paper
                      elevation={isDark ? 2 : 3}
                      className={`pricing ${plan.tags ? "recommend" : ""} pb-2 position-relative`}
                      sx={{ borderRadius: "0.625rem" }}
                    >
                      <div className={`p-2 ${classes["pricing-details"]}`}>
                        <div className="d-flex flex-column" style={{ gap: "1.25rem" }}>
                          <div className={`${classes["subscription-title"]}`}>
                            <div>
                              <h5 className="title">{plan.title}</h5>
                              <span className="sub-text">{plan.desc}</span>
                            </div>
                            <div
                              className={`${
                                getUserPackageData?.status
                                  ? plan.id === getUserPackageData.data.package.package_id
                                    ? "pt-2"
                                    : ""
                                  : ""
                              }`}
                              style={{ width: "7rem", height: "7rem" }}
                            >
                              <img src={plan.logo} alt={plan.title} />
                            </div>
                          </div>
                          <div className="pricing-amount px-2">
                            <div className="amount">
                              ${!validity ? plan.monthly_price : plan.yearly_price}{" "}
                              <span style={{ fontSize: "12px" }}>{`/ ${!validity ? "mo" : "yr"}`}</span>
                            </div>
                            <Separator />

                            <div>
                              <p>Includes</p>
                              <p className="d-flex flex-column fs-5" style={{ gap: "1rem" }}>
                                {plan && plan.services && plan?.services?.length > 0
                                  ? plan.services.map((service, index) => {
                                      return (
                                        <span
                                          key={index}
                                          className="d-flex align-items-center"
                                          style={{ gap: "0.625rem" }}
                                        >
                                          {service.include ? (
                                            <Icon name="check-thick" className={"text-success"} />
                                          ) : (
                                            <Icon name="cross" className={"text-danger fw-bold"} />
                                          )}

                                          <BlockDes>{service.name}</BlockDes>
                                        </span>
                                      );
                                    })
                                  : null}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="pricing-action d-flex justify-content-center">
                          {!subscriptionValidity && getUserPackageData?.status && subscribedPackagePrice > planPrice ? (
                            <StartButton
                              // startIcon={downgradeIsPending ? <CircularProgress size={18} color="inherit" /> : ""}
                              title={"Switch Subscription"}
                              variant={"contained"}
                              color={"primary"}
                              type={"button"}
                              onClick={(event) =>
                                handleSwitchPackageHandler(
                                  event,
                                  packageName,
                                  notIncludedFeatures,
                                  planPrice,
                                  validity,
                                  selectedPlan
                                )
                              }
                            />
                          ) : subscribedPackagePrice < planPrice ? (
                            <StartButton
                              // startIcon={downgradeIsPending ? <CircularProgress size={18} color="inherit" /> : ""}
                              title={"Upgrade Subscription"}
                              variant={"contained"}
                              color={"primary"}
                              type={"button"}
                              onClick={(event) =>
                                handleUpgradeSwitchPackageHandler(
                                  event,
                                  packageName,
                                  notIncludedFeatures,
                                  planPrice,
                                  validity,
                                  selectedPlan
                                )
                              }
                            />
                          ) : (
                            <motion.div whileHover={"hover"}>
                              <Button
                                disabled={
                                  !subscriptionValidity
                                    ? getUserPackageData?.status
                                      ? plan.id === getUserPackageData.data.package.package_id && subscriptionValidity
                                        ? false
                                        : getUserPackageData?.status
                                        ? subscribedPackagePrice > planPrice
                                          ? false
                                          : false
                                        : false
                                      : false
                                    : isFourDayDifference
                                    ? false
                                    : true
                                }
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={() => {
                                  selectPlan(plan);
                                  dispatch(setPackageSubUpgradePlan(null));
                                }}
                              >
                                <span className={`d-flex align-items-center`} style={{ gap: "0.625rem" }}>
                                  <Typography variant="subtitle2" component={"span"}>
                                    {!subscriptionValidity
                                      ? getUserPackageData?.status
                                        ? plan.id === getUserPackageData.data.package.package_id && subscriptionValidity
                                          ? "Renew"
                                          : getUserPackageData?.status
                                          ? subscribedPackagePrice > planPrice
                                            ? "Buy Subscription"
                                            : "Upgrade Subscription"
                                          : "Buy Now"
                                        : "Buy Now"
                                      : "Renew Now"}
                                  </Typography>
                                  <motion.span
                                    variants={textMotion}
                                    className="d-flex justify-content-center align-items-center"
                                  >
                                    <Icon name="arrow-right" />
                                  </motion.span>
                                </span>
                              </Button>
                            </motion.div>
                          )}
                        </div>
                      </div>
                    </Paper>
                  </div>
                </>
              );
            })
          )}
        </div>
      </>
    </>
  );
}
