//^ mui components
import { Stack } from "@mui/material";

import Head from "../../layout/head/Head";

//^ table
import FollowersTable from "../../tables/account-users/followers/table";

export default function FollowersAndFollowing() {
  return (
    <>
      <Head title={`${process.env.REACT_APP_PROJECT_SHORT_NAME} Followers`} />
      <Stack>
        <FollowersTable />
      </Stack>
    </>
  );
}
