import { useNavigate, useParams } from "react-router";
import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
// import { toast } from "react-toastify";

//^ mui
import { Box, Card, Divider, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import styleVar from "../../../assets/scss/_themes-vars.module.scss";

//^ http request
import { getServicePlan } from "../../../http/post-api";

//^ component
import Title from "../../../components/title/Title";
import ErrorAlert from "../../../components/ui/modals/error-model/ErrorAlert";
import PackageSubscriptionDetailSkeleton from "../../../components/ui/loader/Skeleton/packageSubscriptionDetailSkeleton";
import { getCookie } from "../../../utils/Utils";
import { GreetingMessage } from "../../pre-built/package-subscription/current-subscription/CurrentSubscription";
import { ServiceList } from "../service-plans";
import BackButton from "../../../components/button/back-button";
import Switch from "../../../components/ui/switch/Switch";
import StartButton from "../../../components/button/navigate-button";
import Head from "../../../layout/head/Head";
import ServicePayment from "./service-payment";
// import PromoCode from "../../../components/promo-code/PromoCode";

export default function ServicePlansDetails() {
  const { planId, serviceId } = useParams();
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const [features, setFeatures] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [onlyPrice, setOnlyPrice] = useState(0);
  const [onlyTotalPrice, setOnlyTotalPrice] = useState(0);
  const [onlyAugerFee, setOnlyAugerFee] = useState(0);
  const [augerFee, setAugerFee] = useState(0);
  const [showSBCToken, setShowSBCToken] = useState(false);
  const [plan, setPlan] = useState({});
  const [showPayment, setShowPayment] = useState(false);
  // const [promoCodes, setPromoCodes] = useState([]);
  // const [promoCodePrice, setPromoCodePrice] = useState({
  //   value: 0,
  //   type: "",
  //   count: 0,
  // });
  // const [isPromoCodeApplied, setIsPromoCodeApplied] = useState(false);

  const servicePackageValidity = useSelector((state) => state.servicePlan.servicePlanValidity);
  const paymentMethod = useSelector((state) => state.siteSetting.paymentMethod);

  const navigate = useNavigate();

  function handleClickShowSBCToken() {
    setShowSBCToken((prevSBCToken) => !prevSBCToken);
  }

  const {
    data: servicePlanData,
    isLoading: servicePlanIsLoading,
    isRefetching: servicePlanIsRefetching,
    isError: servicePlanIsError,
    error: servicePlanError,
    refetch: servicePlanRefetch,
  } = useQuery({
    queryKey: ["get-service-plan"],
    queryFn: async () => getServicePlan({ planId: parseInt(planId), serviceId: parseInt(serviceId) }),
    gcTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!servicePlanIsLoading || !servicePlanIsRefetching) {
      if (!servicePlanData?.status) {
        if (servicePlanData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        }
      } else {
        const featureObj = JSON.parse(servicePlanData?.data?.plan?.features);
        const features = Object.keys(featureObj).map((title) => ({
          title,
          ...featureObj[title],
        }));
        let planPrice = servicePackageValidity
          ? servicePlanData?.data?.plan?.yearly_price?.price
          : servicePlanData?.data?.plan?.monthly_price?.price;
        let augerFee = servicePackageValidity
          ? servicePlanData?.data?.plan?.yearly_price?.auger_price
          : servicePlanData?.data?.plan?.monthly_price?.auger_price;

        setFeatures(features);
        setPlan(servicePlanData?.data?.plan);

        const price = showSBCToken ? (planPrice / servicePlanData?.data?.token_value)?.toFixed(6) : planPrice;
        const augerPrice = showSBCToken
          ? ((planPrice * augerFee) / 100 / servicePlanData?.data?.token_value)?.toFixed(6)
          : augerFee;

        setPrice(price);
        setOnlyPrice(planPrice);
        setOnlyTotalPrice(planPrice + augerFee);
        setOnlyAugerFee(augerFee);
        setAugerFee(parseFloat(parseFloat(augerPrice).toFixed(2)));
        setTotalPrice(
          showSBCToken
            ? (parseFloat(price) + parseFloat(augerPrice)).toFixed(6)
            : (parseFloat(price) + parseFloat(augerPrice)).toFixed(2)
        );

        // if (promoCodePrice.count === 1) {
        //   if (promoCodePrice.type === "1") {
        //     planPrice = planPrice - (planPrice * promoCodePrice.value) / 100;
        //   } else if (promoCodePrice.type === "0") {
        //     planPrice = planPrice - promoCodePrice.value;
        //   } else {
        //     toast.error("Promo Code type is invalid.");
        //   }

        //   const price = showSBCToken ? (planPrice / servicePlanData?.data?.token_value)?.toFixed(6) : planPrice;
        //   const augerPrice = showSBCToken
        //     ? ((planPrice * augerFee) / 100 / servicePlanData?.data?.token_value)?.toFixed(6)
        //     : augerFee;

        //   setPrice(price);
        //   setOnlyPrice(planPrice);
        //   setOnlyTotalPrice(planPrice + augerFee);
        //   setOnlyAugerFee(augerFee);
        //   setAugerFee(parseFloat(parseFloat(augerPrice).toFixed(2)));
        //   setTotalPrice(
        //     showSBCToken
        //       ? (parseFloat(price) + parseFloat(augerPrice)).toFixed(6)
        //       : (parseFloat(price) + parseFloat(augerPrice)).toFixed(2)
        //   );
        // } else {
        // const price = showSBCToken ? (planPrice / servicePlanData?.data?.token_value)?.toFixed(6) : planPrice;
        // const augerPrice = showSBCToken
        //   ? ((planPrice * augerFee) / 100 / servicePlanData?.data?.token_value)?.toFixed(6)
        //   : augerFee;

        // setPrice(price);
        // setOnlyPrice(planPrice);
        // setOnlyTotalPrice(planPrice + augerFee);
        // setOnlyAugerFee(augerFee);
        // setAugerFee(parseFloat(parseFloat(augerPrice).toFixed(2)));
        // setTotalPrice(
        //   showSBCToken
        //     ? (parseFloat(price) + parseFloat(augerPrice)).toFixed(6)
        //     : (parseFloat(price) + parseFloat(augerPrice)).toFixed(2)
        // );
        // }
      }
    }
  }, [
    servicePlanData,
    servicePlanIsLoading,
    servicePlanIsRefetching,
    servicePackageValidity,
    showSBCToken,
    // promoCodePrice,
  ]);

  useEffect(() => {
    if (servicePlanIsError) {
      // console.log(servicePlanError?.info);
    }
  }, [servicePlanError, servicePlanIsError]);

  const handlePaymentModelClose = useCallback((isModelOpen) => {
    setShowPayment(isModelOpen);
  }, []);

  // const handlePromoCodeData = React.useCallback(
  //   (data) => {
  //     if (data.status) {
  //       const promocode = data?.data?.promocode?.promo_code;

  //       const isPromoName = promoCodes.some((code) => code?.name === promocode);

  //       if (isPromoName) {
  //         const isPromoCountOne = promoCodes.some((code) => code?.name === promocode && code?.count === 1);

  //         if (isPromoCountOne) {
  //           const promoCodeData = promoCodes.find((code) => code?.name === promocode && code?.count === 1);
  //           if (promoCodeData?.type === "1") {
  //             //^ percentage operation
  //             setPromoCodePrice({
  //               count: 1,
  //               type: promoCodeData?.type,
  //               value: promoCodeData?.value,
  //             });
  //           } else if (promoCodeData?.type === "0") {
  //             //^ flat discount operation
  //             setPromoCodePrice({
  //               count: 1,
  //               type: promoCodeData?.type,
  //               value: promoCodeData?.value,
  //             });
  //           } else {
  //             toast.error("Promo Code type is not supported.");
  //           }
  //         }
  //       } else {
  //         const promoCodeData = data?.data?.promocode;

  //         // console.log(promoCodeData)
  //         if (promoCodeData?.type === "1") {
  //           //^ percentage operation
  //           setPromoCodePrice({
  //             count: 1,
  //             type: promoCodeData?.type,
  //             value: promoCodeData?.value,
  //           });
  //         } else if (promoCodeData?.type === "0") {
  //           //^ flat discount operation
  //           setPromoCodePrice({
  //             count: 1,
  //             type: promoCodeData?.type,
  //             value: promoCodeData?.value,
  //           });
  //         } else {
  //           toast.error("Promo Code type is not supported.");
  //         }

  //         setIsPromoCodeApplied(true);
  //         setPromoCodes((prevPromoCode) => {
  //           return [
  //             ...prevPromoCode,
  //             {
  //               name: promocode,
  //               status: data?.data?.promocode?.status,
  //               type: data?.data?.promocode?.type,
  //               value: data?.data?.promocode?.value,
  //               count: 1,
  //             },
  //           ];
  //         });
  //       }
  //     }
  //   },
  //   // eslint-disable-next-line
  //   [promoCodes, price]
  // );

  return (
    <>
      <Head title={"Service Subscription"} />

      {servicePlanIsError && (
        <ErrorAlert
          title={`${servicePlanError?.code || 500}`}
          description={`${servicePlanError?.info?.message || "Something went wrong"}`}
          isConformed={() => servicePlanRefetch()}
        />
      )}

      {servicePlanIsLoading || servicePlanIsRefetching ? (
        <PackageSubscriptionDetailSkeleton />
      ) : (
        <>
          {showPayment && (
            <ServicePayment
              payment={parseFloat(onlyPrice) || 0.0}
              accountToken={servicePlanData?.data?.account_tokens || 0.0}
              isPaymentOpen={showPayment}
              onPaymentModelClose={handlePaymentModelClose}
              totalPrice={parseFloat(onlyTotalPrice)}
              tokenValue={servicePlanData?.data?.token_value || 0.0}
              augerPrice={parseFloat(onlyAugerFee)}
              serviceName={servicePlanData?.data?.plan?.name}
            />
          )}
          <Stack gap={"4.25rem"}>
            <Stack gap={"1.25rem"}>
              <Title
                title={`${plan.service_name} Subscription`}
                endComponent={
                  <Box>
                    <BackButton variant={"text"} title={"Plans"} onClick={() => navigate(-1)} />
                  </Box>
                }
              />
              <GreetingMessage username={getCookie("username", undefined)} />
            </Stack>
            <Stack
              justifyContent={"space-between"}
              gap={"1.25rem"}
              paddingBottom={"3rem"}
              sx={{
                "@media (min-width: 65.5rem)": {
                  flexDirection: "row",
                },
              }}
            >
              <Box width={"100%"}>
                <Stack gap={"1.25rem"}>
                  <Typography
                    variant="h5"
                    fontWeight={"600"}
                  >{`${servicePlanData?.data?.plan?.name} Plan Includes`}</Typography>
                  <Stack gap={"0.325rem"}>
                    {features.length > 0
                      ? features.map((service, index) => {
                          return (
                            <React.Fragment key={index}>
                              <ServiceList keyName={service.title} value={service.text} />
                            </React.Fragment>
                          );
                        })
                      : null}
                  </Stack>
                </Stack>
              </Box>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ borderColor: styleVar.primaryMain, borderWidth: "1.5px", borderRadius: "100%" }}
              />

              <Stack gap={"1.25rem"} width={"100%"}>
                {/* {!isPromoCodeApplied && <PromoCode onPromoCode={handlePromoCodeData} />} */}
                <Box width={"100%"}>
                  <Card elevation={isDark ? 1 : 3} sx={{ borderRadius: "0.625rem", padding: "1.25rem" }}>
                    <Stack gap={"1.25rem"}>
                      <Typography textAlign={"center"} variant="h6" fontWeight={600}>
                        {"Proceed to Payment"}
                      </Typography>
                      <Stack justifyContent={"flex-end"} direction={"row"}>
                        <Switch
                          size={"sm"}
                          checkedLabel={"USD"}
                          unCheckedLabel={"SBC"}
                          checked={showSBCToken}
                          onClick={handleClickShowSBCToken}
                        />
                      </Stack>
                      <Stack padding={"0rem 1.25rem"} gap={"1.25rem"}>
                        <Stack justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
                          <Typography variant="subtitle1">{"Price"}</Typography>
                          <Typography variant="subtitle1" fontWeight={600}>
                            {`${showSBCToken ? "SBC " : "$"}${price}`}
                          </Typography>
                        </Stack>
                        {paymentMethod === "1" ? (
                          <Stack justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
                            <Typography variant="subtitle1">{"Auger Fee"}</Typography>
                            <Typography variant="subtitle1" fontWeight={600}>
                              {`${showSBCToken ? "SBC " : "$"}${augerFee}`}
                            </Typography>
                          </Stack>
                        ) : (
                          ""
                        )}
                        <Stack justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
                          <Typography variant="subtitle1">{"Subscription Validity"}</Typography>
                          <Typography variant="subtitle1" fontWeight={600}>
                            {`${servicePackageValidity ? "1 Year" : "1 Month"}`}
                          </Typography>
                        </Stack>
                        <Divider sx={{ marginTop: "1rem" }} />
                        <Stack justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
                          <Typography variant="subtitle1" fontWeight={600}>
                            {"Total Price"}
                          </Typography>
                          <Typography variant="subtitle1" fontWeight={600}>
                            {`${showSBCToken ? "SBC " : "$"}${paymentMethod === "1" ? totalPrice : price}`}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack alignItems={"center"} justifyContent={"center"} direction={"row"}>
                        <StartButton
                          title={`${
                            servicePlanData?.data?.is_subscribed
                              ? servicePlanData?.data?.subscribed_price >= onlyPrice
                                ? "Renew Now"
                                : "Upgrade Now"
                              : "Buy Now"
                          }`}
                          onClick={() => setShowPayment(true)}
                        />
                      </Stack>
                    </Stack>
                  </Card>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
}
