import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
// import { useQuery } from "@tanstack/react-query";

//^ http requests
// import { getSiteSettingsHandler } from "../http/post-api";

import { useTheme } from "../layout/provider/Theme";

import Head from "./head/Head";
import { useDispatch } from "react-redux";

import { muiAction } from "../store/slices/mui-slice";
// import ErrorAlert from "../components/ui/modals/error-model/ErrorAlert";

const Layout = ({ title, ...props }) => {
  const muiTheme = useSelector((state) => state.mui.theme);
  const dispatch = useDispatch();

  const theme = useTheme();

  // const {
  //   data: getSiteSettingsData,
  //   isLoading: getSiteSettingsIsLoading,
  //   isRefetching: getSiteSettingsIsRefetching,
  //   isError: getSiteSettingsIsError,
  //   error: getSiteSettingsError,
  //   refetch: getSiteSettingsRefetch,
  // } = useQuery({
  //   queryKey: ["get-site-settings"],
  //   queryFn: getSiteSettingsHandler,
  //   gcTime: 0,
  //   staleTime: Infinity,
  // });

  // useEffect(() => {
  //   if (!getSiteSettingsIsLoading || !getSiteSettingsIsRefetching) {
  //     if (!getSiteSettingsData?.status) {
  //       if (getSiteSettingsData?.redirect) {
  //         window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
  //       }
  //     } else {
  //       function getFaviconEl() {
  //         return document.getElementById("favicon");
  //       }
  //       const favicon = getFaviconEl();
  //       const faviconUrl = getSiteSettingsData?.data?.site_settings?.find(
  //         (site_setting) => (site_setting.field_key = "favicon")
  //       );

  //       favicon.href = `${process.env.REACT_APP_API_URL}${faviconUrl.field_value}`;
  //     }
  //   }
  // }, [getSiteSettingsData, getSiteSettingsIsLoading, getSiteSettingsIsRefetching]);

  // console.log(getSiteSettingsData);

  // useEffect(() => {
  //   if (getSiteSettingsIsError) {
  //     console.log(getSiteSettingsError);
  //   }
  // }, [getSiteSettingsIsError, getSiteSettingsError]);

  useEffect(() => {
    if (theme.skin !== muiTheme) {
      dispatch(muiAction.setTheme(theme.skin));
      document.documentElement.style.setProperty("--bar-color", theme.skin === "dark" ? "#5a5a5a" : "#ccc");
      document.documentElement.style.setProperty("--track-color", theme.skin === "dark" ? "#333" : "#f0f0f0");
    }
  }, [theme.skin, dispatch, muiTheme]);
  

  return (
    <>
      {/* {getSiteSettingsIsError && (
        <ErrorAlert
          title={`${getSiteSettingsError?.code || 500}`}
          description={`${getSiteSettingsError?.info?.data?.message || "Something went wrong"}`}
          isConformed={() => getSiteSettingsRefetch()}
        />
      )} */}
      <Head title={!title && "Loading"} />
      <div className="nk-app-root">
        <div className="nk-wrap-nosidebar">
          <div className="nk-content">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
export default Layout;
