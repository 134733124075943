import { Link as MuiLink } from "react-router-dom";

import { Typography, Stack, Box, Button, Link } from "@mui/material";

import styleVar from "../../assets/scss/_themes-vars.module.scss";
import { ImportExportRounded } from "@mui/icons-material";

export const columns = [
  {
    accessorKey: "srNo",
    header: "Sr No",
  },
  {
    accessorKey: "invoiceId",
    header: "Invoice ID",

    accessorFn: (row) => {
      return (
        <Typography variant="subtitle2" fontWeight={400}>
          {row.invoiceId}
        </Typography>
      );
    },
  },
  {
    accessorKey: "tsxId",
    header: "Transaction ID",
  },
  {
    accessorKey: "plan",
    header: "Plan",
    accessorFn: (row) => {
      return (
        <Link
          component={MuiLink}
          to={
            row.type === "package"
              ? `subscription-package-details/${row.planId}/print-invoice/${row.invoiceId}`
              : `service-plan/invoice/${row.serviceId}/${row.planId}/print-invoice/${row.invoiceId}`
          }
          variant="body2"
        >
          {row.plan}
        </Link>
      );
    },
  },
  {
    accessorKey: "plan-hidden",
    header: "Plan",
    accessorFn: (row) => row.plan,
  },
  {
    accessorKey: "type",
    header: "Type",
    accessorFn: (row) => {
      return (
        <Typography variant="subtitle2" fontWeight={400} textTransform={"capitalize"}>
          {row.type}
        </Typography>
      );
    },
  },

  {
    accessorKey: "total",
    header: "Total",
    accessorFn: (row) => {
      return (
        <Stack gap={"0.625rem"}>
          <Stack direction={"row"} gap={"0.625rem"} alignItems={"center"}>
            <Typography variant="subtitle2" sx={{ fontWeight: "400" }}>
              Total:
            </Typography>
            <Typography variant="subtitle2">{row.total ? `$${row.total}` : "---"}</Typography>
          </Stack>
        </Stack>
      );
    },
  },
  {
    accessorKey: "invoiceDate",
    header: ({ column }) => {
      return (
        <Button
          endIcon={<ImportExportRounded />}
          variant="text"
          color="inherit"
          className="px-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Invoice Date
        </Button>
      );
    },
  },
  {
    accessorKey: "status",
    header: "Status",
    accessorFn: (row) => {
      return (
        <Stack direction={"row"} alignItems={"center"} gap={"0.625rem"}>
          <Box
            borderRadius={"100%"}
            sx={{
              width: "0.5rem",
              height: "0.5rem",
              backgroundColor: row?.status === "Paid" ? styleVar.successMain : styleVar.errorMain,
            }}
          />
          <Typography textTransform={"capitalize"} variant="subtitle2">
            {row.status}
          </Typography>
        </Stack>
      );
    },
  },
  {
    accessorKey: "status-disabled",
    header: "Status",
    accessorFn: (row) => {
      return row.status;
    },
  },
];
