import React from "react";
import { useSearchParams } from "react-router-dom";

import { MoreVert } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";

function RequestUserList(props) {
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const requestTab = searchParams.get("requestTab");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = requestTab === "receive" ? ["Ignore", "Accept"] : requestTab === "sent" ? ["Withdraw"] : [];

  return (
    <List
      sx={{
        width: "100%",
      }}
    >
      <ListItem
        alignItems="center"
        secondaryAction={
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            {requestTab === "receive" && (
              <Button
                size="small"
                sx={{
                  display: "none",
                  "@media (min-width: 65.5rem)": {
                    display: "block",
                  },
                }}
              >
                Ignore
              </Button>
            )}
            <Button
              variant="contained"
              sx={{
                borderRadius: "10rem",
                width: "7rem",
                display: "none",
                "@media (min-width: 65.5rem)": {
                  display: "block",
                },
              }}
              size="small"
            >
              {requestTab === "receive" ? "accept" : requestTab === "sent" ? "Withdraw" : ""}
            </Button>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              sx={{
                display: "block",
                "@media (min-width: 65.5rem)": {
                  display: "none",
                },
              }}
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                  width: "20ch",
                },
              }}
            >
              {options.map((option) => (
                <MenuItem key={option} onClick={handleClose}>
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </Stack>
        }
      >
        <ListItemAvatar>
          <Avatar alt={props.row?.username} src={props.row?.imgUrl} />
        </ListItemAvatar>
        <ListItemText
          primary={<Typography textTransform={"capitalize"}>{props.row?.username}</Typography>}
          secondary={<React.Fragment>{`${props.row?.email}`}</React.Fragment>}
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </List>
  );
}

export const columns = [
  {
    accessorKey: "userList",
    header: "User List",
    accessorFn: (row) => {
      return <RequestUserList row={row} />;
    },
  },
  { accessorKey: "username" },
  { accessorKey: "created_at" },
  { accessorKey: "email" },
];
