import React from "react";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";

//^ mui components
import { Box, FormControl, InputLabel, MenuItem, Paper, Select, Stack, Tab, Tabs, Typography } from "@mui/material";
import Search from "../../../components/search";
import { TabContext, TabPanel } from "@mui/lab";
import { useTheme } from "@mui/material/styles";

//^ skeleton
import RequestListSkeleton from "../../../components/ui/loader/Skeleton/follow-list-skeleton";

function RequestList({ table, columns }) {
  return (
    <>
      {table.getRowModel()?.rows?.length ? (
        table.getRowModel()?.rows?.map((row) =>
          row?.getVisibleCells()?.map((cell) => {
            return (
              <Box
                key={cell.id}
                sx={{
                  display:
                    cell.getContext().column.id === "username" ||
                    cell.getContext().column.id === "lastName" ||
                    cell.getContext().column.id === "created_at" ||
                    cell.getContext().column.id === "email"
                      ? "none"
                      : "",
                }}
              >
                <Typography variant="subtitle2" fontWeight={400}>
                  {cell.getValue()}
                </Typography>
              </Box>
            );
          })
        )
      ) : (
        <Box colSpan={columns.length} style={{ height: "6rem" }}>
          <Stack alignItems={"center"} justifyContent={"center"} height={"100%"}>
            <Typography textTransform={"capitalize"} fontWeight={"bold"}>
              No results.
            </Typography>
          </Stack>
        </Box>
      )}
    </>
  );
}

export default function DataTable({ columns, data, isLoading }) {
  const [connectionFilter, setConnectionFilter] = React.useState("none");
  const [sorting, setSorting] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const requestTab = searchParams.get("requestTab") || "receive";

  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const addFollowTypeParam = ({ value, type = "append" }) => {
    if (type === "append") {
      const params = new URLSearchParams(searchParams);
      params.append("requestTab", value);
      setSearchParams(params.toString());
    } else if (type === "set") {
      const params = new URLSearchParams(searchParams);
      params.set("requestTab", value);
      setSearchParams(params.toString());
    }
  };

  React.useEffect(() => {
    if (requestTab === null) {
      addFollowTypeParam({ value: "receive" });
    } else {
      addFollowTypeParam({ value: requestTab, type: "set" });
    }
    // eslint-disable-next-line
  }, []);

  // const pageSizes = [10, 20, 30, 40, 50];

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      pagination,
    },
  });

  // function paginationChangeHandler(_event, page) {
  //   const actualPage = page - 1;
  //   table.setPageIndex(actualPage);
  // }
  function handleStatusSelectChange(event) {
    const recentValues = event.target.value;

    setConnectionFilter(recentValues);
    if (recentValues !== "none") {
      setSorting([
        {
          id: recentValues,
          asc: true,
        },
      ]);
    } else {
      setSorting([]);
    }
  }
  function handleFollowListTabChange(_event, value) {
    if (value !== requestTab) {
      addFollowTypeParam({ value, type: "set" });
    }
  }

  function handleSearchChange(event) {
    table.getColumn("email")?.setFilterValue(event.target.value);
  }

  return (
    <Box>
      <Paper
        variant="elevation"
        elevation={isDark ? 1 : 3}
        sx={{ padding: "1.25rem", borderRadius: "0.625rem", width: "100%" }}
      >
        <Stack gap={2}>
          <Stack gap={2}>
            <Stack
              gap={2}
              sx={{
                "@media (min-width: 65.5rem)": {
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                },
              }}
            >
              <Stack direction={"row"} gap={2} alignItems={"center"}>
                <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                  <InputLabel id="status-outlined-select-label">Sort By</InputLabel>
                  <Select
                    size="small"
                    value={connectionFilter}
                    onChange={handleStatusSelectChange}
                    labelId="status-outlined-select-label"
                    id="status-outlined-select"
                    label="Sort by"
                  >
                    <MenuItem value="none">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"username"}>Username</MenuItem>
                    <MenuItem value={"recent"}>Recent</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Stack>
                <Search
                  width={"20ch"}
                  hoverwidth={"30ch"}
                  placeholder={"Search for email..."}
                  onChange={handleSearchChange}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <TabContext value={requestTab === null ? "" : requestTab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={requestTab === null ? "" : requestTab}
                  onChange={handleFollowListTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label="Received" value={"receive"} />
                  <Tab label="sent" value={"sent"} />
                </Tabs>
              </Box>
              <TabPanel sx={{ padding: "0rem" }} value={"receive"}>
                {isLoading ? <RequestListSkeleton /> : <RequestList table={table} columns={columns} />}
              </TabPanel>
              <TabPanel sx={{ padding: "0rem" }} value={"sent"}>
                {isLoading ? <RequestListSkeleton /> : <RequestList table={table} columns={columns} />}
              </TabPanel>
            </TabContext>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
}
