import dayjs from "dayjs";
import Cookies from "js-cookie";
import moment from "moment";

//url for production
export var url = "";
if (process.env.NODE_ENV === "development") {
  url = "";
} else {
  url = window.location.host.split("/")[1];
  if (url) {
    url = `/${window.location.host.split("/")[1]}`;
  } else url = process.env.PUBLIC_URL; /// ADD YOUR CPANEL SUB-URL
}

//Function to validate and return errors for a form
export const checkForm = (formData) => {
  let errorState = {};
  Object.keys(formData).forEach((item) => {
    if (formData[item] === null || formData[item] === "") {
      errorState[item] = "This field is required";
    }
  });
  return errorState;
};

//Function that returns the first or first two letters from a name
export const findUpper = (string) => {
  let extractedString = [];

  for (var i = 0; i < string.length; i++) {
    if (string.charAt(i) === string.charAt(i).toUpperCase() && string.charAt(i) !== " ") {
      extractedString.push(string.charAt(i));
    }
  }
  if (extractedString.length > 1) {
    return extractedString[0] + extractedString[1];
  } else {
    return extractedString[0];
  }
};

//Function that calculates the from current date
export const setDeadline = (days) => {
  let todayDate = new Date();
  var newDate = new Date(todayDate);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

// Function to structure date ex : Jun 4, 2011;
export const getDateStructured = (date) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  let final = monthNames[m] + " " + d + ", " + y;
  return final;
};

// Function to structure date ex: YYYY-MM-DD
export const setDateForPicker = (rdate) => {
  let d = rdate.getDate();
  d < 10 && (d = "0" + d);
  let m = rdate.getMonth() + 1;
  m < 10 && (m = "0" + m);
  let y = rdate.getFullYear();
  rdate = y + "-" + m + "-" + d;

  return rdate;
};

// Set deadlines for projects
export const setDeadlineDays = (deadline) => {
  var currentDate = new Date();
  var difference = deadline.getTime() - currentDate.getTime();
  var days = Math.ceil(difference / (1000 * 3600 * 24));
  return days;
};

//Date formatter function
export const dateFormatterAlt = (date, reverse) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  reverse ? (date = m + "-" + d + "-" + y) : (date = y + "-" + d + "-" + m);
  return date;
};

//Date formatter function
export const dateFormatter = (date, reverse, string) => {
  var dateformat = date.split("-");
  //var date = dateformat[1]+"-"+dateformat[2]+"-"+dateformat[0];
  reverse
    ? (date = dateformat[2] + "-" + dateformat[0] + "-" + dateformat[1])
    : (date = dateformat[1] + "-" + dateformat[2] + "-" + dateformat[0]);

  return date;
};

//todays Date
export const todaysDate = new Date();

//current Time
export const currentTime = () => {
  var hours = todaysDate.getHours();
  var minutes = todaysDate.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

//Percentage calculation
export const calcPercentage = (str1, str2) => {
  let result = Number(str2) / Number(str1);
  result = result * 100;
  return Math.floor(result);
};

export const truncate = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + " " + truncate(str.substr(n - 1, str.length), n) : str;
};

// returns upload url
export const getUploadParams = () => {
  return { url: "https://httpbin.org/post" };
};

export const bulkActionOptions = [
  { value: "suspend", label: "Suspend User" },
  { value: "delete", label: "Delete User" },
];

// Converts KB to MB
export const bytesToMegaBytes = (bytes) => {
  let result = bytes / (1024 * 1024);
  return result.toFixed(2);
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const getCookie = (cookieName, defaultValue = "") => {
  let value = defaultValue;
  let tempValue = Cookies.get(cookieName);
  if (tempValue) {
    value = tempValue;
  }
  return value;
};
export const getInitials = (text) => {
  const words = text.split(" ");

  // Get the first word (or an empty string if no words are present)
  const firstWord = words[0] || "";

  // Get the second word, if available, or an empty string
  const secondWord = words.length > 1 ? words[1] : "";

  // Get the first letter of the first word and capitalize it
  const firstInitial = firstWord.charAt(0).toUpperCase();

  // Get the first letter of the second word, if available, and capitalize it
  const secondInitial = secondWord.charAt(0).toUpperCase();

  // Concatenate the initials together
  return firstInitial + (secondInitial || firstWord.charAt(1).toUpperCase());
};

export const toggleErrorModel = (setToggle, toggle) => {
  setToggle(!toggle);
};

export function createMask(text) {
  let maskedText = "";

  // Check if text is a string
  if (typeof text === "string") {
    if (text.includes("@")) {
      const atIndex = text.lastIndexOf("@");
      const prefix = text.substring(0, atIndex);
      const postfix = text.substring(atIndex);
      maskedText = prefix.substring(0, 2) + "*".repeat(prefix.length - 2) + postfix;
    } else if (/\d/.test(text)) {
      const digits = text.replace(/\D/g, "");
      const maskedDigits =
        digits.substring(0, digits.length - 4).replace(/\d/g, "*") + digits.substring(digits.length - 4);
      maskedText = text.replace(/\d/g, (_, index) => maskedDigits.charAt(index));
    } else {
      maskedText = text;
    }
  } else {
    // If text is not a string, return it as is
    maskedText = text;
  }

  return maskedText;
}

export function sumArray(arr) {
  return arr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

export function shortenString(str, maxLen) {
  if (str.length <= maxLen) {
    return str;
  } else {
    return str.slice(0, maxLen - 1) + "...";
  }
}

export function getTimeAgoString(timeString) {
  const currentTime = new Date();
  const targetTime = new Date(timeString);

  const timeDifferenceInSeconds = Math.floor((currentTime - targetTime) / 1000);

  if (timeDifferenceInSeconds < 60) {
    return `${timeDifferenceInSeconds} sec ago`;
  } else if (timeDifferenceInSeconds < 3600) {
    const minutesAgo = Math.floor(timeDifferenceInSeconds / 60);
    return `${minutesAgo} min ago`;
  } else if (timeDifferenceInSeconds < 86400) {
    const hoursAgo = Math.floor(timeDifferenceInSeconds / 3600);
    return `${hoursAgo} hour ago`;
  } else if (timeDifferenceInSeconds < 2592000) {
    const daysAgo = Math.floor(timeDifferenceInSeconds / 86400);
    return `${daysAgo} day ago`;
  } else if (timeDifferenceInSeconds < 31536000) {
    const monthsAgo = Math.floor(timeDifferenceInSeconds / 2592000);
    return `${monthsAgo} month ago`;
  } else {
    const yearsAgo = Math.floor(timeDifferenceInSeconds / 31536000);
    return `${yearsAgo} year ago`;
  }
}

export function base64toFile(base64Data, fileName) {
  try {
    // Check if the input is a valid base64 string
    if (!base64Data || typeof base64Data !== "string" || !base64Data.startsWith("data:image")) {
      throw new Error("Invalid base64 data");
    }

    const base64String = base64Data.split(";base64,").pop();

    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: "image/png" });

    return new File([blob], fileName, { type: "image/png" });
  } catch (error) {
    console.error("Error converting base64 to file:", error);
    return null;
  }
}

export async function blobToFile(blobUrl, fileName) {
  try {
    const response = await fetch(blobUrl);

    if (!response.ok) {
      throw new Error("Failed to fetch Blob data");
    }

    const blob = await response.blob();
    const file = new File([blob], fileName);

    return file;
  } catch (error) {
    console.error("Error converting Blob to File:", error);
    return null;
  }
}

export const isFutureDate = (selectedDate) => {
  // Get today's date in UTC to avoid timezone issues
  const today = dayjs().utc();

  // Convert selected date to UTC for accurate comparison
  const selectedDateUtc = dayjs(selectedDate).utc();

  // Check if selected date is after today (including tomorrow)
  return selectedDateUtc.isAfter(today);
};

export function isDifferenceDays(is_subscribed_end_date, differenceDays) {
  // Parse the input date using moment
  const endDate = moment(is_subscribed_end_date).startOf("day");

  // Get the current date using moment
  const currentDate = moment().startOf("day");

  // Calculate the difference in days
  const differenceInDays = endDate.diff(currentDate, "days");

  // Check if the difference is in the specified range
  return differenceInDays >= 0 && differenceInDays <= differenceDays;
}

export function generateRandomPastelColor() {
  // Generate random values within a specific range for each RGB component
  const r = Math.floor(Math.random() * 100) + 155; // R component in the range [155, 255]
  const g = Math.floor(Math.random() * 100) + 155; // G component in the range [155, 255]
  const b = Math.floor(Math.random() * 100) + 155; // B component in the range [155, 255]
  // Convert RGB components to hexadecimal and concatenate
  return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
}

export function generateLinearGradient() {
  // Generate three random pastel colors
  const color1 = generateRandomPastelColor();
  const color2 = generateRandomPastelColor();
  const color3 = generateRandomPastelColor();
  // Generate linear gradient string
  return `linear-gradient(to top right, ${color1}, ${color2}, ${color3})`;
}

export const getBackgroundColor = (elevation, theme) => {
  if (theme.palette.mode === "dark") {
    return theme.palette.background.paper[elevation * 100];
  }
  return theme.palette.background.paper;
};
