import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

//^ http request
import { getUserListsHandler } from "../../../http/post-api";

//^ mui components
import { Box } from "@mui/material";

//^ tables content
import DataTable from "./data-table";
import { columns } from "./columns";

export default function FollowersTable() {
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const followType = searchParams.get("followType") || "followed";

  //^ get followers list query fn =================================================================
  const {
    data: followersData,
    isLoading: followersIsLoading,
    isRefetching: followersIsRefetching,
    isError: followersIsError,
    error: followersError,
    // refetch: followersRefetch,
  } = useQuery({
    queryKey: [`get-${followType}-list`, followType],
    queryFn: async () => getUserListsHandler({ type: followType }),
    gcTime: 0,
  });

  React.useEffect(() => {
    if (!followersIsLoading || !followersIsRefetching) {
      if (followersData) {
        if (!followersData?.status) {
          if (followersData?.redirect) {
            window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
          }
        }
      }
    }
  }, [followersIsLoading, followersIsRefetching, followersData]);

  React.useEffect(() => {
    if (followersIsError) {
      toast.error(followersError.message);
    }
  }, [followersIsError, followersError]);

  //^ get followers list query fn =================================================================

  console.log(followersData);

  return (
    <Box>
      <DataTable columns={columns} data={followersData?.data?.list || []} isLoading={followersIsLoading} />
    </Box>
  );
}
