import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

//^ utils
import { generateLinearGradient, getInitials } from "../../utils/Utils";

//^ mui component
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

//^ http request
import { getUserInvitationHandler, getUserListHandler } from "../../http/post-api";

//^ tabs
import ConnectionTab from "./connections";
import RequestTab from "./Requests";
import FollowingAndFollowerTab from "./FollowersAndFollowing";
import InvitationTab from "./Invitations";

//^ ui components
// import Title from "../../components/title/Title";
import { toast } from "react-toastify";
import InviteListLoader from "../../components/ui/loader/Skeleton/InviteListLoader";

function UserList({ text, seeAllOnclick, buttonText, ...props }) {
  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        borderRadius: "0.625rem",
      }}
      elevation={1}
    >
      <List
        sx={{
          width: "100%",
          "@media (min-width: 65.5rem)": {
            paddingBottom: "1rem",
          },
        }}
        component={Paper}
        elevation={1}
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component={Paper}
            elevation={1}
            sx={{
              boxShadow: "none",
            }}
            id="nested-list-subheader"
          >
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <span>{text}</span>
              <Button size="small" onClick={seeAllOnclick}>
                See all
              </Button>
            </Stack>
          </ListSubheader>
        }
      >
        <>
          <ListItem
            sx={{
              width: "100%",
              padding: "1rem 0rem 1rem 0rem",
              "@media (min-width: 65.5rem)": {
                padding: "0rem 1rem",
              },
              "@media (min-width: 768px)": {
                padding: "1rem 1rem",
              },
            }}
          >
            <Box
              width={"100%"}
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr",
                gap: "1rem",
                // rowGap: "3rem",
                alignItems: "center",
                justifyContent: "center",
                "@media (min-width: 768px)": {
                  gridTemplateColumns: "repeat(2, 1fr)",
                },
                "@media (min-width: 65.5rem)": {
                  gridTemplateColumns: "repeat(4, 1fr)",
                },
              }}
            >
              {props?.userListIsLoading ? (
                "loading..."
              ) : props?.notFollowedPeoples?.length > 0 ? (
                props?.notFollowedPeoples?.map((user, index) => {
                  return (
                    <Stack alignItems={"center"} justifyContent={"center"}>
                      <Card
                        key={index}
                        variant="outlined"
                        elevation={3}
                        sx={{
                          height: "100%",
                          width: "100%",
                          borderRadius: "0.725rem",
                        }}
                      >
                        <Stack height={"100%"} justifyContent={"space-between"} gap={3}>
                          <Stack position={"relative"} width={"100%"}>
                            <Box
                              width={"100%"}
                              height={"6rem"}
                              sx={{
                                background: user?.bannerImgString,
                              }}
                            />
                            <Stack
                              direction={"row"}
                              position={"absolute"}
                              zIndex={1}
                              bottom={"-2rem"}
                              width={"100%"}
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              <Avatar
                                src={user?.user_avatar}
                                alt={user?.username}
                                sx={{
                                  width: 78,
                                  height: 78,
                                }}
                              />
                            </Stack>
                          </Stack>
                          <CardContent>
                            <Stack alignItems={"center"} justifyContent={"flex-start"} marginTop={"0.625rem"}>
                              <Typography
                                textTransform={"capitalize"}
                                textAlign={"center"}
                                gutterBottom
                                variant="h6"
                                component="div"
                              >
                                {user?.username}
                              </Typography>
                              <Typography textAlign={"center"} gutterBottom variant="subtitle2" component="div">
                                {user?.email}
                              </Typography>
                            </Stack>
                          </CardContent>
                          <CardActions
                            sx={{
                              padding: "1.25rem",
                              paddingTop: "0rem",
                              width: "100%",
                            }}
                          >
                            <Stack gap={2} alignItems={"center"} width={"100%"}>
                              {user?.mutualConnect > 0 ? (
                                <Typography>{user?.mutual_connection} mutual connection</Typography>
                              ) : (
                                <Typography
                                  sx={{
                                    opacity: 0,
                                  }}
                                >
                                  You don't follow
                                </Typography>
                              )}
                              <Button
                                variant="contained"
                                size="medium"
                                sx={{
                                  minWidth: "7rem",
                                  borderRadius: "10rem",
                                }}
                              >
                                {buttonText}
                              </Button>
                            </Stack>
                          </CardActions>
                        </Stack>
                      </Card>
                    </Stack>
                  );
                })
              ) : (
                <Stack
                  width={"100%"}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gridColumn={"span 4"}
                >
                  <Typography variant="subtitle1" textTransform={"capitalize"}>
                    User lists are empty
                  </Typography>
                </Stack>
              )}
            </Box>
          </ListItem>
        </>
      </List>
    </Card>
  );
}

function HomeTab({ notConnectedUsers, notConnectedIsLoading, ...props }) {
  // eslint-disable-next-line
  const [_, setSearchParams] = useSearchParams();

  return (
    <>
      <Stack gap={2} width={"100%"}>
        <Card variant="outlined" sx={{ borderRadius: "0.625rem", width: "100%" }} elevation={1}>
          <List
            sx={{
              width: "100%",
            }}
            component={Paper}
            elevation={2}
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader
                component={Paper}
                elevation={1}
                sx={{
                  boxShadow: "none",
                }}
                id="nested-list-subheader"
              >
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                  <span>Invitations</span>
                  <Button onClick={() => setSearchParams({ tab: "Requests", requestTab: "receive" })} size="small">
                    See all
                  </Button>
                </Stack>
              </ListSubheader>
            }
          >
            <Divider />
            {props.inviteIsLoading ? (
              <InviteListLoader />
            ) : props.inviteLists.length > 0 ? (
              props.inviteLists.map((inviteList, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Stack
                          width={"100%"}
                          gap={2}
                          sx={{
                            "@media (min-width: 65.5rem)": {
                              flexDirection: "row",
                              justifyContent: "space-between",
                            },
                          }}
                        >
                          <Stack direction={"row"} gap={2} alignItems={"center"}>
                            <Avatar>{getInitials(inviteList?.username)}</Avatar>
                            <Typography variant="subtitle1" textTransform={"capitalize"}>
                              {inviteList?.username}
                            </Typography>
                          </Stack>
                          <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} gap={2}>
                            <Button variant="text" size="small">
                              ignore
                            </Button>
                            <Button
                              variant="contained"
                              sx={{
                                borderRadius: "10rem",
                              }}
                              size="small"
                            >
                              Accept
                            </Button>
                          </Stack>
                        </Stack>
                      }
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))
            ) : (
              <Stack padding={"1.25rem"} direction={"row"} alignItems={"center"} justifyContent={"center"}>
                <Typography variant="subtitle1" textTransform={"capitalize"}>
                  Invitations are empty
                </Typography>
              </Stack>
            )}
          </List>
        </Card>
        <Stack width={"100%"}>
          <UserList
            text={"People you don’t Follow"}
            userListIsLoading={props.userListIsLoading}
            notFollowedPeoples={props.notFollowedPeoples}
            buttonText={"follow"}
            seeAllOnclick={() => setSearchParams({ tab: "Following & Follower", followType: "not_followed" })}
          />
        </Stack>
        <UserList
          text={"People you don’t connected"}
          userListIsLoading={notConnectedIsLoading}
          notFollowedPeoples={notConnectedUsers}
          buttonText={"connect"}
          seeAllOnclick={() => setSearchParams({ tab: "Connections", connectType: "notConnected" })}
        />
      </Stack>
    </>
  );
}

export default function UserPage() {
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const tabName = searchParams.get("tab");

  //^ states
  const [tabContent, setTabContent] = useState("");
  const [inviteLists, setInviteLists] = useState([]);
  const [notFollowedUsers, setNotFollowedUsers] = useState([]);
  const [notConnectedUsers, setNotConnectedUsers] = useState([]);

  useEffect(() => {
    if (tabName === "Connections") {
      setTabContent(<ConnectionTab />);
    } else if (tabName === "Requests") {
      setTabContent(<RequestTab />);
    } else if (tabName === "Following & Follower") {
      setTabContent(<FollowingAndFollowerTab />);
    } else if (tabName === "Invitations") {
      setTabContent(<InvitationTab />);
    } else {
      setTabContent("");
    }
  }, [tabName]);

  //^ get invitations query fn ===================================================================================
  const {
    data: userInvitationData,
    isLoading: userInvitationIsLoading,
    isRefetching: userInvitationIsRefetching,
    isError: userInvitationIsError,
    error: userInvitationError,
    // refetch: userInvitationRefetch,
  } = useQuery({
    queryKey: ["get-user-invitations"],
    queryFn: getUserInvitationHandler,
  });

  useEffect(() => {
    if (!userInvitationIsLoading || !userInvitationIsRefetching) {
      if (!userInvitationData?.status) {
        if (userInvitationData?.redirect === true) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        }
      } else {
        setInviteLists(userInvitationData?.data?.invitations);
      }
    }
  }, [userInvitationIsLoading, userInvitationIsRefetching, userInvitationData]);

  useEffect(() => {
    if (userInvitationIsError) {
      toast.error(userInvitationError?.message || "Something went wrong");
    }
  }, [userInvitationIsError, userInvitationError]);

  //^ get invitations query fn ===================================================================================

  //^ get not followed peoples query fn ==========================================================================
  const {
    data: userListData,
    isLoading: userListIsLoading,
    isRefetching: userListIsRefetching,
    isError: userListIsError,
    error: userListError,
    // refetch: userListRefetch,
  } = useQuery({
    queryKey: ["get-not-followed-lists"],
    queryFn: async () => getUserListHandler({ type: "not_followed" }),
  });

  useEffect(() => {
    if (!userListIsLoading || !userListIsRefetching) {
      if (!userListData?.status) {
        if (userListData?.redirect === true) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        }
      } else {
        setNotFollowedUsers(
          // eslint-disable-next-line
          userListData?.data?.list?.map((userList) => {
            const gradientString = generateLinearGradient();

            return {
              bannerImgString: `${gradientString}`,
              ...userList,
            };
          })
        );
      }
    }
  }, [userListIsLoading, userListData, userListIsRefetching]);

  useEffect(() => {
    if (userListIsError) {
      toast.error(userListError?.message || "Something went wrong");
    }
  }, [userListIsError, userListError]);

  //^ get not followed peoples query fn ==========================================================================

  //^ get not followed peoples query fn ==========================================================================
  const {
    data: notConnectedUserListData,
    isLoading: notConnectedUserListLoading,
    isRefetching: notConnectedUserListRefetching,
    isError: notConnectedUserListIsError,
    error: notConnectedUserListError,
    // refetch: userListRefetch,
  } = useQuery({
    queryKey: ["get-not-connected-lists"],
    queryFn: async () => getUserListHandler({ type: "not_connected" }),
  });

  useEffect(() => {
    if (!notConnectedUserListLoading || !notConnectedUserListRefetching) {
      if (!notConnectedUserListData?.status) {
        if (notConnectedUserListData?.redirect === true) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        }
      } else {
        setNotConnectedUsers(
          // eslint-disable-next-line
          notConnectedUserListData?.data?.user_list
            ?.map((userList) => {
              const gradientString = generateLinearGradient();

              return {
                bannerImgString: `${gradientString}`,
                ...userList,
              };
            })
            .filter((userList) => userList?.follow_status.following === false)
        );
      }
    }
  }, [notConnectedUserListLoading, notConnectedUserListData, notConnectedUserListRefetching]);

  useEffect(() => {
    if (notConnectedUserListIsError) {
      toast.error(notConnectedUserListError?.message || "Something went wrong");
    }
  }, [notConnectedUserListIsError, notConnectedUserListError]);

  //^ get not followed peoples query fn ==========================================================================

  return (
    <>
      {/* {tabName !== null && (
        <Box sx={{ gridColumn: "1 / span 2", gridRow: "0" }}>
          <Title
            title={`${process.env.REACT_APP_PROJECT_SHORT_NAME} ${
              tabName === "Following & Follower" ? "Followers" : tabName
            }`}
          />
        </Box>
      )} */}
      {tabName === null ? (
        <HomeTab
          inviteLists={inviteLists}
          notFollowedPeoples={notFollowedUsers}
          inviteIsLoading={userInvitationIsLoading}
          userListIsLoading={userListIsLoading}
          notConnectedUsers={notConnectedUsers}
          notConnectedIsLoading={notConnectedUserListLoading}
        />
      ) : (
        ""
      )}
      {tabContent}
    </>
  );
}
