import { Box } from "@mui/material";

// import Title from "../../components/title/Title";
import Head from "../../layout/head/Head";
// import { BlockBetween, BlockHead, BlockHeadContent } from "../../components/Component";

import ServiceTable from "../../tables/service/table";

export default function ServicePage() {
  return (
    <Box>
      <Head title="Services" />
      {/* <BlockHead>
        <BlockBetween>
          <BlockHeadContent className={"w-100"}>
            <Title title={"Services"} />
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead> */}
      <ServiceTable />
    </Box>
  );
}
