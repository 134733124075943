import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

export const CustomButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(23deg, rgb(0 0 0) 0%, rgb(33 99 6) 35%, rgb(69 231 66) 100%)",
  borderColor: theme.palette.primary.main,
  color: "#fff",
  "&:hover": {
    background: "linear-gradient(23deg, rgb(82 224 93) 0%, rgb(54 186 0) 35%, rgb(4 28 4) 100%)",
    borderColor: theme.palette.primary.dark,
  },
  "&:focus": {
    background: "linear-gradient(23deg, rgb(82 224 93) 0%, rgb(54 186 0) 35%, rgb(4 28 4) 100%)",
    borderColor: theme.palette.primary.dark,
  },
  "&.btn-dim": {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.primary.main,
    opacity: 0.89,
  },
}));
