import { useState } from "react";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Spinner } from "reactstrap";
import * as yup from "yup";
import { countries } from "country-data";
import { parsePhoneNumber } from "libphonenumber-js";
import { useSelector } from "react-redux";

//^ stylesheets
import classes from "./TwoFactAuth.module.scss";

//^ lib
import { postSet2FAProfileHandler } from "../../../../../http/post-api";
import { queryClient } from "../../../../../http";

//^ ui
import { Icon } from "../../../../../components/Component";
import { Box, Stack, Button } from "@mui/material";

//^ components
import AuthOtp from "../../../../../components/ui/auth-otp/AuthOtp";
import ResendOtpTimer from "../components/ResendOtpTimer";
import { useTheme } from "../../../../../layout/provider/Theme";
import ErrorMessage from "../../../../../components/ui/error-message/ErrorMessage";
import ErrorAlert from "../../../../../components/ui/modals/error-model/ErrorAlert";

export default function MobileTwoFactorAuth() {
  const theme = useTheme();
  const isDark = theme.skin === "dark";

  const userProfileData = useSelector((state) => state.userProfile.profileData);
  const userProfile = useSelector((state) => state.twoFactorUserProfile.userProfile);

  const countryIso = userProfile?.profile_iso || "US";

  const [phoneInputIsDisabled, setPhoneInputIsDisabled] = useState(
    userProfileData?.phonecode && userProfileData?.two_fact_phone ? true : userProfileData?.phone_number ? true : false
  );

  const [phoneOtpData, setPhoneOtpData] = useState(undefined);
  const [isCompleted, setIsCompleted] = useState(null);
  const [showOtpTimer, setShowOtpTimer] = useState(false);

  //^ error handling state
  const [phoneInputError, setPhoneInputError] = useState(false);

  //^ post send otp mutation query
  const {
    isPending: phoneOtpIsPending,
    isError: phoneOtpIsError,
    error: phoneOtpError,
    mutate: phoneOtpMutate,
    reset: phoneOtpReset,
  } = useMutation({
    mutationKey: ["send-otp-verification-for-mobile"],
    mutationFn: postSet2FAProfileHandler,
    onSuccess: (data) => {
      if (data.type === "error") {
        toast.error(data?.message);
      } else if (data.type === "success") {
        setPhoneInputIsDisabled(true);
        setShowOtpTimer(true);
        setPhoneOtpData(data);
        queryClient.invalidateQueries(["get-user-profile"]);
        toast.success(data?.message);
      } else if (data.message === "Validation failed") {
        toast.error(data?.message);
      }
      phoneOtpReset();
    },
  });

  const schema = yup.object().shape({
    mobile: yup.string().required(),
  });

  const countryCallingCode =
    countries[userProfile?.profile_iso ? userProfile?.profile_iso : "US"]?.countryCallingCodes[0];
  const phoneNumber = userProfile?.phone_number ? userProfile?.phone_number : "";
  const factPhoneCode = userProfile?.two_fact_phone ? userProfile?.two_fact_phone : "";

  const formik = useFormik({
    initialValues: {
      mobile: userProfile?.two_fact_phone
        ? `${countryCallingCode}${factPhoneCode}`
        : `${countryCallingCode}${phoneNumber}`,
    },
    validationSchema: schema,
    onSubmit(values) {
      const phoneNumber = parsePhoneNumber(values.mobile);
      const countryCode = phoneNumber.countryCallingCode;
      const number = phoneNumber.nationalNumber;

      phoneOtpMutate({ phoneCode: countryCode, twoFactAuth: "2", twoFactPhone: number });
    },
  });

  const phoneInputChangeHandler = (value) => {
    const stringValue = String(value);

    const isValid = isValidPhoneNumber(stringValue);
    formik.setFieldValue("mobile", stringValue);

    if (!isValid) {
      setPhoneInputError(true);
    } else {
      setPhoneInputError(false);
    }
  };

  return (
    <>
      {phoneOtpIsError && (
        <ErrorAlert
          title={`${phoneOtpError?.code || 500}`}
          description={`${phoneOtpError?.info?.message || "Something went wrong"}`}
          isConformed={() => phoneOtpReset()}
        />
      )}

      <div className="d-flex flex-column h-100" style={{ gap: "2rem" }}>
        <form onSubmit={formik.handleSubmit}>
          <Stack gap={"1rem"}>
            <Stack
              direction={"column"}
              justifyContent={"space-between"}
              width={"100%"}
              gap={"1rem"}
              sx={{
                "@media (min-width: 65.5rem)": {
                  flexDirection: "row",
                },
              }}
            >
              <Box
                className={`${isDark && phoneInputIsDisabled ? classes["dark"] : ""} w-100 d-flex flex-column`}
                style={{ gap: "0.252rem" }}
              >
                <Stack className={`${isDark ? classes["dark"] : ""} ${classes["input"]}`} gap={"0.325rem"}>
                  <PhoneInput
                    value={formik.values.mobile}
                    onChange={phoneInputChangeHandler}
                    onBlur={() => setPhoneInputIsDisabled(true)}
                    defaultCountry={countryIso}
                    className={`${classes["phone-input"]} ${
                      isDark ? classes["dark-mode"] : ""
                    } form-control form-control-sm ${phoneInputError ? "invalid" : ""} w-100 ${
                      phoneInputIsDisabled ? classes["disable-phone-input"] : ""
                    } ${classes["phone-input"]}`}
                    style={{ height: "48px", fontSize: "14px" }}
                    international
                    disabled={phoneInputIsDisabled}
                  />
                  {phoneInputError ? <ErrorMessage errorMsg={"Enter valid phone number."} /> : ""}
                </Stack>
              </Box>
              <Stack alignItems={"flex-end"}>
                <Button
                  variant="contained"
                  sx={{ height: "48px" }}
                  type="button"
                  size="large"
                  onClick={() => setPhoneInputIsDisabled(false)}
                >
                  <Icon name="edit" className={"fs-4"} />
                </Button>
              </Stack>
            </Stack>
            <Stack direction={"row"} gap={"1rem"} alignItems={"center"}>
              <Button
                variant="contained"
                type={"submit"}
                color="primary"
                disabled={
                  phoneInputError || phoneOtpIsPending || isCompleted === false ? true : false || !formik.isValid
                }
                className={"d-flex"}
                style={{
                  gap: "0.5rem",
                  alignItems: "center",
                  cursor: phoneInputError ? "not-allowed" : "",
                  whiteSpace: "nowrap",
                }}
                startIcon={
                  phoneOtpIsPending ? <Spinner size={"sm"} style={{ borderWidth: "2px", color: "inherit" }} /> : ""
                }
              >
                <span>
                  {phoneOtpData && phoneOtpData?.code === 200 && phoneOtpData?.type === "success"
                    ? "Resend OTP"
                    : "Send OTP"}
                </span>
              </Button>
              <Box>
                {showOtpTimer ? (
                  <ResendOtpTimer
                    onOtpTimer={(isCompleted) => {
                      if (isCompleted) {
                        phoneOtpReset();
                        setShowOtpTimer(false);
                        setIsCompleted(isCompleted);
                      } else {
                        setIsCompleted(isCompleted);
                      }
                    }}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Stack>
          </Stack>
        </form>
        {phoneOtpData && phoneOtpData?.code === 200 ? (
          <AuthOtp otpSendOn={phoneOtpData?.data?.phone_no} type={"mobile"} />
        ) : (
          ""
        )}
      </div>
    </>
  );
}
