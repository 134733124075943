import { Stack, Box, Skeleton, Card, List, Paper, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

export default function UserAccountLoader() {
  return (
    <Box width={"100%"} height={"100%"}>
      <Box
        width={"100%"}
        height={"100%"}
        sx={{
          paddingBottom: "4.25rem",
          "@media (min-width: 65.5rem)": {
            paddingBottom: "0rem",
          },
        }}
      >
        <Box
          width={"100%"}
          height={"100%"}
          gap={3}
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr",
            "@media (min-width: 65.5rem)": {
              gridTemplateColumns: "0.3fr 1fr",
              direction: "row",
            },
          }}
        >
          <Box
            sx={{
              "@media (min-width: 65.5rem)": {
                display: "block",
              },
            }}
          >
            <Card variant="outlined" sx={{ borderRadius: "0.625rem", paddingTop: "1rem" }}>
              <List
                sx={{ width: "100%" }}
                component={Paper}
                elevation={1}
                aria-labelledby="nested-list-subheader"
                subheader={
                  <Box padding={"0rem 1rem"}>
                    <Skeleton animation="wave" height={"2rem"} width={"100%"} />
                  </Box>
                }
              >
                <>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <ListItemButton key={index}>
                      <ListItemIcon>
                        <Skeleton animation="wave" width={"80%"} />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Stack
                            direction={"row"}
                            width={"100%"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            gap={"0.625rem"}
                          >
                            <Skeleton animation="wave" width={"30%"} />
                            <Skeleton animation="wave" width={"15%"} />
                          </Stack>
                        }
                      />
                    </ListItemButton>
                  ))}
                </>
              </List>
            </Card>
          </Box>
          <Box
            sx={{
              "@media (min-width: 65.5rem)": {
                display: "block",
              },
            }}
          >
            <Card variant="outlined" sx={{ borderRadius: "0.625rem", paddingTop: "1rem" }}>
              <List
                sx={{ width: "100%" }}
                component={Paper}
                elevation={1}
                aria-labelledby="nested-list-subheader"
                subheader={
                  <Box padding={"0rem 1rem"}>
                    <Skeleton animation="wave" height={"2rem"} width={"100%"} />
                  </Box>
                }
              >
                <>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <ListItemButton key={index}>
                      <ListItemIcon>
                        <Skeleton animation="wave" width={"2rem"} variant="circular" height={"2rem"} />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Stack
                            direction={"row"}
                            width={"100%"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            gap={"0.625rem"}
                          >
                            <Skeleton animation="wave" variant="rounded" width={"100%"} />
                          </Stack>
                        }
                      />
                    </ListItemButton>
                  ))}
                </>
              </List>
            </Card>
          </Box>
          <Box
            sx={{
              "@media (min-width: 65.5rem)": {
                display: "block",
              },
            }}
          >
            <Card variant="outlined" sx={{ borderRadius: "0.625rem", paddingTop: "1rem" }}>
              <List
                sx={{ width: "100%" }}
                component={Paper}
                elevation={1}
                aria-labelledby="nested-list-subheader"
                subheader={
                  <Box padding={"0rem 1rem"}>
                    <Skeleton animation="wave" height={"2rem"} width={"100%"} />
                  </Box>
                }
              >
                <>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <ListItemButton key={index}>
                      <ListItemText
                        primary={
                          <Stack
                            direction={"row"}
                            width={"100%"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            gap={"0.625rem"}
                          >
                            <Skeleton
                              animation="wave"
                              variant="rounded"
                              width={"100%"}
                              sx={{ borderRadius: "0.625rem", height: "20rem" }}
                            />
                          </Stack>
                        }
                      />
                    </ListItemButton>
                  ))}
                </>
              </List>
            </Card>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
