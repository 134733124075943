import React from "react";
import classNames from "classnames";
import { Paper } from "@mui/material";

function AppWrap({ className, ...props }) {
  const compClass = classNames({
    "nk-wrap": true,
    [`${className}`]: className,
  });
  return (
    <Paper className={compClass} sx={{ width: "100%" }}>
      {props.children}
    </Paper>
  );
}

export default AppWrap;
