import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

//^ images
import Logo from "../../../images/logo.png";
import LogoDark from "../../../images/logo-dark.png";

//^ layoutes
import Head from "../../../layout/head/Head";

//^ http requests
import { postResendVerify2faOtp } from "../../../http/post-api";

//^ components
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, PreviewCard } from "../../../components/Component";
import AuthOtp from "../../../components/ui/auth-otp/AuthOtp";
import ResendTimer from "../../pre-built/user-manage/TwoFactorSettings/components/ResendOtpTimer";
import { createMask } from "../../../utils/Utils";
import { Spinner } from "reactstrap";
import LogoFetch from "../LogoFetch";
import ErrorAlert from "../../../components/ui/modals/error-model/ErrorAlert";

export default function LoginTwoFactOtpVerification({ email, mobile, type, username }) {
  const [isCompleted, setIsCompleted] = useState(false);

  const maskedEmail = createMask(email);
  const maskedPhoneNumber = createMask(mobile ? mobile.toString() : "");

  const handleOnOtpTimer = (isCompleted) => {
    if (isCompleted) {
      setIsCompleted(isCompleted);
    } else {
      setIsCompleted(isCompleted);
    }
  };

  //^ resend otp mutation query
  const {
    isPending: resendOtpIsPending,
    isError: resendOtpIsError,
    error: resendOtpError,
    mutate: resendOtpMutate,
    reset: resendOtpReset,
  } = useMutation({
    mutationKey: ["post-resend-verify-2fa-otp"],
    mutationFn: postResendVerify2faOtp,
    onSuccess: (data) => {
      if (data.toast) {
        if (data.status) {
          if (data.type === "error") {
            toast.error(data?.message);
          } else if (data.type === "success") {
            toast.success(data?.message);
          } else if (data.message === "Validation failed") {
            toast.error(data?.message);
          }
        } else {
          toast.error(data?.message);
        }
      }
      resendOtpReset();
    },
  });

  useEffect(() => {
    if (resendOtpIsError) {
    }
  }, [resendOtpError, resendOtpIsError]);

  const resendOtpHandler = (ev) => {
    ev.preventDefault();

    resendOtpMutate({ username });
  };

  return (
    <>
      {resendOtpIsError && (
        <ErrorAlert
          title={`${resendOtpError?.code || 500}`}
          description={`${resendOtpError?.info?.message || "Something went wrong"}`}
          isConformed={() => resendOtpReset()}
        />
      )}

      <Head title="Login" />
      <Block className="nk-block-middle nk-auth-body wide-sm">
        <div className="brand-logo pb-2 w-30 mx-auto text-center">
          {/* <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link> */}
          <LogoFetch />
        </div>

        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">Two Factor Authentication</BlockTitle>
            </BlockContent>
          </BlockHead>
          <div className="form-group">
            <div className="form-label-group">
              <label className="form-label" htmlFor="default-01">
                Verify OTP
              </label>
            </div>
            <div className="form-control-wrap d-flex flex-column" style={{ gap: "0.625rem" }}>
              <AuthOtp
                type="login-two-fact"
                twoFactAuth={type}
                maskOtpSendOn={
                  type === "1"
                    ? maskedEmail
                    : type === "2"
                    ? maskedPhoneNumber
                    : type === "3"
                    ? { maskedEmail, maskedPhoneNumber }
                    : ""
                }
                otpSendOn={type === "1" ? email : type === "2" ? mobile : type === "3" ? { email, mobile } : ""}
                username={username}
              />
              <BlockDes className={`sub-text`}>
                {isCompleted ? (
                  <div>
                    <Link
                      onClick={resendOtpHandler}
                      className={"d-flex"}
                      disabled={resendOtpIsPending}
                      style={{
                        gap: "0.5rem",
                        cursor: resendOtpIsPending ? "not-allowed" : "",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <span>Resend OTP</span>
                      {resendOtpIsPending ? <Spinner color="light" size={"sm"} /> : ""}
                    </Link>
                  </div>
                ) : (
                  <ResendTimer onOtpTimer={handleOnOtpTimer} />
                )}
              </BlockDes>
            </div>
          </div>
        </PreviewCard>
      </Block>
    </>
  );
}
