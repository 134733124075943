import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profileData: null,
  userWholeProfileData: {
    token_value: 0,
    account_tokens: 0,
  },
  referAndEarnData: undefined,
  tokenValue: 0,
};

const userProfileSlice = createSlice({
  initialState,
  name: "user-profile",
  reducers: {
    setProfileData: (state, { payload }) => {
      state.profileData = payload.profileData;
    },
    setWholeProfileData: (state, { payload }) => {
      state.userWholeProfileData = payload.wholeProfileData;
    },
    setReferAndEarnData: (state, { payload }) => {
      state.referAndEarnData = payload.referAndEarnData;
    },
    setTokenValue: (state, { payload }) => {
      state.tokenValue = payload;
    },
  },
});

export const userProfileAction = userProfileSlice.actions;

export default userProfileSlice.reducer;
