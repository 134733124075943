import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";

//^ stylesheets
import classes from "./SubscriptionPackage.module.scss";
import styles from "./current-subscription/CurrentSubscription.module.scss";
import "./package.css";

//^ utils
import { getCookie } from "../../../utils/Utils";

//^ http request
import {
  getSubscriptedPackageHandler,
  getSubscriptionPlansHandler,
  subscribePackageHandler,
} from "../../../http/post-api";
import { queryClient } from "../../../http";

//^ store slices
import { setPayWithTokensSpinner, setPaymentModal, setPaymentSlice } from "../../../store/slices/payment-slice";
import { setAugerFee, setPlan, setTokenValue } from "../../../store/slices/package-subscription-slice";
import Payment from "./Payment";

//^ layouts
import Head from "../../../layout/head/Head";

//^ components
import { BlockBetween, BlockHead, BlockHeadContent, Icon } from "../../../components/Component";
import { GreetingMessage } from "./current-subscription/CurrentSubscription";
import ErrorAlert from "../../../components/ui/modals/error-model/ErrorAlert";
import Separator from "../../../components/ui/separator/Separator";
import Switch from "../../../components/ui/switch/Switch";

import { Card, Button, Stack } from "@mui/material";
import PackageSubscriptionDetailSkeleton from "../../../components/ui/loader/Skeleton/packageSubscriptionDetailSkeleton";
import Title from "../../../components/title/Title";
import PromoCode from "../../../components/promo-code/PromoCode";

const SubscriptionPackageDetails = () => {
  const params = useParams();
  const [gettingPackage, setGettingPackage] = useState(true);

  //^ redux selectors
  const plan = useSelector((store) => store.packageSubscription.plan);
  const validity = useSelector((store) => store.packageSubscription.validity);
  const purchase = useSelector((store) => store.packageSubscription.purchase);
  const auger_fee = useSelector((store) => store.packageSubscription.auger_fee);
  const token_value = useSelector((store) => store.packageSubscription.token_value);
  const account_tokens = useSelector((store) => store.packageSubscription.account_tokens);
  const paymentMethod = useSelector((state) => state.siteSetting.paymentMethod);
  const upgradePackageSubscription = useSelector((state) => state.packageSubscription.upgradePackageSubscription);

  const [usd, setUSD] = useState(true);
  const [planPrice, setPlanPrice] = useState(0);
  // eslint-disable-next-line
  const [discount, setDiscount] = useState(0);
  const [planServicePrice, setPlanServicePrice] = useState(0);
  const [subscriptionDetailPrice, setSubscriptionDetailPrice] = useState(0);
  const [promoCodes, setPromoCodes] = useState([]);
  const [isPromoCodeApplied, setIsPromoCodeApplied] = useState(false);
  const [promoCodePrice, setPromoCodePrice] = useState({
    value: 0,
    discount: 0,
    type: "",
    discountPrice: 0,
    count: 0,
    prevPrice: 0,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //^ getting current user's subscription package details query request
  const {
    data: getUserPackageData,
    isLoading: getUserPackageIsLoading,
    isError: getUserPackageIsError,
    error: getUserPackageError,
    refetch: getUserPackageRefetch,
  } = useQuery({
    queryKey: ["get-current-user-package-details"],
    queryFn: getSubscriptedPackageHandler,
  });

  useEffect(() => {
    if (getUserPackageIsError) {
      // console.log(getUserPackageError);
    }
  }, [getUserPackageError, getUserPackageIsError]);

  const CurrencyCheckClickHandler = (isChecked) => {
    setUSD(isChecked);
  };

  const textMotion = {
    hover: {
      x: 3,
    },
  };

  const backTextMotion = {
    hover: {
      x: -3,
    },
  };

  const {
    isSuccess: getPlanIsSuccess,
    data: getPlanData,
    // isPending: getPlanIsPending,
    isError: getPlanIsError,
    error: getPlanError,
    mutate: getPlanMutate,
    reset: getPlanReset,
  } = useMutation({
    mutationKey: ["get-subscription-plan-by-id"],
    mutationFn: getSubscriptionPlansHandler,
  });

  useEffect(() => {
    if (getPlanIsSuccess) {
      if (!getPlanData?.status) {
        if (getPlanData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        }
      } else {
        setSubscriptionDetailPrice(
          !validity ? getPlanData.data.package.monthly_price : getPlanData.data.package.yearly_price
        );
        setPaymentSlice({ users_account_tokens: getPlanData?.data?.account_tokens });
      }
    }
  }, [getPlanData, getPlanIsSuccess, validity]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("package_id", params.plan_id);
    getPlanMutate(formData);

    // eslint-disable-next-line
  }, [params.plan_id]);

  useEffect(() => {
    if (getPlanIsSuccess) {
      if (getPlanData?.status) {
        dispatch(setPlan(getPlanData.data.package));
        dispatch(setAugerFee(getPlanData?.data?.auger_fee));
        dispatch(setTokenValue(getPlanData?.data?.token_value));
        dispatch(setPaymentSlice({ users_account_tokens: getPlanData?.data?.account_tokens }));

        let tempServicePrice = 0;
        let tempPrice = 0;
        // console.log("validity: ", validity);
        // console.log("getPlanData.data.package: ", getPlanData.data.package.yearly_price);

        if (!validity) {
          tempPrice = getPlanData.data.package.monthly_price;
          tempServicePrice = getPlanData.data.package.services.reduce(
            (accumulator, currentValue) => accumulator + currentValue.plan.monthly_price,
            0
          );
        } else {
          tempPrice = getPlanData.data.package.yearly_price;
          // console.log("tempPrice: ", tempPrice);
          tempServicePrice = getPlanData.data.package.services.reduce(
            (accumulator, currentValue) => accumulator + currentValue.plan.yearly_price,
            0
          );
        }
        setPlanPrice(tempPrice);
        setPlanServicePrice(tempServicePrice);

        let tempDiscount = ((tempServicePrice - tempPrice) / tempServicePrice) * 100;
        setDiscount(tempDiscount);

        setGettingPackage(false);
      } else {
        toast.error(getPlanData.message);
        setTimeout(() => {
          navigate("/subscription-package");
        }, 1000);
      }
    }

    // eslint-disable-next-line
  }, [getPlanIsSuccess, getPlanData, dispatch]);

  useEffect(() => {
    if (getPlanIsError) {
      // console.log(getPlanError);
    }
  }, [getPlanIsError, getPlanError]);

  const {
    // eslint-disable-next-line
    isPending: _subscribePlanIsPending,
    isError: subscribePlanIsError,
    error: subscribePlanError,
    mutate: subscribePlanMutate,
    reset: subscribePlanReset,
  } = useMutation({
    mutationKey: ["subscribe-package"],
    mutationFn: subscribePackageHandler,
    onSuccess: (data) => {
      if (data.status) {
        const newPaymentState = {
          users_account_tokens: data.data.account_tokens,
        };

        dispatch(setPaymentSlice(newPaymentState));
        dispatch(setPayWithTokensSpinner(true));

        toast.success(data.message);
        navigate(`print-invoice/${data?.data?.invoice_id}`);

        queryClient.invalidateQueries(["get-user-profile"]);
        queryClient.invalidateQueries(["get-user-profile-index-app"]);
        queryClient.invalidateQueries(["get-user-profile"]);
        queryClient.invalidateQueries(["get-user-profile-user-layout"]);

        dispatch(setPayWithTokensSpinner(false));
        dispatch(setPaymentModal(false));
      } else {
        dispatch(setPayWithTokensSpinner(false));
        toast.error(data.message);
      }

      subscribePlanReset();
    },
  });

  useEffect(() => {
    if (subscribePlanIsError) {
      // console.log(subscribePlanError);
    }
  }, [subscribePlanIsError, subscribePlanError]);

  // check Approval
  //capture likely error
  const subscribePackage = async () => {
    dispatch(setPayWithTokensSpinner(true));

    let formData = new FormData();
    formData.append("validity", !validity ? "1" : "12");
    formData.append("package_id", plan.id);
    formData.append("package_start_date", upgradePackageSubscription);

    subscribePlanMutate(formData);
  };

  const handlePromoCodeData = React.useCallback(
    (data) => {
      if (data.status) {
        const promocode = data?.data?.promocode?.promo_code;

        const isPromoName = promoCodes.some((code) => code?.name === promocode);

        if (isPromoName) {
          const isPromoCountOne = promoCodes.some((code) => code?.name === promocode && code?.count === 1);

          if (isPromoCountOne) {
            const promoCodeData = promoCodes.find((code) => code?.name === promocode && code?.count === 1);
            if (promoCodeData?.type === "1") {
              //^ percentage operation
              const discountPrice = parseFloat(planPrice - (planPrice * promoCodeData?.value) / 100);
              setPlanPrice(discountPrice);
            } else if (promoCodeData?.type === "0") {
              //^ flat discount operation
              const discountPrice = planPrice - promoCodeData?.value;
              setPlanPrice(discountPrice);
            } else {
              toast.error("Promo Code type is not supported.");
            }
          }
        } else {
          const promoCodeData = data?.data?.promocode;

          // console.log(promoCodeData)
          if (promoCodeData?.type === "1") {
            //^ percentage operation
            const prvPrice = planPrice;
            const discount = (planPrice * promoCodeData?.value) / 100;
            const discountPrice = planPrice - discount;
            setPromoCodePrice({
              type: promoCodeData?.type,
              discount,
              discountPrice: discountPrice,
              value: promoCodeData?.value,
              count: 1,
              prevPrice: prvPrice,
            });
            setPlanPrice(discountPrice);
          } else if (promoCodeData?.type === "0") {
            //^ flat discount operation
            const discountPrice = planPrice - promoCodeData?.value;
            setPromoCodePrice({
              type: promoCodeData?.type,
              discount: 0,
              discountPrice: 0,
              value: promoCodeData?.value,
              count: 1,
            });
            setPlanPrice(discountPrice);
          } else {
            toast.error("Promo Code type is not supported.");
          }
          setIsPromoCodeApplied(true);
          setPromoCodes((prevPromoCode) => {
            return [
              ...prevPromoCode,
              {
                name: promocode,
                status: data?.data?.promocode?.status,
                type: data?.data?.promocode?.type,
                value: data?.data?.promocode?.value,
                count: 1,
              },
            ];
          });
        }
      }
    },
    // eslint-disable-next-line
    [promoCodes, planPrice]
  );

  if (gettingPackage || getUserPackageIsLoading) {
    return <PackageSubscriptionDetailSkeleton />;
  }

  const augerFee = parseFloat((planPrice * auger_fee) / 100).toFixed(2);
  const SBCPrice = planPrice / token_value;
  const SBCAugerFee = (planPrice * auger_fee) / 100 / token_value;
  const totalPayment = !usd
    ? `SBC ${((planPrice + (planPrice * auger_fee) / 100) / token_value).toFixed(2)}`
    : `$ ${(planPrice + (planPrice * auger_fee) / 100).toFixed(2)}`;

  const setPaymentOptions = () => {
    const newPaymentState = {
      paymentModal: true,
      paymentModalTitle: `${plan.name} purchase : ${
        paymentMethod === "2" || paymentMethod === "3" ? `$${planPrice}` : totalPayment
      }`,
      payWithTokens: subscribePackage,
      users_account_tokens: account_tokens,
      orderDescription: `${plan.name} purchase : $ ${totalPayment}`,
      price: planPrice,
      auger_fee: auger_fee,
      token_value: token_value,
      payWithTokensSpinner: false,
    };
    dispatch(setPaymentSlice(newPaymentState));
  };

  return (
    <>
      <Head title="Package Subscription" />
      {subscribePlanIsError && (
        <ErrorAlert
          title={`${subscribePlanError?.code || 500}`}
          description={`${subscribePlanError?.info?.message || "Something went wrong"}`}
          isConformed={() => {
            subscribePlanReset();
            dispatch(setPayWithTokensSpinner(false));
          }}
        />
      )}
      {getUserPackageIsError && (
        <ErrorAlert
          title={"Something went wrong"}
          description={`Unable to get the current user's subscription package.`}
          isConformed={() => getUserPackageRefetch()}
        />
      )}
      {getPlanIsError && (
        <ErrorAlert
          title={"Something went wrong"}
          description={`Unable to get the Plan Details.`}
          isConformed={() => getPlanReset()}
        />
      )}

      <>
        <BlockHead>
          <BlockBetween>
            <BlockHeadContent className={"w-100"}>
              <div
                className={`${classes["heading-title"]} d-flex w-100 justify-content-between align-items-center w-100`}
              >
                <Title title={"Package Subscription"} divider={false} />
                <motion.div className="d-flex align-items-center justify-content-center" whileHover={"hover"}>
                  <Button variant="text" color="inherit" onClick={() => navigate(-1)}>
                    <span className={`d-flex align-items-center`} style={{ gap: "0.625rem" }}>
                      <motion.span
                        variants={backTextMotion}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <Icon name={"arrow-left"} />
                      </motion.span>
                      <span>Plans</span>
                    </span>
                  </Button>
                </motion.div>
                {!purchase && (
                  <button className="btn btn-primary ms-1">
                    <em className="icon ni ni-printer" />
                  </button>
                )}
              </div>
              <Separator />
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <div className={`${styles["subscription-main"]} pb-5`}>
          <GreetingMessage username={getCookie("username", undefined)} />

          <div>
            <div className="d-flex flex-column" style={{ gap: "2.875rem" }}>
              <div className={`${classes["package-details-main"]}`}>
                <div className={`${styles["package-plan"]} h-100 p-2`}>
                  <h5 className={`${styles["package-name"]}`}>{plan?.name} package includes</h5>
                  <div className={`${styles["package-services"]}`}>
                    {plan?.services.map((service, index) => (
                      <div
                        key={index}
                        className="d-flex align-items-center justify-content-start"
                        style={{ gap: "0.625rem" }}
                      >
                        <em className={`${service?.service?.bs_icon}`} />
                        <p>{service.service.name}</p>
                      </div>
                    ))}
                  </div>
                </div>

                <Stack gap={"1.25rem"}>
                  {!isPromoCodeApplied && <PromoCode onPromoCode={handlePromoCodeData} promoCodeDetails={promoCodes} />}
                  {/* //^ Payment method */}
                  <Card elevation={1} sx={{ borderRadius: "0.625rem" }} className="p-2 py-4">
                    <div className="w-100 col-md-4 pricing-list justify-space-evenly">
                      <div className="theme-bg-plan my-2 d-flex flex-column" style={{ gap: "1rem" }}>
                        <div className="row pb-2 d-flex flex-column" style={{ gap: "1rem" }}>
                          <div className="col-12 d-flex justify-content-center fw-semibold">
                            <h5>Proceed to Payment</h5>
                          </div>
                          <div className="col-12 d-flex justify-content-end my-auto">
                            <div>
                              <Switch
                                checkedLabel={"SBC"}
                                unCheckedLabel={"USD"}
                                checked={usd}
                                onClick={CurrencyCheckClickHandler}
                                size={"sm"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className={`${styles["payment-details-info"]}`}>
                          <div style={{ display: "grid", gridTemplateColumns: "repeat(9, 1fr)", width: "100%" }}>
                            <p className={`${classes["h5"]}`} style={{ gridColumn: "span 8" }}>
                              Price
                            </p>
                            <p className={`${classes["p"]}`} style={{ whiteSpace: "nowrap" }}>
                              {!usd ? (
                                <>
                                  <div className="col-6 d-flex">
                                    <span>SBC {SBCPrice.toFixed(6)} </span>&nbsp;
                                    <span className="text-line pt-1">
                                      &nbsp;SBC {(planServicePrice / token_value).toFixed(6)}{" "}
                                    </span>
                                    &nbsp;
                                  </div>
                                </>
                              ) : (
                                <div className="col-6 d-flex">
                                  <span>${promoCodePrice.count === 1 ? promoCodePrice.prevPrice : planPrice}</span>
                                  &nbsp;
                                  {/* <span className="text-line pt-1">
                                    &nbsp;$
                                    {promoCodePrice.count === 1
                                      ? promoCodePrice.prevPrice
                                      : planServicePrice}
                                  </span> */}
                                </div>
                              )}
                            </p>
                          </div>
                          {paymentMethod === "1" && (
                            <div style={{ display: "grid", gridTemplateColumns: "repeat(9, 1fr)", width: "100%" }}>
                              <p className={`${classes["h5"]}`} style={{ gridColumn: "span 8" }}>
                                Auger Fee
                              </p>

                              <p className={`${classes["p"]}`} style={{ whiteSpace: "nowrap" }}>
                                {!usd ? `SBC ${SBCAugerFee.toFixed(2)}` : `$${parseFloat(augerFee).toFixed(2)}`}
                              </p>
                            </div>
                          )}

                          {promoCodePrice.type === "1" && (
                            <div style={{ display: "grid", gridTemplateColumns: "repeat(9, 1fr)", width: "100%" }}>
                              <p className={`${classes["h5"]}`} style={{ gridColumn: "span 8" }}>
                                Promo Discount
                              </p>

                              <p className={`${classes["p"]}`} style={{ whiteSpace: "nowrap" }}>
                                {`${promoCodePrice.value}%`}
                              </p>
                            </div>
                          )}
                          {promoCodePrice.type === "1" && (
                            <div style={{ display: "grid", gridTemplateColumns: "repeat(9, 1fr)", width: "100%" }}>
                              <p className={`${classes["h5"]}`} style={{ gridColumn: "span 8" }}>
                                Promo Discount Amount
                              </p>

                              <p className={`${classes["p"]} text-danger`} style={{ whiteSpace: "nowrap" }}>
                                {usd
                                  ? `- $${promoCodePrice.discount}`
                                  : `- SBC ${(promoCodePrice.discount / token_value).toFixed(2)}`}
                              </p>
                            </div>
                          )}
                          <div style={{ display: "grid", gridTemplateColumns: "repeat(9, 1fr)", width: "100%" }}>
                            <p className={`${classes["h5"]}`} style={{ gridColumn: "span 8" }}>
                              Subscription Validity
                            </p>

                            <p className={`${classes["p"]}`} style={{ whiteSpace: "nowrap" }}>
                              <span>{!validity ? "1 Month" : "1 Year"}</span>
                            </p>
                          </div>
                          <div>
                            <Separator />
                          </div>
                          <div className={`${classes["px-5"]}`}>
                            <div style={{ display: "grid", gridTemplateColumns: "repeat(9, 1fr)", width: "100%" }}>
                              <h6 className={`${classes["h5"]}`} style={{ gridColumn: "span 8" }}>
                                Total Payment
                              </h6>
                              <h6 className={`${classes["p"]}`} style={{ whiteSpace: "nowrap" }}>
                                {paymentMethod === "1"
                                  ? totalPayment
                                  : `${!usd ? `SBC ${SBCPrice.toFixed(6)}` : `$${planPrice}`}`}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <motion.div className="d-flex align-items-center justify-content-center" whileHover={"hover"}>
                        <Button variant="outlined" color="inherit" onClick={setPaymentOptions}>
                          <span className={`d-flex align-items-center`} style={{ gap: "0.625rem" }}>
                            <span>
                              {getUserPackageData && getUserPackageData.status
                                ? plan.id === getUserPackageData.data.package.package_id
                                  ? "Renew"
                                  : getUserPackageData?.data?.package?.price > subscriptionDetailPrice
                                  ? "Buy Now"
                                  : "Upgrade Now"
                                : "Buy Now"}
                            </span>
                            <motion.span
                              variants={textMotion}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <Icon name="arrow-right" />
                            </motion.span>
                          </span>
                        </Button>
                      </motion.div>
                    </div>
                    <Payment totalPayment={totalPayment} />
                  </Card>
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default SubscriptionPackageDetails;
