import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";

//^ http requests
import { getUserListsHandler } from "../../../http/post-api";

//^ @mui component
import { Box } from "@mui/material";

//^ data table
import DataTable from "./data-table";
import { columns } from "./column";

export default function ConnectionTable() {
  // eslint-disable-next-line
  const [searchParams, _] = useSearchParams();
  const connectType = searchParams.get("connectType") || "connected";

  //^ states
  const [totalConnections, setTotalConnections] = useState(0);
  const [connectionUsers, setConnectionUsers] = useState([]);

  //^ get connect user list query fn ===============================================================
  const {
    data: getUserConnectData,
    isLoading: getUserConnectIsLoading,
    isRefetching: getUserConnectIsRefetching,
    isError: getUserConnectIsError,
    error: getUserConnectError,
    // refetch: getUserConnectRefetch,
  } = useQuery({
    queryKey: [`get-user-${connectType}-list`, connectType],
    queryFn: async () => getUserListsHandler({ type: connectType === "notConnected" ? "not_connected" : connectType }),
    enabled: connectType !== null,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!getUserConnectIsRefetching || !getUserConnectIsLoading) {
      if (getUserConnectData) {
        if (!getUserConnectData?.status) {
          if (getUserConnectData?.redirect) {
            window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
          } else {
            setConnectionUsers([]);
          }
        } else {
          if (connectType === "connected") {
            const connectedUsers = getUserConnectData?.data?.connections?.map((connection) => ({
              ...connection,
              connection_status: {
                connected: true,
              },
            }));

            setConnectionUsers(connectedUsers);
            setTotalConnections(getUserConnectData?.data?.connections?.length || 0);
          } else {
            const notConnectedUsers = getUserConnectData?.data?.user_list?.filter(
              (user) => !user?.connection_status?.connected
            );
            setConnectionUsers(notConnectedUsers || []);
          }
        }
      }
    }

    // eslint-disable-next-line
  }, [getUserConnectData, getUserConnectIsLoading, getUserConnectIsRefetching]);

  useEffect(() => {
    if (getUserConnectIsError) {
      toast.error(getUserConnectError.message);
    }
  }, [getUserConnectIsError, getUserConnectError]);

  //^ get connect user list query fn ===============================================================

  return (
    <Box>
      <DataTable
        columns={columns}
        data={connectionUsers}
        connectionCount={totalConnections}
        getUserConnectIsLoading={getUserConnectIsLoading}
      />
    </Box>
  );
}
