import { Divider } from "@mui/material";

export default function Separator() {
  return (
    <Divider
      sx={{
        margin: "0rem 0rem 1rem 0rem",
        "@media (min-width: 65.5rem)": {
          margin: "1rem 0rem 1rem 0rem",
        },
      }}
    />
  );
}
