import { Stack, Skeleton } from "@mui/material";

export default function FollowListSkeleton() {
  return (
    <Stack gap={2} padding={"1.15rem"}>
      {Array.from({ length: 10 }).map((_, index) => (
        <Stack key={index} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
          <Stack direction={"row"} alignItems={"center"} width={"100%"}>
            <Skeleton variant="circular" width={"2.5rem"} height={"2.5rem"} animation="wave" />
            <Stack marginLeft={"1rem"} width={"100%"} gap={1}>
              <Skeleton
                variant="rounded"
                sx={{
                  width: "80%",
                  "@media (min-width:65.5rem)": {
                    width: "30%",
                  },
                }}
                height={"1.5rem"}
                animation="wave"
              />
              <Skeleton
                variant="rounded"
                sx={{
                  width: "60%",
                  "@media (min-width:65.5rem)": {
                    width: "20%",
                  },
                }}
                height={"1rem"}
                animation="wave"
              />
            </Stack>
          </Stack>
          <Stack gap={2} alignItems="center" direction={"row"}>
            <Skeleton
              variant="rounded"
              width={"7rem"}
              height={"1.5rem"}
              animation="wave"
              sx={{
                borderRadius: "0.625rem",
                display: "none",
                "@media (min-width: 65.5rem)": {
                  display: "block",
                },
              }}
            />

            <Skeleton variant="rectangular" width={"1.5rem"} height={"1.5rem"} animation="wave" />
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}
