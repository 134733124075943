import { InputLabel, Typography, Box, Stack, Button, OutlinedInput } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import * as yup from "yup";

//^ http request
import { validatePromoCodeHandler } from "../../http/post-api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import Loader from "../ui/loader/Loader";
import ErrorMessage from "../ui/error-message/ErrorMessage";

export default function PromoCode({ onPromoCode, promoCodeDetails = [] }) {
  const [alreadyPresentPromo, setAlreadyPresentPromo] = useState(false);

  const formik = useFormik({
    initialValues: {
      promoCode: "",
    },
    validationSchema: yup.object({
      promoCode: yup
        .string()
        .required("Promo code is required")
        .matches(/^[a-zA-Z0-9]{4,10}$/, "Promo code must be between 4 and 10 characters"),
    }),
    onSubmit: (values) => {
      validatePromoMutate(values);
    },
  });

  const {
    isPending: validatePromoIsPending,
    isError: validatePromoIsError,
    error: validatePromoError,
    mutate: validatePromoMutate,
    reset: validatePromoReset,
  } = useMutation({
    mutationKey: ["validate-promo-code"],
    mutationFn: validatePromoCodeHandler,
    onSuccess: (data) => {
      if (data?.toast) {
        if (data?.status) {
          toast.success(data?.message);
          onPromoCode(data);
        } else {
          if (data?.redirect) {
            window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
          } else {
            toast.error(data?.message);
          }
        }
      }
      validatePromoReset();
    },
  });

  useEffect(() => {
    if (validatePromoIsError) {
      // toast.error(validatePromoError?.message);
    }
  }, [validatePromoIsError, validatePromoError]);

  const promoCodeChangeHandler = (e) => {
    const value = e.target.value;
    const promoCodeNm = promoCodeDetails.some((promoCode) => promoCode.name === value);

    if (promoCodeNm) {
      setAlreadyPresentPromo(true);
    } else {
      setAlreadyPresentPromo(false);
    }
    formik?.handleChange(e);
  };

  useEffect(() => {
    const promoCodeNm = promoCodeDetails.some((promoCode) => promoCode.name === formik.values.promoCode);

    if (promoCodeNm) {
      setAlreadyPresentPromo(true);
    } else {
      setAlreadyPresentPromo(false);
    }
  }, [promoCodeDetails, formik.values.promoCode]);

  return (
    <Stack>
      <form onSubmit={formik?.handleSubmit}>
        <Stack gap={"1rem"}>
          <InputLabel component={"label"} htmlFor="promo-code">
            <Typography
              component={"span"}
              variant="subtitle1"
              sx={{
                display: "none",
                "@media (min-width: 65.5rem)": {
                  display: "block",
                },
              }}
            >
              Add Promo Code
            </Typography>
          </InputLabel>
          <Stack gap={"0.325rem"}>
            <OutlinedInput
              type="text"
              id="promo-code"
              name={"promoCode"}
              onChange={promoCodeChangeHandler}
              onBlur={formik?.handleBlur}
              // className="form-control"
              placeholder="Enter Promo Code"
            />
            {formik?.errors["promoCode"] && formik?.touched["promoCode"] ? (
              <ErrorMessage errorMsg={formik?.errors["promoCode"]} />
            ) : alreadyPresentPromo ? (
              ""
            ) : (
              // <Typography className="invalid">{"Promo code is already Applied"}</Typography>
              ""
            )}
          </Stack>
          <Box
            sx={{
              width: "100%",
              display: "block",
              "@media (min-width: 65.5rem)": {
                width: "auto",
                display: "none",
              },
            }}
          >
            <Button variant="contained" type="submit" fullWidth>
              Apply
            </Button>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "none",
              "@media (min-width: 65.5rem)": {
                width: "auto",
                display: "block",
              },
            }}
          >
            <Button
              variant="contained"
              disabled={!formik.isValid || validatePromoIsPending || alreadyPresentPromo}
              type="submit"
              startIcon={validatePromoIsPending ? <Loader /> : ""}
            >
              {alreadyPresentPromo ? "Applied" : "Apply"}
            </Button>
          </Box>
        </Stack>
      </form>
    </Stack>
  );
}
