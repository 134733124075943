import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useMutation, useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";
import moment from "moment";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { Alert } from "reactstrap";

//^ utils
import { isDifferenceDays } from "../../../utils/Utils";

//^ stylesheets
import classes from "../../../pages/pre-built/package-subscription/subscription-packages/SubscriptionPackage.module.scss";

//^ redux actions
import { servicePlanAction } from "../../../store/slices/service-slice";

//^ http request
import { downgradeServiceHandler, getServiceDetails } from "../../../http/post-api";

//^ mui
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Close, ReportProblemRounded } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

//^ components
import Title from "../../../components/title/Title";
import Switch from "../../../components/ui/switch/Switch";
import ErrorAlert from "../../../components/ui/modals/error-model/ErrorAlert";
import SubscriptionPackageSkeleton from "../../../components/ui/loader/Skeleton/SubscriptionPackageSkeleton";
import Separator from "../../../components/ui/separator/Separator";
import { Icon } from "../../../components/Component";
import BackButton from "../../../components/button/back-button";
import EmptyFolder from "../../../components/svg/empty-folder";
import Head from "../../../layout/head/Head";
import StartButton from "../../../components/button/navigate-button";

import { setServiceSubUpgradePlan } from "../../../store/slices/package-subscription-slice";

export function ServiceList({ keyName, value }) {
  return (
    <>
      <Stack gap={"0.525rem"} direction={"row"}>
        <Typography variant="subtitle1" textTransform={"capitalize"}>{`${keyName}:`}</Typography>
        <Typography variant="subtitle1" fontWeight={600}>{`${value}`}</Typography>
      </Stack>
    </>
  );
}

export default function ServicePlans() {
  const theme = useTheme();
  const { serviceId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const servicePlanValidity = useSelector((state) => state.servicePlan.servicePlanValidity);

  const [subscribedData, setSubscribedData] = useState({});
  const [subscribedFeatures, setSubscribedFeatures] = useState([]);
  const [switchClickContent, setSwitchClickContent] = useState({
    packageName: "",
    notIncludedFeatures: [],
    price: 0,
    validity: "",
    selectedPlan: {},
  });
  const [upgradeSwitchClickContent, setUpgradeSwitchClickContent] = useState({
    packageName: "",
    notIncludedFeatures: [],
    price: 0,
    validity: "",
    selectedPlan: {},
  });
  const [selectedUpgradeOption, setSelectedUpgradeOption] = useState("option1");
  const [date, setDate] = useState(null);

  //^ boolean states
  const [isSwitchModelOpen, setIsSwitchModelOpen] = React.useState(false);
  const [isUpgradeSwitchModelOpen, setIsUpgradeSwitchModelOpen] = React.useState(false);

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isDark = theme.palette.mode === "dark";

  //^ service plan query fn
  const {
    data: serviceDetailsData,
    isLoading: serviceDetailsIsLoading,
    isRefetching: serviceDetailsIsRefetching,
    isError: serviceDetailsIsError,
    error: serviceDetailsError,
    refetch: serviceDetailsRefetch,
  } = useQuery({
    queryKey: ["get-service-details"],
    queryFn: () => getServiceDetails({ serviceId: parseInt(serviceId) }),
    gcTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!serviceDetailsIsLoading && !serviceDetailsIsRefetching) {
      if (!serviceDetailsData?.status) {
        if (serviceDetailsData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        }
      } else {
        setSubscribedData(serviceDetailsData?.data?.subscribedData);

        const subscribedData = serviceDetailsData?.data?.services?.plans?.find(
          (plan) => plan.id === serviceDetailsData?.data?.subscribedData?.plan_id
        );

        let featureObj = {};
        if (subscribedData?.features) {
          try {
            featureObj = JSON.parse(subscribedData.features);
          } catch (error) {
            console.error("Error parsing features:", error);
          }
        }

        const formattedFeatures = Object.keys(featureObj)?.map((title) => ({
          title,
          ...featureObj[title],
        }));

        setSubscribedFeatures(formattedFeatures);
      }
    }
  }, [serviceDetailsData, serviceDetailsIsLoading, serviceDetailsIsRefetching]);

  useEffect(() => {
    if (serviceDetailsIsError) {
      console.log(serviceDetailsError);
    }
  }, [serviceDetailsIsError, serviceDetailsError]);

  function servicePlanSwitchHandler() {
    dispatch(servicePlanAction.toggleServicePlanValidity());
  }

  const textMotion = {
    hover: {
      x: 3,
    },
  };

  function handleSwitchPackageHandler(_event, packageName, notIncludedFeatures, price, validity, selectPlan) {
    setSwitchClickContent({
      packageName,
      notIncludedFeatures,
      price: price,
      validity,
      selectedPlan: selectPlan,
    });
    setIsSwitchModelOpen(true);
  }
  function handleUpgradeSwitchPackageHandler(_event, packageName, notIncludedFeatures, price, validity, selectPlan) {
    setUpgradeSwitchClickContent({
      packageName,
      notIncludedFeatures,
      price: price,
      validity,
      selectedPlan: selectPlan,
    });

    setIsUpgradeSwitchModelOpen(true);
  }
  function handleUpgradeSwitchModalClose() {
    setIsUpgradeSwitchModelOpen(false);
    setSelectedUpgradeOption("option1");
  }
  function handleSwitchModalClose() {
    setIsSwitchModelOpen(false);
  }
  const handleDateChangeHandler = (date) => {
    setDate(date);
  };
  const handleOptionChange = (event) => {
    setSelectedUpgradeOption(event.target.value);
  };
  const currentDate = moment().format("YYYY-MM-DD");

  //^ downgrade service plan mutation fn =================================================================================================================

  const {
    isPending: downgradeServiceIsLoading,
    isError: downgradeServiceIsError,
    error: downgradeServiceError,
    mutate: downgradeServiceMutate,
    reset: downgradeServiceReset,
  } = useMutation({
    mutationKey: ["downgrade-service-plan"],
    mutationFn: downgradeServiceHandler,
    onSuccess: (data) => {
      if (data?.toast) {
        if (data?.status) {
          toast.success(data?.message);
          navigate(
            `/subscriptions/service-plan/invoice/${data?.data?.service?.service_id}/${data?.data?.service?.plan_id}/print-invoice/${data?.data?.invoice_id}`
          );
        } else {
          toast.error(data?.message);
        }
      }
      downgradeServiceReset();
    },
  });

  useEffect(() => {
    if (downgradeServiceIsError) {
      toast.error(downgradeServiceError.message);
      downgradeServiceReset();
    }
  }, [downgradeServiceError, downgradeServiceIsError, downgradeServiceReset]);

  //^ downgrade service plan mutation fn =================================================================================================================

  return (
    <>
      <Dialog
        open={isSwitchModelOpen}
        aria-labelledby="switch-dialog-title"
        onClose={handleSwitchModalClose}
        fullScreen={fullScreen}
        PaperProps={{
          elevation: 1,
          component: "form",
          sx: {
            width: "100%",
            maxWidth: "37.5rem",
          },
          onSubmit: (event) => {
            event.preventDefault();
            handleSwitchModalClose();
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="switch-dialog-title">
          {"Are you sure you want to switch subscription?"}
          <IconButton
            aria-label="close"
            onClick={handleSwitchModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="subtitle1">
            Switching will change your subscription from {subscribedData?.plan}{" "}
            <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
              {` ($${subscribedData?.price}/${
                subscribedData?.validity === 12 ? "yr" : subscribedData?.validity === 1 ? "mo" : ""
              }) `}
            </Typography>
            to {switchClickContent.packageName}{" "}
            <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
              {` ($${switchClickContent.price}/${
                switchClickContent?.validity === 1 ? "yr" : switchClickContent?.validity === 12 ? "mo" : ""
              }) `}
            </Typography>
            . Some features available in the {subscribedData?.plan} package will not be included in the{" "}
            {switchClickContent.packageName} package. These features are:
          </Typography>
          {
            <List>
              {subscribedFeatures.map((feature, index) => {
                return (
                  <ListItem key={index}>
                    <Typography variant="subtitle2">- {feature?.text}</Typography>
                  </ListItem>
                );
              })}
            </List>
          }

          <Typography variant="subtitle1">
            If you are sure about this change, please confirm by clicking the{" "}
            <Typography variant="button" component={"span"}>
              "Confirm"
            </Typography>{" "}
            button below.
          </Typography>
          <Alert color="warning" style={{ marginTop: "1rem", padding: "1rem" }}>
            <Stack direction={"row"} gap={2}>
              <ReportProblemRounded color="warning" />
              <Typography variant="body2">
                When switching from {subscribedData?.plan}{" "}
                <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                  {` ($${subscribedData?.price}/${
                    subscribedData?.validity === 12 ? "yr" : subscribedData?.validity === 1 ? "mo" : ""
                  }) `}
                </Typography>{" "}
                to {switchClickContent.packageName}{" "}
                <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                  {` ($${switchClickContent.price}/${
                    switchClickContent?.validity === 1 ? "yr" : switchClickContent?.validity === 12 ? "mo" : ""
                  }) `}
                </Typography>
                , the payment will remain the same as for {subscribedData?.plan}, with only the services changing to
                those of the {switchClickContent.packageName} package. While the plan will transition, it's important to
                note that even though {subscribedData?.plan} costs{" "}
                <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                  ${subscribedData?.price}
                </Typography>{" "}
                and {switchClickContent.packageName} costs{" "}
                <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                  ${switchClickContent?.price}
                </Typography>
                , the remaining{" "}
                <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                  ${(subscribedData?.price - switchClickContent?.price).toFixed(2)}
                </Typography>{" "}
                will not be refunded.
              </Typography>
            </Stack>
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            startIcon={downgradeServiceIsLoading ? <CircularProgress size={16} color="inherit" /> : ""}
            onClick={() => {
              downgradeServiceMutate({
                planId: switchClickContent.selectedPlan?.id,
                serviceId: parseInt(serviceId),
                validity: servicePlanValidity ? "12" : "1",
              });
            }}
          >
            Confirm
          </Button>
          <Button color="secondary" onClick={handleSwitchModalClose} sx={{ color: isDark ? "white" : "black" }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isUpgradeSwitchModelOpen}
        aria-labelledby="switch-dialog-title"
        onClose={handleUpgradeSwitchModalClose}
        fullScreen={fullScreen}
        PaperProps={{
          elevation: 1,
          component: "form",
          sx: {
            width: "100%",
            maxWidth: "37.5rem",
          },
          onSubmit: (e) => {
            e.preventDefault();

            navigate(`${upgradeSwitchClickContent.selectedPlan.id}`);
            setSelectedUpgradeOption("option1");
            handleUpgradeSwitchModalClose();

            dispatch(
              setServiceSubUpgradePlan(
                selectedUpgradeOption === "option1"
                  ? currentDate
                  : selectedUpgradeOption === "option2"
                  ? subscribedData?.end_date
                  : dayjs(date).format("YYYY-MM-DD")
              )
            );
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="switch-dialog-title">
          {"Are you sure you want to upgrade subscription?"}
          <IconButton
            aria-label="close"
            onClick={handleUpgradeSwitchModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <RadioGroup value={selectedUpgradeOption} onChange={handleOptionChange}>
            <Stack gap={2}>
              <FormControlLabel
                value="option1"
                control={<Radio />}
                label="Do you want to upgrade to Silver from Gold starting today?"
                required
              />
              <FormControlLabel
                value="option2"
                control={<Radio />}
                label="Upgrade the subscription when the current subscription expires."
                required
              />
              <FormControlLabel
                value="option3"
                control={<Radio />}
                label="From which date do you want to upgrade the plan?"
                required
              />
            </Stack>
          </RadioGroup>
          {selectedUpgradeOption === "option3" && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                variant="standard"
                value={date}
                id={"dob"}
                onChange={handleDateChangeHandler}
                sx={{ width: "100%", mt: 2 }}
                format="DD/MM/YYYY"
                minDate={dayjs()}
              />
            </LocalizationProvider>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" type="submit">
            Confirm
          </Button>
          <Button
            color="secondary"
            onClick={handleUpgradeSwitchModalClose}
            sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Head title={"Service Plan"} />
      {serviceDetailsIsError && (
        <ErrorAlert
          title={`${serviceDetailsError?.code || 500}`}
          description={`${serviceDetailsError?.info?.message || "Something went wrong"}`}
          isConformed={() => serviceDetailsRefetch()}
        />
      )}
      <Stack gap={"1.25rem"}>
        <Title
          title={
            serviceDetailsIsLoading || serviceDetailsIsRefetching
              ? "Service Plans"
              : `${serviceDetailsData?.data?.services?.name} Plans`
          }
          endComponent={
            <Stack direction={"row"} gap={"1.5rem"} justifyContent={"space-between"} width={"100%"}>
              <Switch
                checkedLabel={"Monthly"}
                unCheckedLabel={"Yearly"}
                onClick={servicePlanSwitchHandler}
                checked={servicePlanValidity}
              />
              <BackButton variant={"text"} title={"Services"} onClick={() => navigate(-1)} />
            </Stack>
          }
        />
        <Box paddingBottom={"2rem"}>
          {serviceDetailsIsLoading || serviceDetailsIsRefetching ? (
            <Box width={"100%"}>
              <SubscriptionPackageSkeleton />
            </Box>
          ) : serviceDetailsData?.data?.services?.plans?.length > 0 ? (
            <Grid container spacing={"0.625rem"}>
              {serviceDetailsData?.data?.services?.plans?.map((plan, index) => {
                const planPrice = servicePlanValidity ? plan.yearly_price : plan.monthly_price;
                const featureObj = JSON.parse(plan.features);
                const formattedFeatures = Object.keys(featureObj).map((title) => ({
                  title,
                  ...featureObj[title],
                }));
                const validity = servicePlanValidity ? 12 : 1;
                const packageName = plan?.name || "";
                const selectedPlan = plan;

                return (
                  <>
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Box
                        width={"100%"}
                        height={"100%"}
                        className={`${
                          subscribedData?.plan_id === plan.id &&
                          subscribedData?.validity === validity &&
                          plan.is_subscribed &&
                          "bg-primary d-flex flex-column"
                        }`}
                        style={
                          subscribedData?.plan_id === plan.id &&
                          subscribedData?.validity === validity &&
                          plan.is_subscribed
                            ? {
                                borderRadius: "0.625rem",
                                paddingRight: "0.225rem",
                                paddingLeft: "0.225rem",
                                paddingBottom: "0.225rem",
                              }
                            : {}
                        }
                      >
                        {subscribedData?.plan_id === plan.id &&
                        subscribedData?.validity === validity &&
                        plan.is_subscribed ? (
                          <Typography
                            textAlign={"center"}
                            variant="overline"
                            fontWeight={"600"}
                            color={"white"}
                            fontSize={15}
                            padding={"0.725rem 0rem"}
                          >
                            Current Subscription
                          </Typography>
                        ) : (
                          ""
                        )}
                        <Paper
                          variant="elevation"
                          elevation={isDark ? 2 : 3}
                          className={`pricing ${plan.tags ? "recommend" : ""} pb-2 position-relative`}
                          sx={
                            isDark
                              ? { borderRadius: "0.625rem", boxShadow: "none", borderWidth: "1px" }
                              : { borderRadius: "0.625rem" }
                          }
                        >
                          <div className={`p-2 ${classes["pricing-details"]}`}>
                            <div className="d-flex flex-column" style={{ gap: "1.25rem" }}>
                              <div className={`${classes["subscription-title"]}`}>
                                <div>
                                  <h5 className="title">{plan.name}</h5>
                                </div>
                                <div
                                  className={`${plan.is_subscribed ? "pt-2" : ""}`}
                                  style={{ width: "7rem", height: "7rem" }}
                                >
                                  <img src={`${process.env.REACT_APP_API_URL}${plan.logo}`} alt={plan.name} />
                                </div>
                              </div>
                              <div className="pricing-amount px-2">
                                <div className="amount">
                                  ${planPrice}{" "}
                                  <span style={{ fontSize: "12px" }}>{`/ ${!servicePlanValidity ? "mo" : "yr"}`}</span>
                                </div>
                                <Separator />

                                <div>
                                  <p>Includes</p>
                                  <p className="d-flex flex-column fs-5" style={{ gap: "1rem" }}>
                                    {formattedFeatures.length > 0 ? (
                                      <Stack gap={"0.625rem"}>
                                        {formattedFeatures.map((service, index) => {
                                          return (
                                            <React.Fragment key={index}>
                                              <ServiceList keyName={service.title} value={service.text} />
                                            </React.Fragment>
                                          );
                                        })}
                                      </Stack>
                                    ) : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="pricing-action d-flex justify-content-center">
                              {subscribedData.current_subscribed && subscribedData?.price > planPrice ? (
                                <StartButton
                                  title={"Switch Subscription"}
                                  variant={"contained"}
                                  color={"primary"}
                                  type={"button"}
                                  onClick={(event) => {
                                    handleSwitchPackageHandler(
                                      event,
                                      packageName,
                                      {},
                                      planPrice,
                                      validity,
                                      selectedPlan
                                    );
                                  }}
                                />
                              ) : subscribedData?.price < planPrice ? (
                                <StartButton
                                  // startIcon={downgradeIsPending ? <CircularProgress size={18} color="inherit" /> : ""}
                                  title={"Upgrade Subscription"}
                                  variant={"contained"}
                                  color={"primary"}
                                  type={"button"}
                                  onClick={(event) =>
                                    handleUpgradeSwitchPackageHandler(
                                      event,
                                      packageName,
                                      {},
                                      planPrice,
                                      validity,
                                      selectedPlan
                                    )
                                  }
                                />
                              ) : (
                                <motion.div whileHover={"hover"}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={() => {
                                      navigate(`${plan.id}`);
                                      dispatch(setServiceSubUpgradePlan(null));
                                    }}
                                    disabled={
                                      subscribedData.current_subscribed
                                        ? subscribedData?.price >= planPrice
                                          ? subscribedData?.plan_id === plan.id &&
                                            subscribedData?.validity === validity &&
                                            plan.is_subscribed
                                            ? isDifferenceDays(subscribedData?.end_date, 4)
                                              ? false
                                              : true
                                            : false
                                          : false
                                        : false
                                    }
                                  >
                                    <Stack gap={"0.625rem"} alignItems={"center"} direction={"row"}>
                                      <Typography variant="subtitle2" component={"span"}>
                                        {`${
                                          subscribedData.current_subscribed
                                            ? subscribedData?.price >= planPrice
                                              ? subscribedData?.plan_id === plan.id &&
                                                subscribedData?.validity === validity &&
                                                plan.is_subscribed
                                                ? "Renew Plan"
                                                : "Buy Plan"
                                              : "Upgrade Plan"
                                            : "Buy Plan"
                                        }`}
                                      </Typography>
                                      <motion.span
                                        variants={textMotion}
                                        className="d-flex justify-content-center align-plans-center"
                                      >
                                        <Icon name="arrow-right" />
                                      </motion.span>
                                    </Stack>
                                  </Button>
                                </motion.div>
                              )}
                            </div>
                          </div>
                        </Paper>
                      </Box>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          ) : (
            <Stack width={"100%"} height={"100%"} alignItems={"center"} justifyContent={"center"}>
              <EmptyFolder />
            </Stack>
          )}
        </Box>
      </Stack>
    </>
  );
}
