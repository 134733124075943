import { Stack, Skeleton, Box, Card } from "@mui/material";
import Title from "../../../title/Title";

import { useTheme } from "@mui/material/styles";
import Separator from "../../separator/Separator";

export default function PackageSubscriptionDetailSkeleton() {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  return (
    <Box className={"w-100"}>
      <Stack width={"100%"} gap={4}>
        <Title title={<Skeleton variant="text" height={"4.25rem"} animation="wave" width={"100%"} />} />
        <Box width={"100%"}>
          <Stack gap={7}>
            <Stack width={"100%"} gap={1}>
              <Skeleton variant="text" height={"2.25rem"} width={"10rem"} animation="wave" />
              <Stack>
                <Skeleton variant="text" height={"1.25rem"} width={"100%"} animation="wave" />
                <Skeleton variant="text" height={"1.25rem"} width={"90%"} animation="wave" />
                <Skeleton variant="text" height={"1.25rem"} width={"80%"} animation="wave" />
                <Skeleton variant="text" height={"1.25rem"} width={"70%"} animation="wave" />
              </Stack>
            </Stack>
            <Stack
              direction={"column"}
              gap={5}
              width={"100%"}
              sx={{
                "@media (min-width: 65.5rem)": {
                  flexDirection: "row",
                },
              }}
            >
              <Box width={"100%"}>
                <Stack width={"100%"} gap={1}>
                  <Skeleton variant="text" height={"2.25rem"} width={"20rem"} animation="wave" />
                  <Stack>
                    <Skeleton variant="text" height={"1.25rem"} width={"100%"} animation="wave" />
                    <Skeleton variant="text" height={"1.25rem"} width={"100%"} animation="wave" />
                    <Skeleton variant="text" height={"1.25rem"} width={"100%"} animation="wave" />
                  </Stack>
                </Stack>
              </Box>
              <Box width={"100%"} height={"100%"}>
                <Card elevation={isDark ? 1 : 3} sx={{ width: "100%", borderRadius: "0.425rem" }}>
                  <Box sx={{ ...theme.palette.cardInner.main }}>
                    <Stack gap={4} width={"100%"}>
                      <Stack alignItems={"center"} justifyContent={"center"}>
                        <Skeleton animation="wave" variant="text" height={"2.25rem"} width={"15rem"} />
                      </Stack>
                    </Stack>
                  </Box>
                  <Separator />
                  <Box
                    sx={{ width: "100%", marginTop: "1rem", ...theme.palette.cardInner.main, paddingBottom: "0rem" }}
                  >
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Box>
                        <Stack gap={1}>
                          <Skeleton animation="wave" variant="text" width="10rem" />
                          <Skeleton animation="wave" variant="text" width="10rem" />
                          <Skeleton animation="wave" variant="text" width="10rem" />
                        </Stack>
                      </Box>
                      <Box>
                        <Stack gap={1}>
                          <Skeleton animation="wave" variant="text" width="10rem" />
                          <Skeleton animation="wave" variant="text" width="10rem" />
                          <Skeleton animation="wave" variant="text" width="10rem" />
                        </Stack>
                      </Box>
                    </Stack>
                  </Box>
                  <Separator />
                  <Box
                    sx={{ width: "100%", marginTop: "1rem", ...theme.palette.cardInner.main, paddingBottom: "0rem" }}
                  >
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Box>
                        <Stack gap={1}>
                          <Skeleton animation="wave" variant="text" width="10rem" />
                        </Stack>
                      </Box>
                      <Box>
                        <Stack gap={1}>
                          <Skeleton animation="wave" variant="text" width="10rem" />
                        </Stack>
                      </Box>
                    </Stack>
                  </Box>
                  <Box sx={{ paddingLeft: theme.palette.cardInner.main.padding, paddingTop: "1rem" }}>
                    <Stack alignItems={"center"} justifyContent={"center"}>
                      <Skeleton animation="wave" height={"3.5rem"} width={"7rem"} />
                    </Stack>
                  </Box>
                </Card>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}
